<template>
    <sui-container>

        <portal to="page-actions">
            <div style="zoom: 0.85">
                <sui-button 
                    class="labeled icon button"
                    icon="cog"
                    @click="onEditSettingsButtonClicked()"
                >
                    Indstillinger
                </sui-button>

                <sui-button 
                    v-if="firebaseUser.Roles.includes(UserRoles.EFB_PROJECT_EDITOR) && settingsPl.get('showProjectsAsTiles') == 'tiles'"
                    class="labeled icon button"
                    icon="plus"
                    @click="createProject()"
                >
                    Opret nyt projekt
                </sui-button>
            </div>
        </portal>

        <div v-if="settingsPl.get('showProjectsAsTiles') == 'tiles'">
            <sui-card-group 
                :items-per-row="getTileGridWidth()" 
                stackable
            >
                <sui-card
                    v-for="project in projects"
                    :key="project.id"
                    class="link raised"
                    @click="projectClicked(project,'open')"
                >
                    <sui-card-content>
                        <div class="color-square" :class="getProjectColor(project)" style="float: right;"></div>
                        <sui-card-header>
                            <span class="mr-5px" v-if="isUserAssociatedToProject(project, user)">
                                <i v-if="specialOccasion == 'christmas'" class="fa-kit fa-nisse" title="Du er tilknyttet dette projekt"></i>
                                <i v-else class="fas fa-user-hard-hat" title="Du er tilknyttet dette projekt"></i>
                            </span>
                            {{project.Name}}
                        </sui-card-header>
                        <sui-card-meta>
                            <p>
                                <span v-if="project.ProjectBillingCategory == 'subProject'"> {{ billingCategoryLabels.subProject }} </span>
                                    <span v-else> {{ billingCategoryLabels.billingProject }} </span>
                            </p>
                            <p v-if="!onlyTickets(project)">
                                {{ getContactByRole(project, ContactRole.EFB_CONTACT).Name }}
                                <span class="cursive" v-if="getContactByRole(project, ContactRole.EFB_CONTACT).Company && getContactByRole(project, ContactRole.EFB_CONTACT).Company.Name">
                                    ({{ getContactByRole(project, ContactRole.EFB_CONTACT).Company.Name }})
                                </span>
                                <br />
                                {{ getContactByRole(project, ContactRole.PROJECT_LEAD).Name }}
                                <span class="cursive" v-if="getContactByRole(project, ContactRole.PROJECT_LEAD).Company && getContactByRole(project, ContactRole.PROJECT_LEAD).Company.Name">
                                    ({{ getContactByRole(project, ContactRole.PROJECT_LEAD).Company.Name }})
                                </span>
                            </p>
                        </sui-card-meta>
                    </sui-card-content>
                    <sui-card-content v-if="onlyTickets(project)">
                        <span>Antal sager</span>
                        <br/>
                        <span>{{newTroubleTicketCount}} {{newTroubleTicketCount == 1 ? 'ny' : 'nye'}} / {{activeTroubleTicketCount}} total<span v-if="onHoldTroubleTicketCount"> ({{onHoldTroubleTicketCount}} on hold)</span></span>
                    </sui-card-content>
                    <sui-card-content v-if="onlyTickets(project)">
                        <span>Nærmeste deadline</span>
                        <br/>
                        <span>{{toUserFriendlyDate(nearestTroubleTicketDeadline)}}</span>
                    </sui-card-content>
                    <sui-card-content v-if="!onlyTickets(project)">
                        <sui-label :color="ProjectStatus.getColorFromCode(project.Status)">
                            {{ ProjectStatus.getValueFromCode(project.Status) }}
                        </sui-label>
                    </sui-card-content>
                    <sui-card-content v-if="!onlyTickets(project) && settingsPl.get('showProjectTypes')">
                        <sui-label v-if="!Array.isArray(project.Type)" :color="ProjectType.getColorFromCode(project.Type)" size="small">
                            {{ ProjectType.getValueFromCode(project.Type) }}
                        </sui-label>
                        <template v-else>
                            <span 
                                v-for="(type, index) in project.Type"
                                :key="index"
                            >
                                <sui-label :color="ProjectType.getColorFromCode(type)" size="small">
                                    {{ ProjectType.getValueFromCode(type) }}
                                </sui-label>
                                <br/>
                            </span>
                        </template>
                    </sui-card-content>
                    <sui-card-content extra v-if="firebaseUser.Roles.includes(UserRoles.EFB_PROJECT_EDITOR)">
                        <sui-button
                            @click.stop="projectClicked(project,'edit')"
                        >Redigér</sui-button>
                    </sui-card-content>
                </sui-card>
            </sui-card-group>
        </div>

        <div v-if="settingsPl.get('showProjectsAsTiles') == 'list'">
            <DataCard
                no-padding
                title="Drift"
            >
                <sui-table striped selectable single-line>
                    <sui-table-header>
                        <sui-table-row>
                            <sui-table-header-cell colspan="2">ProjektNavn</sui-table-header-cell>
                            <sui-table-header-cell>Antal sager</sui-table-header-cell>
                            <sui-table-header-cell>Nærmeste deadline</sui-table-header-cell>
                            <sui-table-header-cell></sui-table-header-cell>
                        </sui-table-row>
                    </sui-table-header>
                    <sui-table-body 
                        v-for="project in projects"
                        :key="project.id"
                    >
                        <sui-table-row
                            v-if="onlyTickets(project)"
                            @click="projectClicked(project,'open')"
                        >
                            <sui-table-cell>
                                <span class="mr-5px" v-if="isUserAssociatedToProject(project, user)">
                                    <i v-if="specialOccasion == 'christmas'" class="fa-kit fa-nisse" title="Du er tilknyttet dette projekt"></i>
                                    <i v-else class="fas fa-user-hard-hat" title="Du er tilknyttet dette projekt"></i>
                                </span>
                                <strong>{{project.Name}}</strong>
                                <p style="color: gray;">
                                    <span v-if="project.ProjectBillingCategory == 'subProject'"> {{ billingCategoryLabels.subProject }} </span>
                                    <span v-else> {{ billingCategoryLabels.billingProject }} </span>
                                </p>
                            </sui-table-cell>
                            <sui-table-cell>
                                <div class="color-square" :class="getProjectColor(project)" style="float: right;"></div>
                            </sui-table-cell>
                            <sui-table-cell>{{newTroubleTicketCount}} {{newTroubleTicketCount == 1 ? 'ny' : 'nye'}} / {{activeTroubleTicketCount}} total<span v-if="onHoldTroubleTicketCount"> ({{onHoldTroubleTicketCount}} on hold)</span></sui-table-cell>
                            <sui-table-cell>{{toUserFriendlyDate(nearestTroubleTicketDeadline)}}</sui-table-cell>
                            <sui-table-cell>
                                    <sui-dropdown
                                        v-if="firebaseUser.Roles.includes(UserRoles.EFB_PROJECT_EDITOR)"
                                        floating
                                        button
                                        class="actions basic mini right-floated" 
                                        text="•••"
                                    >
                                        <sui-dropdown-menu>
                                            <sui-dropdown-item @click.stop="projectClicked(project,'edit')">
                                                <sui-icon name="edit" />
                                                Rediger projekt
                                            </sui-dropdown-item>
                                        </sui-dropdown-menu>
                                    </sui-dropdown>
                                </sui-table-cell>
                        </sui-table-row>
                    </sui-table-body>
                </sui-table>
            </DataCard>
            <DataCard
                no-padding
                :title="projectListTitle"
                :actions="firebaseUser.Roles.includes(UserRoles.EFB_PROJECT_EDITOR) ? [
                    { type: 'button', icon: 'plus', label: 'Opret ny', eventName: 'project-list-new-btn' },
                ] : null"
            >
                <sui-table striped selectable single-line>
                    <sui-table-header>
                        <sui-table-row>
                            <sui-table-header-cell colspan="2">Projektnavn</sui-table-header-cell>
                            <sui-table-header-cell>Kontaktpersoner</sui-table-header-cell>
                            <sui-table-header-cell>Status</sui-table-header-cell>
                            <sui-table-header-cell v-if="settingsPl.get('showProjectTypes')">Type(r)</sui-table-header-cell>
                            <sui-table-header-cell colspan="2">Antal Omr&aring;der</sui-table-header-cell>
                        </sui-table-row>
                    </sui-table-header>
                    <sui-table-body 
                        v-for="project in projects"
                        :key="project.id"
                    >
                        <sui-table-row
                            v-if="!onlyTickets(project)"
                            @click="projectClicked(project,'open')"
                        >
                            <sui-table-cell>
                                <span class="mr-5px" v-if="project._associated">
                                    <i v-if="specialOccasion == 'christmas'" class="fa-kit fa-nisse" title="Du er tilknyttet dette projekt"></i>
                                    <i v-else class="fas fa-user-hard-hat" title="Du er tilknyttet dette projekt"></i>
                                </span>
                                <strong>{{ project.Name }}</strong>
                                <p style="color: gray;">
                                    <span v-if="project.ProjectBillingCategory == 'subProject'"> {{ billingCategoryLabels.subProject }} </span>
                                    <span v-else> {{ billingCategoryLabels.billingProject }} </span>
                                </p>
                            </sui-table-cell>
                            <sui-table-cell>
                                <div class="color-square" :class="getProjectColor(project)" style="float: right; margin-top: 5px;"></div>
                            </sui-table-cell>
                            <sui-table-cell>
                                <p>
                                    {{ getContactByRole(project, ContactRole.EFB_CONTACT).Name }}
                                    <span class="cursive" v-if="getContactByRole(project, ContactRole.EFB_CONTACT).Company && getContactByRole(project, ContactRole.EFB_CONTACT).Company.Name">
                                        ({{ getContactByRole(project, ContactRole.EFB_CONTACT).Company.Name }})
                                    </span>
                                    <br />
                                    {{ getContactByRole(project, ContactRole.PROJECT_LEAD).Name }}
                                    <span class="cursive" v-if="getContactByRole(project, ContactRole.PROJECT_LEAD).Company && getContactByRole(project, ContactRole.PROJECT_LEAD).Company.Name">
                                        ({{ getContactByRole(project, ContactRole.PROJECT_LEAD).Company.Name }})
                                    </span>
                                </p>
                            </sui-table-cell>
                            <sui-table-cell>
                                <sui-label :color="ProjectStatus.getColorFromCode(project.Status)">
                                    {{ ProjectStatus.getValueFromCode(project.Status) }}
                                </sui-label>
                            </sui-table-cell>
                            <sui-table-cell v-if="settingsPl.get('showProjectTypes')">
                                <sui-label v-if="!Array.isArray(project.Type)" :color="ProjectType.getColorFromCode(project.Type)" size="small">
                                    {{ ProjectType.getValueFromCode(project.Type) }}
                                </sui-label>
                                <template v-else>
                                    <span 
                                        v-for="(type, index) in project.Type"
                                        :key="index"
                                    >
                                        <sui-label :color="ProjectType.getColorFromCode(type)" size="small">
                                            {{ ProjectType.getValueFromCode(type) }}
                                        </sui-label>
                                        <br />
                                    </span>
                                </template>
                            </sui-table-cell>
                            <sui-table-cell>
                                <span v-if="!project.AreaCodes.length">
                                    -
                                </span>
                                <span v-else-if="project.AreaCodesType == 'whitelist'">
                                    {{ project.AreaCodes.length }}
                                </span>
                                <span v-else>
                                    -{{ project.AreaCodes.length }}
                                </span>
                            </sui-table-cell>
                            <sui-table-cell>
                                <sui-dropdown
                                    v-if="firebaseUser.Roles.includes(UserRoles.EFB_PROJECT_EDITOR)"
                                    floating
                                    button
                                    class="actions basic mini right-floated" 
                                    text="•••"
                                >
                                    <sui-dropdown-menu>
                                        <sui-dropdown-item @click.stop="projectClicked(project,'edit')">
                                            <sui-icon name="edit" />
                                            Rediger projekt
                                        </sui-dropdown-item>
                                    </sui-dropdown-menu>
                                </sui-dropdown>
                            </sui-table-cell>
                        </sui-table-row>
                    </sui-table-body>
                    <sui-table-body v-if="!projectList.length">
                        <sui-table-cell colspan="5">
                            <TableLoader />
                        </sui-table-cell>
                    </sui-table-body>
                </sui-table>
            </DataCard>
        </div>

        <edit-settings-modal
            :isOpen="editSettings"
            :settings="settingsPl"
            :hasProjectTasks="true"
            :hasTroubleTickets="true"
            page="project list"
            :allowShowOverride="false"
        />

    </sui-container>
</template>

<script>
import { db } from '../../firebase'
import EventBus from '../../EventBus'
import { DataAPI } from '../../lib/DataAPI'
import { Mixin } from '../../lib/Mixins/mixin'
import { DateMixin } from '../../lib/Mixins/dateMixin'
import { CoreMixin } from '../../lib/core/coreMixin'
import ContactRole from '../../lib/Enums/ContactRole'
import ProjectType from '../../lib/Enums/ProjectType'
import ProjectStatus from '../../lib/Enums/ProjectStatus'
import TableLoader from '../../components/TableLoader'
import TicketState from '../../lib/Enums/TicketState'
import CachedSettings from '@/modules/Settings/settingsClass'
import EditSettingsModal from '@/modules/Settings/EditSettingsModal.vue'
import UserRoles from '../../lib/Enums/UserRoles'
import { specialOccasionsMixin } from '../../lib/Mixins/specialOccasionsMixin'

export default {
    name: 'ProjectList',
    mixins: [DataAPI, Mixin, DateMixin, CoreMixin, specialOccasionsMixin],

    components: {
        TableLoader,
        EditSettingsModal
    },

    enums: {
        ContactRole,
        ProjectType,
        ProjectStatus,
        TicketState,
        UserRoles
    },

    data() {
        return {
            projectList: [],
            settingsPl: new CachedSettings(),
            editSettings: false,


                // Sorting
            sortingCurrent: 0,
            sortingOptions: [
                { text: 'Stigende', value: 0 },
                { text: 'Faldende', value: 1 }
            ],

            billingCategoryLabels: {
                subProject: 'Delprojekt',
                billingProject: 'Faktureringsprojekt'
            },

            troubleTickets: [],
        }
    },

    firestore: {
        projectList: db.collection('Projects'),
    },

    computed: {
        sortAscending() {
            if (this.settingsPl.get('sortProjectsAscending') == '0') return true
            return false
        },

        user() {
            return this.$store.state.userProfile
        },

        firebaseUser() {
            return this.$root.$children[0].firebaseUser
        },

        currentMedia() {
            return this.$root.$children[0].currentMedia
        },

        projectCount() {
            let count = 0;
            for (let project of this.projects){
                this.onlyTickets(project) ? count : count++;
            }
            return count;
        },

        projectListTitle() {
            return `Projekter (${this.projectCount})`;
        },

        projects() {
            if (!this.projectList.length) return []

            let projects = []
            this.projectList.forEach(project => {
                project._associated = this.isUserAssociatedToProject(project, this.user)

                if (this.settingsPl.get('showOnlyAssociated') && !project._associated) return
                if (this.settingsPl.get('showOnlyInProgress') && project.Status != ProjectStatus.IN_PROGRESS) return
                if (!this.settingsPl.get('showClosedProjects') && project.Status == ProjectStatus.CLOSED) return
                if (project.Name == 'Driftsager'){
                    projects.unshift(project)
                } else {
                    projects.push(project)
                }
            })

            return projects
        },

        newTroubleTicketCount() {
            let newTroubleTickets = this.troubleTickets.filter((task) => {
                return task.state.value == TicketState.NEW
            })
            return newTroubleTickets.length
        },

        activeTroubleTicketCount() {
            return this.troubleTickets.length
        },

        onHoldTroubleTicketCount() {
            let onHoldTroubleTickets = this.troubleTickets.filter((task) => {
                return task.state.value == TicketState.ON_HOLD
            })
            return onHoldTroubleTickets.length
        },

        nearestTroubleTicketDeadline() {
            let ticketsNotOnHold = this.troubleTickets.filter((task) => {
                return task.state.value != TicketState.ON_HOLD
            })
            let earliestDate = null
            for (let i in ticketsNotOnHold) {
                let date = ticketsNotOnHold[i].dueDate
                if (date < earliestDate || earliestDate == null) {
                    earliestDate = date
                }
            }
            return earliestDate
        }
    },

    methods: {
        getTileGridWidth(){
            if (this.currentMedia.type == 'Tablet' && this.currentMedia.orientation == 'Portrait') return 2
            return 3
        },

        getProjectColor(project){
            return project.BackgroundColor
        },

        onlyTickets(proj){
            if (proj.Type.length == 1 && proj.Type[0] == ProjectType.TROUBLE_TICKETS) return true
            return false
        },
        
        createProject(){
            console.log("Creating new project")
            this.$router.push('/projects/create')
        },
        onEditSettingsButtonClicked(){
            this.editSettings = true
            console.log("Opening settings modal")
        },

        projectClicked(project, mode){
            if (mode == 'edit'){
                console.log("Edit",project.Name)
                this.editProject(project.id)
            } else {
                this.listItemClicked(project)
            }
        },

        listItemClicked(project) {
            this.$store.dispatch('setActiveProject', project)
            this.$store.dispatch('setActiveProjectId', project.id)
            let page = window.localStorage.getItem('last-visited-page')
            if (!page) page = 'booking'
            this.$router.push(`/projects/${project.id}/${page}`)
        },

        getContactByRole(project, role) {
            if (!project.Contacts) return {}
            let contact = project.Contacts.find(contact => {
                if (!contact.Roles) return false
                return contact.Roles.includes(role)
            })

            if (!contact) return {}
            return contact
        },

        editProject(id) {
            this.$router.push(`/projects/edit/${id}`)
        },

        toggleSetting(action) {
            if (!Object.prototype.hasOwnProperty.call(this, action)) {
                throw new Error(`Unknown setting "${action}"`)
            }

            this[action] = !this[action]
            window.localStorage.setItem(`project-page-${action}`, this[action])
        },

        async getTroubleTickets() {
            EventBus.$emit('function-activity', {functionName: 'Project/List_getTroubleTickets', isActive: true})
            let apiResponse = await this.dataGetTroubleTickets().catch((error) => {
                console.error(error)
            })
            this.troubleTickets = apiResponse || []
            EventBus.$emit('function-activity', {functionName: 'Project/List_getTroubleTickets', isActive: false})
        },
    },

    async mounted() {
        EventBus.$emit('function-activity', {functionName: 'Project/List_mounted', isActive: true})

        EventBus.$on('edit-settings-modal-closing', () => {this.editSettings = false})
        EventBus.$on('edit-settings-modal-open', () => {this.editSettings = true})
        EventBus.$on('project-list-new-btn-click', () => {
            this.$router.push('/projects/create')
        })
        
        this.$bind('projectList', db.collection('Projects').orderBy('Status', 'asc').orderBy('Name', 'asc'))

        try {
            await this.runWhenPropertySet(() => {return this.$root.$children[0].isReady}, this.getTroubleTickets)  
        } catch (error) {
            console.error(error)
        }
        EventBus.$emit('function-activity', {functionName: 'Project/List_mounted', isActive: false})
    },

    beforeDestroy() {
        EventBus.$off('project-list-new-btn-click') // removes all listeners
        EventBus.$off('edit-settings-modal-closing') // removes all listeners
        EventBus.$off('edit-settings-modal-open') // removes all listeners
    },


    watch: {
        sortAscending: {
            immediate: true,
            handler(sortAscending) {
                let sorting = sortAscending ? 'asc' : 'desc'
                this.$bind('projectList', db.collection('Projects')
                    .orderBy('Status', sorting).orderBy('Name', sorting)
                )
            },
        },
    }
}
</script>
<style scoped>

.color-square {
    height: 35px;
    width: 35px;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.table tr td {
    cursor: pointer;
}

.right-floated {
    float: right;
}

table tr th:nth-child(2) {
    width: 350px;
}

.fa-nisse {
    color: #708090;
}

.fa-user-hard-hat {
    color: #708090;
}
</style>
<style>
.actions.ui.basic.button {
    box-shadow: none;
}
.actions.ui.dropdown>.dropdown.icon {
    display: none !important;
}
.actions.ui.dropdown>.menu {
    left: -105px !important;
}
.cursive {
    font-style: italic;
}

.mr-5px {
    margin-right: 5px;
}
</style>
