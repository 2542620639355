<template>    
    <sui-modal small :open="isOpen" v-on:clickAwayModal="closeModal()">
        <sui-modal-header>
            <div class="row">
                <div class="col-sm-10">
                    <span v-if="!openFileLoading && !!openFile">{{openFile.name}}</span><span v-else>Indlæser fil...</span>
                </div>
                <div class="col-sm-2" style="text-align: right;">
                    <sui-button icon="download" @click="downloadBase64File(openFile.type, openFile.base64Content, openFile.name)"></sui-button>
                    <sui-button icon="times" @click="closeModal()"></sui-button>
                </div>
            </div>
        </sui-modal-header>
        <sui-table v-if="openFileLoading">
                <TableLoader/> <!--This loader doesn't show for some reason-->
        </sui-table>
        <sui-modal-content v-else-if="!!openFile" scrolling class="centered">
            <img v-if="openFile.type.substr(0,5) == 'image'" :src="`data:${openFile.type};base64,${openFile.base64Content}`" class="imageAttachment"/>
            <adobe-embed-pdf v-else-if="openFile.type == 'application/pdf'" :openFile="openFile"/>
            <iframe v-else width="100%" height="550" scrolling="auto" :src="`data:${openFile.type};base64,${openFile.base64Content}`"><em>Denne fil understøttes ikke af din browser<br><a :download="openFile.name" :href="`data:${openFile.type};base64,${openFile.base64Content}`">Klik her for at downloade</a></em></iframe>
            <br><br><br>
            <sui-accordion exclusive styled fluid>
                <sui-accordion-title>
                    <sui-icon name="dropdown" />
                    Se rå meta-data
                </sui-accordion-title>
                <sui-accordion-content>
                    <pre style="white-space: pre-wrap; word-break: break-all;">
                        {{openFile}}
                    </pre>
                </sui-accordion-content>
            </sui-accordion>
        </sui-modal-content>
    </sui-modal>
</template>
<script>
import { Mixin } from '../../lib/Mixins/mixin'
import { Mime } from '../../lib/helpers/mime'
import EventBus from '../../EventBus'
import TableLoader from '../../components/TableLoader'
import { analytics } from '../../firebase'
import AdobeEmbedPdf from './AdobePdfEmbed.vue'

export default {
    name: 'FileViewerModal',
    mixins: [Mixin, Mime, EventBus, analytics],
    components: {TableLoader, AdobeEmbedPdf},

    props: {
        openFile: {
            type: Object,
            requred: true,
        },
        isOpen: {
            type: Boolean,
            requred: true,
            default: false,
        },
        openFileLoading: {
            type: Boolean,
            requred: false,
            default: false,
        }
    },

    methods: {
        closeModal() {
            EventBus.$emit('file-viewer-modal-close')
        },

        downloadBase64File(contentType, base64Data, fileName) {

            analytics.logEvent('attachment_download', {contentType})

            const linkSource = `data:${contentType};base64,${base64Data}`;
            const downloadLink = document.createElement("a");
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
       },
    },

    watch: {
        isOpen: {
            immediate: false,
            handler(val) {
                if (val) {
                    analytics.logEvent('attachment_open')
                }
            }
        }
    }

}
</script>