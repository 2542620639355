<template>
    <sui-modal
        name="fileUploadModal"
        id="fileUploadModal"
        :open="isModalOpen" 
        v-on:clickAwayModal="clickAway"
    >
        <sui-modal-header>Upload fil</sui-modal-header>
        <sui-modal-content scrolling>
            <sui-form :success="fileFormSuccess" @submit.prevent="submitFile" :loading="fileFormLoading">
                    <sui-form-field>
                        <sui-message>
                            <sui-message-header>Vejledning</sui-message-header>
                            <strong>Mappe-struktur:</strong><br>
                            For at uploade en fil til en ny mappe, skal du i stedet for filnavnet skrive mappenavnet, efterfulgt af en skråstreg, og så filnavnet (ex. 'mappe/fil').
                            Samme struktur kan fortsættes i flere niveauer (ex. 'mappe/længere ind/helt ind i sindet/fil') <br>
                            Den sti du fastsætter starter i den mappe du stod i da du trykkede 'upload'<br>
                            Man kan ikke oprette tomme mapper, og hvis den sidste fil i en mappe slettes, slettes mappen også.<br>
                            Pga den ovennævnte funktionalitet kan man ikke have to skråstreger i træk, eller slutte med en skråstreg
                        </sui-message>
                        <label>Navngiv den uploadede fil</label>
                        <input type="text" placeholder="FilNavn" v-model="fileNameInput"/>
                    </sui-form-field>
                    <sui-form-field>
                        <input type="file" @change="setFileUpload"/>
                    </sui-form-field>
                    <sui-message> Uploadede filer opbevares i FiberLANs database, og kan ikke ses af EnergiFyn</sui-message>
                    <sui-button type="submit" :disabled="!fileUpload">Gem</sui-button>
                    <sui-message success>Fil uploadet til database</sui-message>
                </sui-form>
                <div v-if="showProgress">
                    <hr>
                    <sui-progress size="large" :percent="uploadProgress" :label="`Upload status: ${uploadState}`" :state="progressBarState"/>
                </div>
        </sui-modal-content>
    </sui-modal>
</template>

<script>
import EventBus from '../../EventBus'
import { storageRef } from '../../firebase'
import { Mime } from '../../lib/helpers/mime'
import swal from 'sweetalert'

export default {
    mixins: [Mime],

    props: {
        isModalOpen: {
            type: Boolean,
            required: true
        },
        path: {
            type: String,
            required: true
        },
    },

    data() {
        return {
            fileFormLoading: false,
            fileFormSuccess: false,

            fileNameInput: null,
            fileUpload: null,

            showProgress: false,
            uploadProgress: null,
            uploadState: null,
            progressBarState: 'disabled',
        }
    },

    methods: {
        clickAway() {
            EventBus.$emit('file-upload-modal-closing')
        },

        setFileUpload(e){
            this.fileFormSuccess = false
            this.showProgress = false
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) {
                this.fileUpload = null
                return false
            }
            this.fileUpload = files[0]
            return true
        },

        async encodeFile(file){
            EventBus.$emit('function-activity', {functionName: 'FileUploadModal_encodeFile', isActive: true})
            const toBase64 = file => new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = () => resolve (reader.result)
                reader.onerror = error => reject(error)
            })
            let encodedFile = await toBase64(file).catch(e => Error(e))
            if(encodedFile instanceof Error) {
                console.log('Error encoding file: ',encodedFile.message)
                EventBus.$emit('function-activity', {functionName: 'FileUploadModal_encodeFile', isActive: false})
                return
            }
            EventBus.$emit('function-activity', {functionName: 'FileUploadModal_encodeFile', isActive: false})
            return encodedFile
        },

        async submitFile(){
            EventBus.$emit('function-activity', {functionName: 'FileUploadModal_submitFile', isActive: true})
            this.fileFormLoading = true //Start loading animation
            this.progressBarState = 'active'
            this.showProgress = true
            this.fileFormSuccess = false
            let base64Content = await this.encodeFile(this.fileUpload)
            // console.log(base64Content)
            base64Content = String(base64Content).substring(base64Content.indexOf('base64,')+7)
            // console.log(base64Content)
            
            let fileName = this.fileNameInput
            if (!fileName) fileName = this.fileUpload.name
            
            const expectedExtension = this.getExtenstionByMimeType(this.fileUpload.type)
            const fileExtension = this.getFileExtension(fileName)
            if (fileExtension.toLowerCase() != expectedExtension) {
                fileName += `.${expectedExtension}`
            }

            let metadata = {
                contentType: this.fileUpload.type,
                customMetadata: { //Custom metadata for uploads to Firebase storage MUST be a simple object with no nesting
                    'uploadedBy': this.$parent.user.email
                }
            }

            let newFileRef = storageRef.child(`${this.path}/${fileName}`)
            let uploadTask = newFileRef.putString(base64Content, 'base64', metadata)

            uploadTask.on('state-changed', (snapshot) => {
                let progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 1000) / 10
                console.log(`Upload ${progress}% complete`)
                this.uploadProgress = progress
                this.uploadState = snapshot.state
                
                switch(snapshot.state){
                    case 'running':
                        this.progressBarState = 'active'
                        break;
                    case 'paused':
                        this.progressBarState = 'warning'
                        break;
                }

            },
            (error) => {
                console.error(`Encountered error while uploading: ${error}`)
            },
            async () => {
                console.log('UPLOAD SUCCESSFUL')
                this.fileFormSuccess = true
                this.progressBarState = 'success'

                // reset modal
                this.uploadFileModalOpen = false
                this.fileNameInput = null
                this.fileFormLoading = false //End loading animation
                EventBus.$emit('function-activity', {functionName: 'FileUploadModal_submitFile', isActive: false})
                await swal('Success!','Din fil blev uploadet til databasen', 'success')
                EventBus.$emit('file-uploaded')
            })

        },
    }
}
</script>

<style>

</style>