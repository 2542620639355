import EventBus from "../../EventBus";
import TaskState from "../Enums/TaskState"
import TaskCode from "../Enums/TaskCode";
import TicketState from "../Enums/TicketState"
import AppointmentType from "../Enums/AppointmentType.js"
import { phoneMixin } from "./phoneMixin";

export const Mixin = {

    mixins: [phoneMixin],

    enums: {
        TaskState,
        TicketState,
        AppointmentType,
        TaskCode,
    },

    methods: {
        up(){
            let upperPath = this.$route.path.split("/")
            if(upperPath.length > 0) {
                upperPath.splice(upperPath.length - 1)
                this.$router.push(upperPath.join("/"))
            }
        },

        executeFunction(functionName, /*, args*/){
            if (functionName === 'executeFunction'){
                throw new Error('Execute Function by String: Recursive function call detected')
            }

            if (typeof this[functionName] === "function"){
                var args = Array.prototype.slice.call(arguments, 1)
                return this[functionName].apply(this, args)
            } else {
                if (functionName === null){
                    return
                }
                if (typeof functionName === 'undefined'){
                    console.warn('Execute Function by String: Function name is undefined')
                 } else {
                    console.error(`Execute Function by String: Function '${functionName}' not found`)
                 }

            }
        },

        getISOTimestamp(){
            let timestamp = new Date()
            return timestamp.toISOString()
        },

        chunkify(inputArray, chunkSize){
            if (chunkSize == 0){
                throw new Error("Chunksize of Zero will result in infinite loop")
            }
            let results = inputArray.reduce((all,one,i) => {
                const ch = Math.floor(i/10); 
                all[ch] = [].concat((all[ch]||[]),one); 
                return all
            }, [])
            return results
        },

        classes(...classList) {
            return classList.filter(c => !!c).join(' ');
        },

        getConfiguration(task){
            if (task?.code != TaskCode.TICKET) return task?.configurationItem
            return task?.installation || task?.configurationItem
        },

        getConfigurationLabel(task){
            if (task?.code != TaskCode.TICKET) return task?.configurationItem?.label
            return task?.installation?.number || task?.configurationItem?.number
        },

        checkIfEmail(email) {
            const reqex = /\S+@\S+\.\S+/;
            return reqex.test(email)
        },

        asyncProp(source, prop, defaultValue = false) {
            if (source instanceof Object && source.length === undefined) {
                if (Object.prototype.hasOwnProperty.call(source, prop)) {
                    return source[prop]
                } else {
                    return defaultValue !== false ? defaultValue : '<span class="loading-text"></span>'
                }
            } else {
                return defaultValue !== false ? defaultValue : '<span class="loading-text"></span>'
            }
        },

        toTitleCase(str) {
            return str.replace(/\w\S*/g, (txt) => {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
            })
        },

        formatRoad(road){
            if (!road) return ''
            let roadArray = road.split(' ')
            let formattedRoadString = ''
            for (let i in roadArray) {
                let roadWord = roadArray[i]
                let formattedRoadWord = roadWord.substr(0,1).toUpperCase()+roadWord.substr(1).toLowerCase()
                formattedRoadString += ` ${formattedRoadWord}`
            }
            return formattedRoadString.trim()
        },

        formatAddress(address, short = true, flipped = false) {
            if(!address || typeof address == 'string'){
                // throw new Error('Cannot format null address')
                // console.error('Cannot format NULL address')
                return JSON.stringify(address)?.replaceAll('"','') || null
            }
            let display = `${this.formatRoad(address.road)} ` // Formatted road name with space at the end

            let tmpString = ''
            if (address.number) {
                tmpString += address.number
            }

            if (address.letter) {
                tmpString += address.letter
            }

            if (address.floor) {
                tmpString += `, ${address.floor}`
            }

            if (address.appartment) {
                tmpString += ` ${address.appartment}`
            }
            if (flipped) {
                tmpString += `, ${display}`
                display = tmpString.trim();
            } else {
                display += tmpString
            }

            if (!short && address.zipcode && address.city) {
                display += `, ${address.zipcode} ${address.city}`
            }

            return display
        },

        reformatAddress(address, flipped = false) {
            if (!address) return address

            // Check if the 'flipped' flag is false or if there's no comma in the address
            if (!flipped || !address.includes(',')) return address

            // Split the address by the comma
            let parts = address.split(',');

            // Split the first part of the address by space
            let firstPart = parts[0].trim().split(' ');

            // Extract the house number (last element) and join the rest as the street name
            let houseNumber = firstPart.pop();
            let streetName = firstPart.join(' ');

            // Reconstruct the first part with the house number first, followed by any additional part
            let flippedFirstPart;

            if (parts.length == 3) {
                flippedFirstPart = [houseNumber, parts[1] ? parts[1].trim() : '', streetName].join(', ').trim();
            } else {
                flippedFirstPart = [houseNumber, streetName, parts[1]].join(', ').trim();
            }

            // Combine the flipped first part with the rest of the address (e.g., city and postal code)
            return [flippedFirstPart, ...parts.slice(2)].join(', ').trim();
        },

        generateUserProfileImageFromName(name) {
            const firstLetter = name.substring(0, 1)
            const bgColor = this._intToRGB(this._hashCode(name))
            const brightness = this._getHexBrightness(bgColor)
            const fgColor = brightness > 155 ? '333333' : 'FAFAFA'

            return {
                text: firstLetter,
                bgColor: `#${bgColor}`,
                fgColor: `#${fgColor}`,
                brightness: brightness
            }
        },

        _hashCode(str) {
            let hash = 0
            for (let i = 0; i < str.length; i++) {
               hash = str.charCodeAt(i) + ((hash << 5) - hash)
            }
            return hash
        }, 
        
        _intToRGB(i){
            let c = (i & 0x00FFFFFF)
                .toString(16)
                .toUpperCase()
        
            return "00000".substring(0, 6 - c.length) + c
        },

        _getHexBrightness(color) {
            const hex = color.replace('#', '')
            const c_r = parseInt(hex.substr(0, 2), 16)
            const c_g = parseInt(hex.substr(2, 2), 16)
            const c_b = parseInt(hex.substr(4, 2), 16)
            const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000
            return brightness
        },

        formatBytes(bytes, decimals = 2) {
            if (bytes === 0) return '0 Bytes';
            const k = 1024; //Bytes per KB / KB per MB etc...
            const dm = decimals < 0 ? 0 : decimals; //No negative decimal count
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']; //Size name for k^i bytes
            const i = Math.floor(Math.log(bytes) / Math.log(k)); //Logarithm of bytes divided by logaritm of k to find best power of k
            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]; //Bytes divided by power of k, followed by relevant suffix
        },

        objExistsInArray(propertyName, propertyValue, array){
            // console.log(propertyName, propertyValue, array)
            if (!array) return false
            for (var i=0; i<array.length; i++){
                if (array[i][propertyName] == propertyValue){
                    return true
                }
            }
            return false
        },

        getStateColor(state) {
            switch (state.value) {
                case TaskState.PENDING:
                case TaskState.ON_HOLD:
                    return "grey"
                case TaskState.OPEN:
                    return "blue"
                case TaskState.WORK_IN_PROGRESS: 
                    return "yellow"
                case TaskState.CLOSED_COMPLETE: 
                    return "green"
                case TaskState.CLOSED_INCOMPLETE: 
                case TaskState.CLOSED_SKIPPED: 
                    return "olive"
                default: 
                    return "black"
            }
        },

        getTtStateColor(state) {
            if (!state) return "black" //Colors are not critical, so dont throw an error here, instead use default value
            if (!state.value){
                state = {value: state} //In case the value is passed directly to this function
            }
            switch (state.value) {
                case TicketState.NEW: 
                    return "blue"
                case TicketState.WORK_IN_PROGRESS: 
                    return "yellow"
                case TicketState.ON_HOLD: 
                    return "grey"
                case TicketState.RESOLVED: 
                case TicketState.CANCELED:
                    return "olive"
                case TicketState.CLOSED: 
                    return "green"
                default: 
                    return "black"
            }
        },

        readFromDropdown(n, arr){
            if (n == null || !arr) return null
            let obj = arr.find(c => c.value == n)
            return obj.text
        },

        insContainsState(ins, state) {
            let outputBool = false
            Object.entries(ins.tasks).forEach(([key,t]) => {
                if(ins.tasks[key] != t || !t.state || !t.state.value){
                    console.error(`Something went wrong when checking task state in insContainsState: Could not check state of object: ${JSON.stringify(t)}`)
                }
                if (t && t.state && t.state.value == state) outputBool = true
            })
            for (var t in ins.tasks) {
                if (t && t.state && t.state.value == state) outputBool = true
            }
            return outputBool
        },

        insContainsOnlyState(ins, state) {
            if (!ins?.tasks ||!ins?.tasks.length) return false //Return false in the case where there are no tasks
            let outputBool = true
            Object.entries(ins.tasks).forEach(([key, t]) => {
                if(ins.tasks[key] != t || !t.state || !t.state.value){
                    console.error(`Could not check state of object: ${JSON.stringify(t)}`)
                    throw new Error('Something went wrong when checking task state in insContainsOnlyState')
                }
                if (t.state.value != state) {
                    outputBool = false
                }
            })
            return outputBool
        },

        insHasTaskWithCode(ins, code) {
            let outputBool = false
            try {
                Object.entries(ins.tasks).forEach(([key, t]) => {
                    if(ins.tasks[key] != t || (!t.code && typeof t.code != 'string')){
                        throw new Error('Something went wrong when checking task code')
                    }
                    if (t.code == code) {
                        outputBool = true
                        return outputBool
                    }
                })
            } catch (error) {
                return outputBool
            }
            return outputBool
        },

        getCurrentAppointmentType(inst, force = false){  
            // console.log(inst)
            let closedStateOptions = [TaskState.CLOSED_COMPLETE, TaskState.CLOSED_INCOMPLETE, TaskState.CLOSED_SKIPPED]
            if (inst?.appointment && !force){ // If installation has appointment return appointmenttype
                return inst.appointment.AppointmentType
            } else if (inst?.appointmentTypes && !force){ // If already calculated
                if (inst.appointmentTypes.length === 1){
                    return inst.appointmentTypes[0].appointmentType
                } else {
                    let hasClosedInstallation = false
                    for (let i in inst.appointmentTypes){
                        if (closedStateOptions.includes(inst.appointmentTypes[i].state.value) && inst.appointmentTypes[i].appointmentType == AppointmentType.INSTALLATION){
                            hasClosedInstallation = true
                        }

                        if (!closedStateOptions.includes(inst.appointmentTypes[i].state.value)){
                            return inst.appointmentTypes[i].appointmentType
                        }
                    }
                    if (hasClosedInstallation){
                        return AppointmentType.INSTALLATION
                    }
                }
            }
            else 
            {
                let foundTaskCodes = []
                for (let i in inst.tasks){
                    if (inst.tasks[i]?.code) {
                        foundTaskCodes.push(inst.tasks[i].code)
                    }
                }

                let relevantAppointmentTypes = AppointmentType.appointmentTypesFromTaskCodes(foundTaskCodes)
                
                if (!relevantAppointmentTypes.length){
                    relevantAppointmentTypes = AppointmentType.appointmentTypesFromTaskCodes(foundTaskCodes, true) // Try again with ignoreMissingRequiredTasks = true
                    if (!relevantAppointmentTypes.length){
                        relevantAppointmentTypes = [AppointmentType.UNKNOWN]
                    }        
                }

                if (inst.label == '70401435') {
                    console.log('Kirkevej 12', relevantAppointmentTypes)
                } else if (inst.label == '70401363') {
                    console.log('Kirkevej 3', relevantAppointmentTypes)
                }
                
                if (relevantAppointmentTypes.length === 1){
                    inst.appointmentTypes = [{appointmentType: relevantAppointmentTypes[0], state: inst.tasks[0].state}]
                    return relevantAppointmentTypes[0]
                } else {
                    let appointmentTypesOnInst = []
                    for (let i in relevantAppointmentTypes){
                        let primaryTaskCode = AppointmentType.AppointmentTasks[relevantAppointmentTypes[i]].Primary.TaskCode
                        let stateIndex = inst.tasks.findIndex((a) => a.code === primaryTaskCode)
                        let state  = null
                        if (stateIndex != -1){
                            state = inst.tasks[stateIndex].state
                            appointmentTypesOnInst.push({appointmentType: relevantAppointmentTypes[i], state: state})
                        }
                    }
                    inst.appointmentTypes = appointmentTypesOnInst

                    for (let i in relevantAppointmentTypes){
                        //If this is the last appointmentType, return it regardless of state
                        if (i == relevantAppointmentTypes.length - 1){
                            return relevantAppointmentTypes[i]
                        }
                        //If the state of the primary task for the appointmentType is not closed, return it
                        let primaryTaskCode = AppointmentType.AppointmentTasks[relevantAppointmentTypes[i]].Primary.TaskCode
                        let primaryTaskIndex = inst.tasks.findIndex((t) => t.code === primaryTaskCode)
                        let state  = {value: null}
                        if (primaryTaskIndex != -1){
                            state = inst.tasks[primaryTaskIndex].state
                        }
                        if (!closedStateOptions.includes(state.value)){
                            return relevantAppointmentTypes[i]
                        }
                    }
                }
            }
            
            inst.appointmentTypes = [{appointmentType: AppointmentType.UNKNOWN, state: inst.tasks[0].state}]
            return AppointmentType.UNKNOWN
        },

        isPatchTask(inst){
            if (this.insHasTaskWithCode(inst, TaskCode.PATCH) && !this.insHasTaskWithCode(inst, TaskCode.CPE)) return true
            return false
        },

        isExtendedTask(inst){
            if (this.insHasTaskWithCode(inst, TaskCode.UDVID) && !this.insHasTaskWithCode(inst, TaskCode.CPE)) return true
            return false
        },

        mergeObjectsOnProp(sources, property) {
            let combinedDataset = []
            sources.forEach(source => {
                combinedDataset.push(...source[property])
            })
            return combinedDataset
        },

        mergeObjectsOnPropByKey(sources, property, key) {
            let combinedDataset = this.mergeObjectsOnProp(sources, property)
            combinedDataset = [...new Map(combinedDataset.map(i => [i[key], i])).values()]

            return combinedDataset
        },

        mergeObjectsOnPropByValue(sources, property) {
            let combinedDataset = this.mergeObjectsOnProp(sources, property)
            combinedDataset = [...new Map(combinedDataset.map(i => [i, i])).values()]

            return combinedDataset
        },

        isDefined(property) {
            return typeof property !== typeof undefined
        },

        lowercase(str) {
            return String(str).toLowerCase()
        },

        getFileExtension(fileName) {
            if (!fileName) return null
            let ext = fileName.split('.').reverse()
            if (!ext) return null
            return ext[0]
        },

        getInitialsFromEmail(email) {
            if (!email) return null
            let output = ''
            let atSignIndex = email.indexOf('@')
            if (atSignIndex == -1) return email.toUpperCase() //In case there is no '@', return the original string capitalized
            output = email.substring(0,atSignIndex).toUpperCase()
            return output
        },

        getSubtaskStateColor(stateObj) {
            if (stateObj.isPositive) return "green";
            return "blue";
        },

        generateGoogleMapsLink(coordinates, address) {
            let baseURL = "https://www.google.com/maps/dir/?api=1&destination="
            if (coordinates) {
                let coordString = `${coordinates.Lat},${coordinates.Lng}`
                return baseURL+coordString
            }
            if (address) {
                let adr = this.formatAddress(address, false)
                let addressURIString = encodeURIComponent(this.formatAddress(adr, false))
                return baseURL+addressURIString
            }
            throw new Error('Needs either coordinates or address to make link to google maps')
        },

        linkToGoogleMapsDirections(coordinates, address) {
            let link = this.generateGoogleMapsLink(coordinates,address)
            EventBus.$emit('link-to-external-url',link)
        },

        resolveObjPath(path, obj=self, separator='.') {
            if (!path) return obj //If there is no path, return the root obj
            var properties
            if (Array.isArray(path)) {
                properties = path
            }
            else if (typeof path != 'string') {
                console.error(`unexpected path data type: ${typeof path}`)
            }
            else if (path.indexOf(separator) == -1) {
                properties = [path]
            } else {
                properties = path.split(separator)
            }
            return properties.reduce((prev, curr) => prev && prev[curr], obj)
        },

        isUserAssociatedToProject(project, user) {
            
            for (let i in project.Contacts) {
                if (project.Contacts[i].Email == user.Email) {
                    return true
                }
            }

            for (let i in project.Workers) {
                if (project.Workers[i].Email == user.Email) {
                    return true
                }
            }

            return false
        },

        matchSearchCriteria(ins, searchFilterValue = this.searchFilterValue) {
            if (!searchFilterValue) return true

            const installation = JSON.stringify(ins).toLowerCase()
 
            let searchFilterArray = searchFilterValue.split(" ")
            let match = true

            for (let i in searchFilterArray) {
                const searchString = searchFilterArray[i].toLowerCase()
                if (!installation.match(searchString)){
                    match = false
                }
            }

            return match
        },

        getMinPropValue(array, property) {
            let minValue
            if (array.length == 1) {
                return array[0][property]
            }
            for (let i in array) {
                let value = array[i][property]
                if (!value) continue; //Skip null values

                if (value < minValue || minValue == null) {
                    minValue = value
                }
            }
            return minValue
        },

        getMaxPropValue(array, property) {
            let maxValue
            if (array.length == 1) {
                return array[0][property]
            }
            for (let i in array) {
                let value = array[i][property]
                if (!value) continue; //Skip null values

                if (value > maxValue || maxValue == null) {
                    maxValue = value
                }
            }
            return maxValue
        },

        stringToBool(string) {
            if (!string) return false
            switch(string.toLowerCase().trim()){
                case "true": 
                case "yes": 
                case "ja": 
                case "1": 
                    return true
                default: 
                    return false
            }
        },

        /**
         * Tests weather a task or ticket is closed (its state is on the list of closed states)
         * @param {Object} task task object from Dispatch API
         * @returns {Boolean} weather the state of the task is on the list of closed states
         */
         taskHasClosedState(task) {
            if (!task.number || !task.state?.value) {
                throw new Error(`Cannot evaluate task without number (${task.number}) and state.value (${task.state?.value})`)
            }
            if (task.number.substring(0,3) == 'TRT') {
                return TicketState.closedStatesArray.includes(task.state.value)
            } else if (task.number.substring(0,7) == 'PRJTASK') {
                return TaskState.closedStatesArray.includes(task.state.value)
            } else {
                throw new Error(`Task number (${task.number}) doesn't look like a ProjectTask or TroubleTicket`)
            }
        },

        findLastTask(taskArray, preferOpen = true){
            let lastTask = {}
            if (preferOpen) { //Only do the more restrictive search if preferOpen is set to true
                for (let task of taskArray) {
                    if (this.taskHasClosedState(task)) { //Skip closed tasks
                        continue;
                    } else if (!lastTask.number || task.number > lastTask.number) { //Check if the number is higher than the one in memory
                        lastTask = task //Replace task in memory with higher numbered task
                    }
                }
                if (Object.keys(lastTask).length) { //If there is an object with properties to return
                    return lastTask //Return the found task object
                }
            }
            for (let i in taskArray) { //This loop is onlye reached if preferOpen is false, or the previous loop did not yeild an Object
                let task = taskArray[i]
                if (!lastTask.number || task.number > lastTask.number) {
                    lastTask = task
                }
            }
            return lastTask //Return the task object
        },

        /**
         * 
         * @param {Array} taskArray //Array of tasks, between which to find the first
         * @param {Boolean} preferOpen //Weather to find the first open task (if a such exists), or the first task overall
         * @returns {Object} the task object from the array, with the lowest number
         */
        findFirstTask(taskArray, preferOpen = true){
            let firstTask = {}
            if (preferOpen) { //Only do the more restrictive search if preferOpen is set to true
                for (let task of taskArray) {
                    if (this.taskHasClosedState(task)) { //Skip closed tasks
                        continue;
                    } else if (!firstTask.number || task.number < firstTask.number) { //Check if the number is lower than the one in memory
                        firstTask = task //Replace task in memory with lower numbered task
                    }
                }
                if (Object.keys(firstTask).length) { //If there is an object with properties to return
                    return firstTask //Return the found task object
                }
            }
            for (let task of taskArray) { //This loop is onlye reached if preferOpen is false, or the previous loop did not yeild an Object
                if (!firstTask.number || task.number < firstTask.number) {
                    firstTask = task
                }
            }
            return firstTask //Return the task object
        },

        goToBroadBandSupplierPortal(inst){
            let link = `https://broadbandsupplierportal.energifyn.dk/Installation/SearchInstallation?Query=${inst}&IncludeClosed=false&ShowAll=false`
            EventBus.$emit('link-to-external-url',link)
        },
        
        nestedEquality(obj1, obj2) {
            if (typeof obj1 != typeof {} || typeof obj2 != typeof {} || obj1 == null || obj2 == null) { //At least one of the compared values is not an object/array
                return obj1 == obj2 //Simple equality for non-objects
            }
            let keys1
            let keys2
            try {
                keys1 = Object.keys(obj1)
                keys2 = Object.keys(obj2)
            }
            catch(error) {
                console.log('value1: '+JSON.stringify(obj1))
                console.log('value2: '+JSON.stringify(obj2))
                console.error('could not convert compared values to objects', error)
                return false
            }
            if (keys1.length != keys2.length){
                return false
            }
            for (let i in keys1) { //Loop through keys, looking for inequalities, ignoring equalities to ensure loop either finds inequality or finishes.
                let key = keys1[i]
                if (typeof obj1[key] != typeof {} || typeof obj2[key] != typeof {}) { //At least one of the compared properties is not an object/array
                    if (obj1[key] != obj2[key]){
                        console.log(`${obj1[key]} != ${obj2[key]}`)
                        return false //Simple inequality for non-objects
                    }
                } else {
                    if (!this.nestedEquality(obj1[key],obj2[key])) { //Recursive function call for nested objects and arrays
                        console.log(`${JSON.stringify(obj1[key])} != ${JSON.stringify(obj2[key])}`)
                        return false 
                    }
                }
            } //If loop completes, no inequalities were found
            return true
        },

        camelCaseToSentence(camelCase) {
            if (typeof camelCase != 'string') {
                console.error(`camelCaseToSentence() expects a string as input, but got type '${typeof camelCase}'`)
                return camelCase
            }
            let string = camelCase.replace(/([A-Z])/g, " $1").toLowerCase().trim() //Add space before uppercase letters
            let capitalizedString = string.substring(0,1).toUpperCase() + string.substring(1) //Capitalize first character
            return capitalizedString
        },

        // getOnHoldTask(tasklist, first = true) { //Deprecated - causes infinite loop
        //     if (!tasklist || !tasklist.length) return null
        //     tasklist = tasklist.sort((a,b) => a.number > b.number ? (first ? 1 : -1) : (first ? -1 : 1))
        //     for (let task of tasklist) {
        //         // console.log(task.number.substring(0,3))
        //         let onHoldState = task.number.substring(0,3) == 'TRT' ? TicketState.ON_HOLD : TaskState.ON_HOLD
        //         if (task.state.value == onHoldState) return task
        //     }
        //     return null //There are no on hold tasks in array
        // },

        /**
         * Filter array of tasks, to return tasks with state on hold, optionally sorted
         * @param {Array} tasklist array of tasks to filter
         * @param {*} sorted 'asc', 'desc', or falsy value (eg null) if sorting not required
         * @returns {Array} of the tasks from the original array that have state on hold
         */
        getOnHoldTasks(tasklist, sorted) {
            if (!tasklist || !tasklist.length) return []
            let filteredArr = tasklist.filter((task) => {
                let onHoldState = task.number.substring(0,3) == 'TRT' ? TicketState.ON_HOLD : TaskState.ON_HOLD
                return (task.state.value == onHoldState)
            })
            if (!filteredArr.length) return []
            if (sorted) {
                return filteredArr.sort((a,b) => a.number > b.number ? (sorted == 'asc' ? 1 : -1) : (sorted == 'asc' ? -1 : 1))
            }
            return filteredArr
        },

        appointmentBelongsToUser(appointmentObj, useremail) {
            if (!appointmentObj || !useremail) return false
            let userInitials = this.getInitialsFromEmail(useremail)
            if (appointmentObj.Worker?.Email?.toLowerCase() == useremail.toLowerCase()) return true
            if (appointmentObj.WorkerInitials?.toUpperCase() == userInitials.toUpperCase()) return true
            return false
        },

        async sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms))
        },

        /**
         * Makes a clone of an object or array, to prevent unwanted side-effects; could probably be optimized using the lodash function cloneDeep()
         * @param {Object || Array} obj //Object or array to be cloned
         * @returns {Object || Array} //Object or array that is guaranteed to not be tied to the original in any way
         */
        cloneJson(obj) {
            if (!obj) return null //Avoid error "undefined is not valid JSON"
            return JSON.parse(JSON.stringify(obj)) //convert obj to string and back to obj
        },

        /**
         * Takes a notes value (String) as provided from pilotBI, and splits it into author and body, based on the '@user:' formatting.
         * Function can be expanded with more properties, eg mentions
         * @param {String} noteValue Note as retrieved from pilotBI
         * @returns {Object} Object containing body (with unnecessary properties removed), and properties such as author
         */
        parseNote(noteValue) {
            if (!noteValue) {
                return {
                    author: null,
                    origin: null,
                    body:  null,
                }
            }
            let authorMatch = noteValue.match(/@[a-z æøå]*:/i)?.[0] //A '@' followed by a number of letters (case insensitive) and spaces, ending in a ':' (Does not support special or accented characters like üöäé)
            let originMatch = noteValue.match(/comment from [a-zæøå\d() .,-]*:\n/i)?.[0] //The words 'Comment from' followed by some string, ending in a ':' and a newline
            // console.log(authorMatch)
            let body = noteValue
            let author = null
            let origin = null
            if (authorMatch){
                body = body.replace(authorMatch, '').trim() //Remove author from body, and trim to avoid starting with \n
                author = authorMatch?.replaceAll(/[@:]*/g, '') //Remove @ and : from authorMatch, to be returned as name
            }
            if (originMatch){
                body = body.replace(originMatch, '').trim() //Remove origin from body, and trim to avoid starting with \n
                origin = originMatch
            }
            return {author, origin, body}
        },
    }
}
