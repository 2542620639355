<template>
    <div>
        <sui-modal
            id="viewServiceOrderModal"
            :open="isOpen"
            v-on:clickAwayModal="clickAway()"
        >
            <sui-modal-header>
                {{modalTitle}}
            </sui-modal-header>
            <sui-modal-content scrolling v-if="serviceOrder">
                <sui-table striped :columns="3" color="red">
                    <sui-table-header>
                        <sui-table-row>
                            <sui-table-headerCell colspan="3">
                                Generelt
                            </sui-table-headerCell>
                        </sui-table-row>
                    </sui-table-header>
                    <sui-table-body>
                        <sui-table-row>
                            <sui-table-cell colspan="2">SonWin ID</sui-table-cell>
                            <sui-table-cell conspan="1">{{serviceOrder.sonWinId}}</sui-table-cell>
                        </sui-table-row>
                        <sui-table-row>
                            <sui-table-cell colspan="2">Oprettet</sui-table-cell>
                            <sui-table-cell colspan="1">{{toUserFriendlyTimestamp(serviceOrder.createdAt, true, true)}}</sui-table-cell>
                        </sui-table-row>
                        <sui-table-row>
                            <sui-table-cell colspan="2">Service Provider</sui-table-cell>
                            <sui-table-cell colspan="1">{{serviceOrder.serviceProvider.name}}</sui-table-cell>
                        </sui-table-row>
                        <sui-table-row>
                            <sui-table-cell colspan="2">Status</sui-table-cell>
                            <sui-table-cell colspan="1">{{serviceOrder.state.label}}</sui-table-cell>
                        </sui-table-row>
                    </sui-table-body>
                </sui-table>

                <sui-table striped :columns="3" color="orange">
                    <sui-table-header>
                        <sui-table-row>
                            <sui-table-headerCell colspan="2">
                                Projekt
                            </sui-table-headerCell>
                            <sui-table-headerCell colspan="1">
                                {{ serviceOrder.project.number }}
                            </sui-table-headerCell>
                        </sui-table-row>
                    </sui-table-header>
                    <sui-table-body>
                        <sui-table-row>
                            <sui-table-cell colspan="2">Kort beskrivelse</sui-table-cell>
                            <sui-table-cell colspan="1">{{expandDescription(serviceOrder.project.shortDescription)}}</sui-table-cell>
                        </sui-table-row>
                        <sui-table-row>
                            <sui-table-cell colspan="2">Start Dato</sui-table-cell>
                            <sui-table-cell colspan="1">{{ toUserFriendlyTimestamp(serviceOrder.project.startDate, true, true) }}</sui-table-cell>
                        </sui-table-row>
                        <sui-table-row>
                            <sui-table-cell colspan="2">Slut Dato</sui-table-cell>
                            <sui-table-cell colspan="1">{{ toUserFriendlyTimestamp(serviceOrder.project.endDate, true, true) }}</sui-table-cell>
                        </sui-table-row>
                    </sui-table-body>
                </sui-table>

                <sui-table striped :columns="3" color="yellow">
                    <sui-table-header>
                        <sui-table-headerCell colspan="3">
                            Kontakt
                        </sui-table-headerCell>
                    </sui-table-header>
                    <sui-table-body>
                        <sui-table-row>
                            <sui-table-cell colspan="2">Navn</sui-table-cell>
                            <sui-table-cell colspan="1">{{ serviceOrder.contacts[0].name }}</sui-table-cell>
                        </sui-table-row>
                        <sui-table-row>
                            <sui-table-cell colspan="2">E-mail</sui-table-cell>
                            <sui-table-cell colspan="1">{{ serviceOrder.contacts[0].email }}</sui-table-cell>
                        </sui-table-row>
                        <sui-table-row>
                            <sui-table-cell colspan="2">Mobil</sui-table-cell>
                            <sui-table-cell colspan="1">{{ serviceOrder.contacts[0].mobile }}</sui-table-cell>
                        </sui-table-row>
                        <sui-table-row>
                            <sui-table-cell colspan="2">Telefon</sui-table-cell>
                            <sui-table-cell colspan="1">{{ serviceOrder.contacts[0].phone }}</sui-table-cell>
                        </sui-table-row>
                        <sui-table-row>
                            <sui-table-cell colspan="2">Rolle</sui-table-cell>
                            <sui-table-cell colspan="1">{{ serviceOrder.contacts[0].role }}</sui-table-cell>
                        </sui-table-row>
                    </sui-table-body>
                </sui-table>

                <sui-table striped :columns="3" color="green" v-if="serviceOrderTasksToUs.length">
                    <sui-table-header>
                        <sui-table-row>
                            <sui-table-headerCell colspan="3" v-if="serviceOrderTasksToUs.length">
                                Opgaver tildelt FiberLAN
                            </sui-table-headerCell>
                            <sui-table-headerCell colspan="3" v-if="!serviceOrderTasksToUs.length">
                                Der er ingen opgaver tildelt til FiberLAN på denne Service Ordre
                            </sui-table-headerCell>
                        </sui-table-row>
                    </sui-table-header>
                    <sui-table-body>
                        <sui-table-row 
                            v-for="(task, index) in serviceOrderTasksToUs" 
                            :key="index"
                        >
                            <sui-table-cell colspan="2">
                                {{ task.shortDescription }} <br>
                                <sui-item-meta style="font-size: small"> {{ task.number }} </sui-item-meta>
                            </sui-table-cell>
                            <sui-table-cell colspan="1">
                                <sui-button
                                    size="mini"
                                    title="Vis task info"
                                    @click="showTaskModal(task)"
                                    style="margin-right: 5px;"
                                ><i class="fa-solid fa-info"></i></sui-button> 
                                <sui-button size="mini" :color="getStateColor(task.state)" @click.stop="taskStatusClicked()" >{{ task.state.label }}</sui-button>
                            </sui-table-cell>
                        </sui-table-row>
                    </sui-table-body>
                </sui-table>

                <sui-table striped :columns="3" color="blue">
                    <sui-table-header>
                        <sui-table-row>
                            <sui-table-headerCell colspan="3" v-if="serviceOrderTasks.length">
                                Øvrige Opgaver
                            </sui-table-headerCell>
                            <sui-table-headerCell colspan="3" v-if="!serviceOrderTasks.length && !serviceOrderTasksToUs.length">
                                Der er ingen opgaver tilknyttet denne Service Ordre
                            </sui-table-headerCell>
                            <sui-table-headerCell colspan="3" v-if="!serviceOrderTasks.length">
                                Der er ingen øvrige opgaver tilknyttet denne Service Ordre
                            </sui-table-headerCell>
                        </sui-table-row>
                    </sui-table-header>
                    <sui-table-body>
                        <sui-table-row 
                            v-for="(task, index) in serviceOrderTasks" 
                            :key="index"
                        >
                            <sui-table-cell colspan="2">
                                {{ task.shortDescription }} <br>
                                <sui-item-meta style="font-size: small"> {{ task.number }} </sui-item-meta>
                            </sui-table-cell>
                            <sui-table-cell colspan="1">
                                <sui-button
                                    size="mini"
                                    title="Vis task info"
                                    @click="showTaskModal(task)"
                                    style="margin-right: 5px;"
                                ><i class="fa-solid fa-info"></i></sui-button> 
                                {{ task.state.label }}
                            </sui-table-cell>
                        </sui-table-row>
                    </sui-table-body>
                </sui-table>

                <sui-table striped color="purple" v-if="lastTask">
                    <sui-table-header>
                        <sui-table-row>
                            <sui-table-headerCell v-if="lastTask.notes.length"> 
                                Noter 
                            </sui-table-headerCell>
                            <sui-table-headerCell v-if="!lastTask.notes.length"> 
                                Der er tilsyneladende ingen noter tilknyttet denne opgave 
                            </sui-table-headerCell>
                        </sui-table-row>
                    </sui-table-header>
                    <sui-table-body>
                        <sui-table-row 
                            v-for="note in lastTask.notes"
                            :key="note.id"
                        >
                            <sui-table-cell>
                                <p>
                                    <strong><i v-if="note.createdBy.includes('@fiberlan.dk')" class="fa-solid fa-eye-slash" title="Intern note, kan ikke ses af andre end FiberLAN"/> {{ note.createdBy }}<span v-if="parseNote(note.value).author"> ({{ parseNote(note.value).author }})</span></strong> - 
                                    <span>{{ toUserFriendlyTimestamp(note.createdAt) }}</span>
                                    <span v-if="parseNote(note.value).origin"><br><small>{{ parseNote(note.value).origin }}</small></span>
                                </p>
                                <p class="note-body">{{ parseNote(note.value).body }}</p>
                            </sui-table-cell>
                        </sui-table-row>
                    </sui-table-body>
                </sui-table>
            </sui-modal-content>
            <sui-modal-actions>
                <sui-button @click="clickAway()">Luk</sui-button>
            </sui-modal-actions>
        </sui-modal>
        
        <!-- TODO: Find remaining data to make this modal work properly -->
        <!-- projectProp: Object -->
        <!-- <state-change-modal v-if="serviceOrder && lastTask"
            :isOpen="stateModalOpen" 
            :projectProp="project"
            :serviceOrder="serviceOrder" 
            :task="activeTask" 
            :installationLabel="serviceOrder.installation.number" 
            :technicalData="lastTask.configurationItem.technicalData"
        /> -->

        <show-task-data-modal 
            :isOpen="showTaskDataModal"
            :task="taskDataModalTask"
        />
    </div>
</template>

<script>
import { Mixin } from '../../lib/Mixins/mixin.js'
import { DateMixin } from '../../lib/Mixins/dateMixin'
import { DataAPI } from '../../lib/DataAPI.js'
import EventBus from '../../EventBus.js'
// import StateChangeModal from './StateChangeModal.vue'
import TaskType from '../../lib/Enums/TaskType'
import { db } from '../../firebase'
import swal from 'sweetalert'
import ShowTaskDataModal from '@/components/Project/ShowTaskDataModal.vue'

export default {
    mixins: [Mixin, DateMixin, DataAPI],

    enums: {
        TaskType,
    },

    components: {
        ShowTaskDataModal,
    },

    props: {
        isOpen: Boolean,
        providedServiceOrder: Object,
    },

    data(){
        return {
            serviceOrder: null,
            serviceOrderTasks: [],
            serviceOrderTasksToUs: [],
            openServiceOrderModal: false,
            modalTitle: "Henter Service Order...",
            project: {},
            lastTask: null,

            stateModalOpen: false,
            activeTask: null,
            firebaseUser: {},

            showTaskDataModal: false,
            taskDataModalTask: {},
        }
    },

    computed: {
        user() {
            return this.$parent.$parent.$parent.user
        },
    },

    methods: {
        closeTaskDataModal(){
            this.showTaskDataModal = false
            this.taskDataModalTask = {}
        },

        showTaskModal(task){
            this.taskDataModalTask = task
            this.showTaskDataModal = true
        },

        clickAway(){
            EventBus.$emit('view-service-order-modal-closing')
            this.clearForm()
        },

        clearForm(){
            this.serviceOrder = null
            this.serviceOrderTasks = []
            this.serviceOrderTasksToUs = []
            this.modalTitle = "Henter Service Order..."
            this.lastTask = null
        },

        async getServiceOrder() {
            console.log("Provided service order",this.providedServiceOrder)
            this.firebaseUser
            if (this.providedServiceOrder){
                EventBus.$emit('function-activity', {functionName: 'NexelServiceOrder_getServiceOrder', isActive: true})
                const resp = await this.dataGetServiceOrder(this.providedServiceOrder.id)
                this.serviceOrder = resp
                this.modalTitle = `Service Order ${this.serviceOrder.number}`
                console.log("Service order",this.serviceOrder)
                for (let i in resp.project.tasks){
                    if (this.providedServiceOrder.project.tasks.some(task => task.id === resp.project.tasks[i].id)){
                        this.serviceOrderTasksToUs.push(resp.project.tasks[i])
                    } else {
                        this.serviceOrderTasks.push(resp.project.tasks[i])
                    }
                }
                this.serviceOrderTasks = this.serviceOrderTasks.sort((a, b) => (a.number > b.number) ? 1 : -1)

                EventBus.$emit('function-activity', {functionName: 'NexelServiceOrder_getServiceOrder', isActive: false})
            }
        },

        // Find a different way of finding the project
        async getProject(){
            EventBus.$emit('function-activity', {functionName: 'NexelServiceOrder_getProject', isActive: true})
            if (this.lastTask) {
                let project = await this.dataGetProjectByAreaCode(this.lastTask.configurationItem.area.sonWinProjectId)
                this.project = project
            }
            EventBus.$emit('function-activity', {functionName: 'NexelServiceOrder_getProject', isActive: false})
        },

        async getLastTask() {
            EventBus.$emit('function-activity', {functionName: 'NexelServiceOrder_getLastTask', isActive: true})
            if (this.serviceOrderTasksToUs.length) {
                this.serviceOrderTasksToUs = this.serviceOrderTasksToUs.sort((a, b) => (a.number > b.number) ? 1 : -1)
                let task = this.serviceOrderTasksToUs[this.serviceOrderTasksToUs.length - 1]
                let fullTask = await this.dataGetProjectTask(task)

                this.lastTask = fullTask
            }
            EventBus.$emit('function-activity', {functionName: 'NexelServiceOrder_getLastTask', isActive: false})
        },

        expandDescription(desc){
            if (desc != null) {
                let tmpStr = desc.split(' ')[0]
                if (tmpStr == "HC") return desc.replace("HC","House Connected")
                return desc.replace("HP","House Passed")
            }
        },

        taskStatusClicked(){
            swal('Kommende feature!', 'Det er på nuværende tidspunkt ikke muligt at ændre status på en opgave herfra.\n Der arbejdes på sagen.', 'info')
        },
    },

    beforeMount() {
        EventBus.$on('show-task-data-modal-close', () => {this.closeTaskDataModal()})
    },

    mounted() {
        EventBus.$on('stateChange-modal-closing', () => {this.stateModalOpen = false})
    },

    beforeDestroy() {
        EventBus.$off('stateChange-modal-closing')
    },

    watch: {
        providedServiceOrder: {
            immediate: true,
            async handler() {
                if (this.isOpen){
                    await this.getServiceOrder()
                    await this.getLastTask()
                    this.getProject()
                }
                // await this.getServiceOrder()
                // await this.getLastTask()
                // this.getProject()
            }
        },
        user: {
            immediate: true,
            handler(user) {
                if (user){
                    this.$bind('firebaseUser', db.collection(`Users`).doc(user.email))
                }
            }
        },
        
    }
}
</script>

<style scoped>
    table.selectable tr:hover td {
        cursor: pointer;
    }
</style>