<template>
    <aside class="main-sidebar sidebar-dark-primary elevation-4" :class="this.specialOccasion">

        <!-- Brand Logo -->
        <!--<a href="index3.html" class="brand-link">
            <img src="dist/img/AdminLTELogo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3"
                style="opacity: .8">
            <span class="brand-text font-weight-light">AdminLTE 3</span>
        </a>-->

        <div class="project-selector-container" v-if="userProfile && userProfile.Roles && userProfile.Roles.includes(UserRoles.USER)">
            <sui-dropdown
                placeholder="Vælg Projekt"
                selection
                search
                :options="projectsDropdownList"
                v-model="selectedProject"
                @input="onSelectProject"
            />
        </div>

        <div class="sidebar">
            <div class="user-panel mt-3 pb-3 mb-3 d-flex">
                <UserProfilePhoto
                    class="profile-image"
                    v-if="userProfile.Photo"
                    :base64ImgSrc="userProfile.Photo"
                    :alternateText="userProfile.Name"
                />
                <div v-else-if="user.displayName" class="profile-image img-circle elevation-2" :style="generateStyleColorsFromName(user.displayName)">
                    {{ user.displayName.substring(0, 1) }}
                </div>
                <div class="info">
                    <a href="#" class="d-block">{{ user.displayName }}</a>
                </div>
            </div>

            <nav class="mt-2" v-if="showTopMenuContent">
                <ul class="nav nav-pills nav-sidebar flex-column">
                    <li class="nav-item" v-if="userProfile && userProfile.Roles && userProfile.Roles.includes(UserRoles.USER)">
                        <router-link
                            to="/projects"
                            active-class="active"
                            class="nav-link"
                        >
                            Projekter
                        </router-link>
                    </li>
                    <li class="nav-item" v-if="userProfile && userProfile.Roles && userProfile.Roles.includes(UserRoles.USER)">
                        <router-link
                            to="/hubs"
                            active-class="active"
                            class="nav-link"
                        >
                            Accesshuse
                        </router-link>
                    </li>
                    <li class="nav-item" v-if="userProfile && userProfile.Roles && userProfile.Roles.includes(UserRoles.USER)">
                        <router-link
                            to="/installationCheck"
                            active-class="active"
                            class="nav-link"
                        >
                            Installationsopslag
                        </router-link>
                    </li>
                    <li class="nav-item" v-if="userProfile && userProfile.Roles && userProfile.Roles.includes(UserRoles.USER)">
                        <router-link
                        to="/files"
                        active-class="active"
                        class="nav-link"
                        >
                            Filer
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link
                        to="/hourcheck"
                        active-class="active"
                        class="nav-link"
                        >
                        Løntimer
                        </router-link>
                    </li>

                    <!-- Grouped -->
                    <li class="nav-item dropdown" v-if="userProfile && userProfile.Roles && userProfile.Roles.includes(UserRoles.USER)">
                        <a class="nav-link" data-toggle="dropdown" @click.stop="toggleMiscMenu">
                            Diverse
                            <i class="fa-solid fa-angles-down" style="float: right; margin-top: 3px;" v-if="!isMiscMenuOpen"></i>
                            <i class="fa-solid fa-angles-up" style="float: right; margin-top: 3px;" v-if="isMiscMenuOpen"></i>
                            
                        </a>
                    </li>
                    <li class="nav-item sub-menu" v-if="isMiscMenuOpen">
                        <router-link
                            to="/signaturforklaring"
                            active-class="active"
                            class="nav-link"
                        >
                            Signaturforklaring
                        </router-link>
                    </li>
                    <li class="nav-item sub-menu" v-if="isMiscMenuOpen">
                        <router-link
                            to="/sygdom"
                            active-class="active"
                            class="nav-link"
                        >
                            Sygdoms-statistik
                        </router-link>
                    </li>
                    <li class="nav-item sub-menu" v-if="isMiscMenuOpen">
                        <router-link
                            to="/pdfgenerator"
                            active-class="active"
                            class="nav-link"
                        >
                            PDF Generator
                        </router-link>
                    </li>
                    
                    <li class="nav-item sub-menu" v-if="isMiscMenuOpen">
                        <a class="nav-link" href="https://app.virkplan.com/home/dashboard">
                            Pilot-BI
                        </a>
                    </li>
                    <li class="nav-item sub-menu" v-if="isMiscMenuOpen">
                        <router-link
                            to="/addresssearch"
                            active-class="active"
                            class="nav-link"
                        >
                            DAR Søgning
                        </router-link>
                    </li>
                    <li class="nav-item sub-menu" v-if="isMiscMenuOpen">
                        <router-link
                            to="/logs"
                            active-class="active"
                            class="nav-link"
                        >
                            Log Filer
                        </router-link>
                    </li>

                </ul>
                <hr />
            </nav>

            <nav class="mt-2" v-if="userProfile && userProfile.Roles && userProfile.Roles.includes(UserRoles.USER)">
                <div class="project-related-links" v-if="selectedProject && activeProject.Type != 'TroubleTickets'">
                    <ul class="nav nav-pills nav-sidebar flex-column">
                        <li class="nav-item">
                            <router-link
                                :to="`/projects/${this.activeProject.id}`"
                                exact-active-class="active"
                                class="nav-link"
                            >
                                <i class="nav-icon fas fa-th-large"></i>
                                <p>
                                    Projekt Detaljer
                                </p>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link
                                :to="`/projects/${this.activeProject.id}/booking`"
                                exact-active-class="active"
                                class="nav-link"
                            >
                                <i class="nav-icon fas fa-object-group"></i>
                                <p>
                                    Booking Dashboard
                                </p>
                            </router-link>
                        </li>
                        <!-- <li class="nav-item">
                            <router-link
                                :to="`/projects/${this.activeProject.id}/timeline`"
                                active-class="active"
                                class="nav-link"
                            >
                                <i class="nav-icon far fa-calendar-alt"></i>
                                <p>
                                    Tidsplan
                                </p>
                            </router-link>
                        </li> -->
                        <li class="nav-item" v-if="this.activeProject && this.activeProject.Type && this.activeProject.Type.includes(String(ProjectType.CABINET))">
                            <router-link
                                :to="`/projects/${this.activeProject.id}/cabinet-dashboard`"
                                active-class="active"
                                class="nav-link"
                            >
                                <i class="nav-icon fas fa-door-closed"></i>
                                <p>
                                    Skabs-oversigt
                                </p>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link
                                :to="`/projects/${this.activeProject.id}/unitWork`"
                                active-class="active"
                                class="nav-link"
                            >
                                <i class="nav-icon fa-solid fa-file-invoice-dollar"></i>
                                <p>
                                    Udført arbejde
                                </p>
                            </router-link>
                        </li>
                        <li class="nav-item" v-if="this.activeProject.Integrations && this.activeProject.Integrations.formToPDF.enabled">
                            <router-link
                                :to="`/pdfgenerator/?project_id=${this.activeProject.id}`"
                                active-class="active"
                                class="nav-link"
                            >
                                <i class="nav-icon fa-solid fa-file-pdf"></i>
                                <p>
                                    {{ this.activeProject.Integrations.formToPDF.name }}
                                </p>
                            </router-link>
                        </li>
                        <!-- <li class="nav-item">
                            <router-link
                                :to="`/projects/${this.activeProject.id}/blowing-cabinets`"
                                active-class="active"
                                class="nav-link"
                            >
                                <i class="nav-icon fas fa-wind"></i>
                                <p>
                                    Blæsning
                                </p>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link
                                :to="`/projects/${this.activeProject.id}/splicing-cabinets`"
                                active-class="active"
                                class="nav-link"
                            >
                                <i class="nav-icon fas fa-compress-alt rotate-45"></i>
                                <p>
                                    Splidsning
                                </p>
                            </router-link>
                        </li> -->
                        <!-- <li class="nav-item">
                            <router-link
                                :to="`/projects/${this.activeProject.id}/import`"
                                active-class="active"
                                class="nav-link"
                            >
                                <i class="nav-icon fas fa-file-import"></i>
                                <p>
                                    Import
                                </p>
                            </router-link>
                        </li> -->
                    </ul>
                </div>
                <div class="project-related-links" v-else-if="selectedProject">
                    <ul class="nav nav-pills nav-sidebar flex-column">
                        <li class="nav-item">
                            <router-link
                                :to="`/projects/${this.activeProject.id}`"
                                exact-active-class="active"
                                class="nav-link"
                            >
                                <i class="nav-icon fas fa-th-large"></i>
                                <p>
                                    Projekt Detaljer
                                </p>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link
                                :to="`/projects/${this.activeProject.id}/booking`"
                                exact-active-class="active"
                                class="nav-link"
                            >
                                <i class="nav-icon fas fa-object-group"></i>
                                <p>
                                    Booking Dashboard
                                </p>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link
                                :to="`/projects/${this.activeProject.id}/unitWork`"
                                active-class="active"
                                class="nav-link"
                            >
                                <i class="nav-icon fa-solid fa-file-invoice-dollar"></i>
                                <p>
                                    Udført arbejde
                                </p>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </nav>
        <!-- /.sidebar-menu -->
        </div>

    </aside>
</template>
<script>
import { mapGetters } from 'vuex'
import { db } from '../../firebase'
import { Mixin } from '../../lib/Mixins/mixin'
import UserRoles  from '../../lib/Enums/UserRoles'
import ProjectType from '../../lib/Enums/ProjectType'
import EventBus from '../../EventBus'
import UserProfilePhoto from '../Worker/UserProfilePhoto.vue'
import { specialOccasionsMixin } from '../../lib/Mixins/specialOccasionsMixin.js'

export default {
    name: 'LeftMenu',
    mixins: [Mixin, specialOccasionsMixin],
    enums: {
        ProjectType,
        UserRoles
    },
    props: ['media'],
    components: { UserProfilePhoto },
    data() {
        return {
            projects: [],
            isMiscMenuOpen: false,
        };
    },
    computed: {
        ...mapGetters({
            user: 'authProviderUser',
            activeProject: 'activeProject',
            userProfile: 'userProfile',
        }),
        projectsDropdownList() {
            return this.projects.map((project) => {
                return { value: project.id, text: project.Name };
            });
        },
        showTopMenuContent() {
            if (this.media.type != 'Desktop')
                return true;
            return false;
        },
        selectedProject: {
            get() {
                return this.$store.state.activeProjectId;
            },
            set(id) {
                this.$store.dispatch('setActiveProjectId', id);
                this.$store.dispatch('setActiveProject', this.projects.find(p => p.id === id));
            }
        },
    },
    firestore: {
        projects: db.collection('Projects').where('Status', '==', 1).orderBy('Name', 'asc')
    },
    mounted() {
        EventBus.$on('sidebar-clear-submenu-expanders', () => { this.isMiscMenuOpen = false; });
    },
    methods: {
        toggleMiscMenu() {
            this.isMiscMenuOpen = !this.isMiscMenuOpen;
        },
        async onSelectProject() {
            EventBus.$emit('function-activity', { functionName: 'LeftMenu_onSelectProject', isActive: true });
            let page = window.localStorage.getItem('last-visited-page');
            if (!page) page = 'booking';

            this.$router.push(`/projects/${this.$store.state.activeProjectId}/${page}`);
            EventBus.$emit('function-activity', { functionName: 'LeftMenu_onSelectProject', isActive: false });
            location.reload(); //Force reload to avoid mixing data between projects
        },
        generateStyleColorsFromName(name) {
            const imageData = this.generateUserProfileImageFromName(name);
            return {
                backgroundColor: imageData.bgColor,
                color: imageData.fgColor
            };
        },
    },
}
</script>
<style scoped>
.project-selector-container {
    border-bottom: 1px solid #4b545c;
    padding: 0.643rem .5rem;
    text-align: center;
    height: 57px;
}

.rotate-45 {
    transform: rotate(45deg);
}
</style>
<style>
/* NON-SCOPED STYLES */
.project-selector-container .dropdown,
.project-selector-container .ui.dropdown .menu>.item {
    font-size: 13px;
}


.project-selector-container .ui.selection.dropdown:hover,
.project-selector-container .ui.selection.active.dropdown {
    border-color: rgba(0,0,0,0);
    box-shadow: 0 0 5px 1px rgb(34 44 49 / 80%) !important;
}

.project-selector-container .ui.selection.active.dropdown .menu {
    box-shadow: 0 2px 3px 0 rgb(34 44 49 / 80%);
}

.project-selector-container .ui.selection.search.dropdown {
    background: #474f56;
    color: #89929a;
    min-width: 16em;
    box-shadow: 0 2px 3px 1px #30373e;
}

.project-selector-container .ui.selection.active.dropdown {
    border-color: #656e77;
}

.project-selector-container .ui.selection.active.dropdown .menu {
    background: #525b63;
    border-color: #656e77;
}

.project-selector-container .ui.selection.dropdown .menu>.item {
    border-top-color: rgba(0,0,0,0);
    color: #b2bcc5;
}

.project-selector-container .ui.selection.dropdown .menu>.item:hover,
.project-selector-container .ui.selection.dropdown .menu>.item.active {
    color: #fff;
    background: rgba(0,0,0,.05);
}

.project-selector-container .ui.dropdown .menu .selected.item {
    background: inherit;
    color: inherit;
}

.project-selector-container .ui.search.dropdown>input.search,
.project-selector-container .ui.selection.visible.dropdown>.text:not(.default) {
    color: #89929a;
    cursor: pointer;
}


/* COLLAPSED STATE */
.sidebar-mini.sidebar-collapse .main-sidebar .project-selector-container .ui.search.dropdown>input.search,
.sidebar-mini.sidebar-collapse .main-sidebar .project-selector-container .ui.search.dropdown>.text {
    display: none;
}

.sidebar-mini.sidebar-collapse .main-sidebar .project-selector-container .ui.selection.search.dropdown {
    width: 50px;
    min-width: 50px;
    text-align: center;
}

.sidebar-mini.sidebar-collapse .main-sidebar .project-selector-container .ui.selection.dropdown>.dropdown.icon {
    right: auto;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    width: 100%;
    font-size: 16px;
    margin-top: -19px;
}


/* COLLAPSED HOVER STATE */
.sidebar-mini.sidebar-collapse .main-sidebar:hover .project-selector-container .ui.search.dropdown>input.search,
.sidebar-mini.sidebar-collapse .main-sidebar:hover .project-selector-container .ui.search.dropdown>.text {
    display: inherit;
}

.sidebar-mini.sidebar-collapse .main-sidebar:hover .project-selector-container .ui.selection.search.dropdown {
    width: auto;
    min-width: 16em;
    text-align: left;
}

.sidebar-mini.sidebar-collapse .main-sidebar:hover .project-selector-container .ui.selection.dropdown>.dropdown.icon {
    right: 1em;
    margin: -.78571429em;
    left: auto;
    width: auto;
    font-size: inherit;
    top: 8px;
}

aside.main-sidebar:not(.christmas):before {
    content: '';
    position: absolute;
    bottom: 10px;
    height: 150px;
    opacity: 0.4;
    background-image: url(../../../public/assets/fiberteam-icon-white.svg);
    background-position: bottom center;
    background-size: 140px;
    background-repeat: no-repeat;
}

aside.christmas.main-sidebar:before {
    content: '';
    position: absolute;
    bottom: 10px;
    height: 175px;
    opacity: 0.4;
    background-image: url(../../../public/assets/fiberteam-icon-white-christmas.svg);
    background-position: bottom center;
    background-size: 140px;
    background-repeat: no-repeat;
}

body.sidebar-collapse aside.main-sidebar:before {
    background-size: 50px;
    bottom: 5px;
}

body.sidebar-mini.sidebar-collapse .main-sidebar:hover:before {
    background-size: 140px;
    bottom: 10px;
    width: 100%;
}

aside .profile-image {
    max-width: 40px;
    min-width: 40px;
    width: 40px;
    height: 40px;
    text-align: center;
    padding-top: 8px;
    border-radius: 50%;
    margin-top: 3px;
    margin-left: .6rem;
}

.user-panel .info {
    margin-top: 6px;
}

.sub-menu {
    margin-left: 15px;
}

</style>
