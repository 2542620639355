import Vue from 'vue'
import Vuex from 'vuex'
// import Firebase from 'firebase/compat/app'
import EventBus from './EventBus'
import { auth, authProvider, usersCollection, gAuthProvider } from './firebase'
import { OAuthProvider, linkWithPopup, signInWithPopup, signOut, sendEmailVerification } from 'firebase/auth'
// import { router } from './router'
import axios from 'axios'
import { Buffer } from 'buffer'

Vue.use(Vuex)

function parsePhoneNumber(phoneNumber) {
    if (!phoneNumber) return ''

    let p = String(phoneNumber)
    p = p.replace(/\s+/g, '') //Removes spaces

    if (p.substring(0,2) == '00') {
        p = '+'+p.substring(2) //Replace leading '00' with '+'
    } else if (p.substring(0,1) != '+'){
        if(p.length == 8){ //For 8 digit numbers
            p = '+45'+ p //Adds DK country code if missing
        }
        else if(p.length == 10 && p.substring(0,2) == '45') { //10 digit number starting with 45 (DK country code without the '+')
            p = '+'+p // Append '+' to DK country code
        }
        else {
            throw new Error('Missing country code for non-8-digit number')
        }
    }

    let numberRegex = new RegExp('^[0-9]*$')
    if (!numberRegex.test(p.substr(1))) {
        throw new Error('Phone number must contain only numbers from 0-9 and optionally a leading +')
    }

    // return "+4530559140"
    return p
}

function resizeProfilePhoto(photoBase64, targetWidth, targetHeight) {
    return new Promise((resolve, reject) => {
        let img = new Image();
        img.src = photoBase64;

        img.onload = () => {
            // create a canvas element
            let canvas = document.createElement('canvas');
            let ctx = canvas.getContext('2d');

            // set the canvas dimensions to the target size
            canvas.width = targetWidth;
            canvas.height = targetHeight;

            // draw the image on the canvas
            ctx.drawImage(img, 0, 0, targetWidth, targetHeight);

            // convert the canvas content to base64
            let resizedPhoto = canvas.toDataURL('image/jpeg');

            // resolve the promise with the resized photo
            resolve(resizedPhoto);
        };

        img.onerror = (error) => {
            reject(error);
        };    
    })
}

const store = new Vuex.Store({

    state: {
        userProfile: {},
        authProviderUser: {},
        users: [], // TODO: Check if this is used, probably not
        activeProject: {},
        activeProjectId: null,
        boundSonWinProjects: [], // TODO: Check to see what this is used for
        projectInstallations: [], // TODO: Check if this is used, probably not
        adobeClientId: null,
        useCache: false,
        saveTasksInCache: false,
        useAPiV3: false,
    },

    mutations: {
        setUserProfile(state, value) {
            state.userProfile = value
        },
        setAuthProviderUser(state, value) {
            state.authProviderUser = value
        },
        setUsers(state, value) {
            state.users = value
        },
        setActiveProject(state, value) {
            state.activeProject = value
        },
        setActiveProjectId(state, value) {
            state.activeProjectId = value
        },
        setBoundSonWinProjects(state, value) { 
            state.boundSonWinProjects = value
        },
        setProjectInstallations(state, value) {
            state.projectInstallations = value.map((ins) => {
                if (!Object.prototype.hasOwnProperty.call(ins, 'Adresse')) {
                    ins.Adresse = {}
                }
                return ins
            })
            // state.projectInstallations = value
        },
        setAdobeClientId(state, value) {
            state.adobeClientId = value
        },
        setUseCache(state, value) {
            state.useCache = value
        },
        setSaveTasksInCache(state, value) {
            state.saveTasksInCache = value
        },
        setUseAPiV3(state, value) {
            state.useAPiV3 = value
        },
    },

    actions: {
        async login({ dispatch }) {
            EventBus.$emit('function-activity', {functionName: 'store_login', isActive: true})
            // sign user in
            // Firebase.auth().useDeviceLanguage()

            let user;
            let credential;

            try {
                await signInWithPopup(auth, authProvider)
                    .then((result) => {
                        if (!result) throw new Error('Attempt to sign in with Microsoft failed! Result was null or undefined.');
                        user = result.user //Basic user info; name and email
                        credential = OAuthProvider.credentialFromResult(result) //Get credentials for Microsoft Graph API, including the accessToken with all the requested permission scopes
                        user.credentialAccessToken = credential.accessToken //Include the expanded scope accessToken in the user object
                        // console.log(credential)
                    })
            } catch (error) {
                console.error(error)
    
                if (error.code === 'auth/account-exists-with-different-credential') { // User's email already exists.
                    let pendingCred = error.credential; //undefined :-(
                    console.log('User last logged in with a different authentication provider, will attempt to link credential', pendingCred)
                    await dispatch('linkMicrosoftAccount', pendingCred).then((result) => {
                        if (!result) throw new Error('Attempt to link account to Microsoft failed! Result was null or undefined.');
                        user = result.user //Basic user info; name and email
                        credential = OAuthProvider.credentialFromResult(result) //Get credentials for Microsoft Graph API, including the accessToken with all the requested permission scopes
                        user.credentialAccessToken = credential.accessToken //Include the expanded scope accessToken in the user object
                    }).catch((error) => {
                        console.error('Failed linking accounts due to error:', error)
                        EventBus.$emit('login-failure', {'code':'error-linking-credential','name':'AppError'}) //Listener in App.vue alerts user of error
                    })
    
                } else {
                    EventBus.$emit('login-failure', {'code':'error-getting-credential','name':'AppError'}) //Listener in App.vue alerts user of error
                }
    
                EventBus.$emit('function-activity', {functionName: 'store_login', isActive: false})
            }

            if (!user) {
                EventBus.$emit('function-activity', {functionName: 'store_login', isActive: false})
                throw new Error('Failed to sign in! User object was null or undefined.');
            }

            if (!user?.emailVerified) {
                // EventBus.$emit('login-failure', {'code':'email-not-verified','name':'AppError'}) //Listener in App.vue alerts user of error
                // EventBus.$emit('function-activity', {functionName: 'store_login', isActive: false})
                // return
                await sendEmailVerification(auth.currentUser).then(() => {
                    console.log('Email verification sent!')
                }).catch((err) => {
                    console.error('Error sending Email verification!', err)
                })
            }

            EventBus.$emit('event-login-success-start-loading')

            await dispatch('fetchUserProfile', user)
            EventBus.$emit('function-activity', {functionName: 'store_login', isActive: false})
            location.href = '/' //Comment out when testing login flow, otherwise the page will redirect and reload
        },

        async linkMicrosoftAccount() {
            EventBus.$emit('function-activity', {functionName: 'store_linkMicrosoftAccount', isActive: true})
        
            return signInWithPopup(auth, gAuthProvider).then(async (result) => {
                return linkWithPopup(result.user, authProvider).then((result) => {
                    console.log('Google and Microsoft authentication linked!')
                    EventBus.$emit('function-activity', {functionName: 'store_linkMicrosoftAccount', isActive: false})
                    return result
                }).catch(error => {
                    console.error('Failed linking accounts due to error: Could not sign in to Microsoft account:', error)
                    EventBus.$emit('function-activity', {functionName: 'store_linkMicrosoftAccount', isActive: false})
                })
            }).catch(error => {
                console.error('Failed linking accounts due to error: Could not sign in to Google account:', error)
                EventBus.$emit('function-activity', {functionName: 'store_linkMicrosoftAccount', isActive: false})
            })
        },

        async logout({ commit }, meta) {
            EventBus.$emit('function-activity', {functionName: 'store_logout', isActive: true})
            // await Firebase.auth().signOut()
            await signOut(auth)

            commit('setUserProfile', {})
            commit('setAuthProviderUser', {});

            EventBus.$emit('function-activity', {functionName: 'store_logout', isActive: false})

            if (meta && meta.redirect) return location.href = meta.redirect

            return location.href = '/login'
        },

        async fetchUserProfile({ commit }, user) {
            // console.log('Fetching user profile')
            EventBus.$emit('function-activity', {functionName: 'store_fetchUserProfile', isActive: true})
            // console.log(user)
            let userProfile;

            if (!user) {
                EventBus.$emit('function-activity', {functionName: 'store_fetchUserProfile', isActive: false})
                await signOut(auth) //Log user out
                throw new Error('Cannot fetch user profile without user. User object was null or undefined. User has been signed out.')
            }

            if (!user.metadata) {
                EventBus.$emit('function-activity', {functionName: 'store_fetchUserProfile', isActive: false})
                await signOut(auth) //Log user out
                throw new Error('Cannot fetch user profile without user metadata. User metadata object was null or undefined. User has been signed out.')
            }

            const lastLoginDate = new Date(Number(user.metadata.lastLoginAt))
            let earliestValidLoginDate = new Date()
            let currentDate = earliestValidLoginDate.getDate()
            earliestValidLoginDate.setDate(currentDate-14)
            // console.log(user.metadata?.lastLoginAt, lastLoginDate, earliestValidLoginDate)
            if (lastLoginDate < earliestValidLoginDate) {
                console.error(`Logging user out, due to expired login (Last logged in at ${lastLoginDate.toLocaleDateString()})`)
                await signOut(auth) //Log user out
                location.href = '/login?logOutReason=dit%20login%20er%20udløbet,%20log%20venligst%20ind%20igen.' //Redirect user to login page, with url param logOutReason set to an explanation that the login has expired
                return null
            }

            try {
                // fetch db user profile from firestore
                userProfile = await usersCollection.doc(user.email).get()
            }
            catch (error) {
                // TODO Maybe look at different failures?
                // Insuffient Permissions
                EventBus.$emit('login-failure', JSON.parse(JSON.stringify(error)))
                await signOut(auth) // Very important step! Otherwise the user will be authticated for the app but not for data fetching from firebase!
                EventBus.$emit('function-activity', {functionName: 'store_fetchUserProfile', isActive: false})
                throw error
            }

            if (user && user.credentialAccessToken) {
                // console.log(JSON.stringify(user, null, 4))
                let userdetails, userphoto
                let axiosConfig = {
                    headers: {
                        Authorization: `Bearer ${user.credentialAccessToken}`
                    },
                    // responseType: 'arraybuffer',
                }
                try {
                    await axios.get('https://graph.microsoft.com/v1.0/me', axiosConfig).then(response => {
                        userdetails = response.data
                        if (!userdetails) {
                            throw new Error(`Microsoft Graph response did not contain the expected data:\n${response}`)
                        }
                        // console.log(JSON.stringify(userdetails, null, 4))
                    })

                } catch (error) {
                    console.error(`Failed getting user details with error:\n${JSON.stringify(error, null, 4)}`)
                }
                try { //Try to get the users profile photo from Microsoft
                    axiosConfig.responseType = 'arraybuffer'
                    await axios.get('https://graph.microsoft.com/v1.0/me/photo/$value', axiosConfig).then(async response => {
                        // console.log(response) //This line is reached
                        let contentType = response.headers['content-type']
                        let base64Image = `data:${contentType};base64,${Buffer.from(response.data, 'binary').toString('base64')}`
                        // console.log(base64Image) //This line is not reached
                        // let photoURL = URL.createObjectURL(response.data)
                        // console.log(base64Image)
                        userphoto = base64Image
                    })
                } catch (error) {
                    console.error(`Failed getting user photo with error:\n${JSON.stringify(error, null, 4)}`)
                    throw error
                }

                // console.log(userdetails, userphoto)

                try {
                    let userProfileUpdateDoc = {}
                    userProfileUpdateDoc.LastLogin = new Date(Number(user.metadata.lastLoginAt))
                    userProfileUpdateDoc.LastLogin = userProfileUpdateDoc.LastLogin.toISOString()

                    userProfileUpdateDoc.FirebaseUID = user.uid
                    userProfileUpdateDoc.EmailVerified = user.emailVerified

                    if (userdetails){
                        userProfileUpdateDoc.Name = userdetails.displayName
                        userProfileUpdateDoc.Mobile = parsePhoneNumber(userdetails.mobilePhone) || null
                        userProfileUpdateDoc.Phone = userdetails.businessPhones ? parsePhoneNumber(userdetails.businessPhones[0]) || null : null
                        userProfileUpdateDoc.JobTitle = userdetails.jobTitle || null
                        userProfileUpdateDoc.MicrosoftUserId = userdetails.id || null
                    }

                    if (userphoto){
                        try {
                            let userphotoResized = await resizeProfilePhoto(userphoto, 720, 720)
                            userProfileUpdateDoc.Photo = userphotoResized
                        }
                        catch (error) {
                            console.error(`Failed resizing user photo with the following error:`, error)
                        }
                    }

                    // console.log(userProfileUpdateDoc)

                    for (let key of Object.keys(userProfileUpdateDoc)) { //Remove properties which are null, undefined, or unchanged
                        if (userProfileUpdateDoc[key] == null || userProfileUpdateDoc[key] == userProfile.data()[key]) {
                            delete userProfileUpdateDoc[key]
                        }
                    }

                    // console.log(userProfileUpdateDoc)

                    if (Object.keys(userProfileUpdateDoc).length) {
                        await usersCollection.doc(user.email).update(userProfileUpdateDoc).then(async () => {
                            console.log(`Updated user in database with data:\n${JSON.stringify(userProfileUpdateDoc, null, 4)}`)
                            userProfile = await usersCollection.doc(user.email).get()
                        })
                    }

                } catch (error) {
                    console.error(`Failed updating user data with the following error:`, error)
                }
            }

            // set user profile in state
            commit('setAuthProviderUser', user);
            commit('setUserProfile', userProfile.data())
            EventBus.$emit('function-activity', {functionName: 'store_fetchUserProfile', isActive: false})
        },

        setUsers({ commit }, users) {
            commit('setUsers', users)
        },

        setActiveProject({ commit }, project) {
            commit('setActiveProject', project)
        },
        setActiveProjectId({ commit }, projectId) {
            commit('setActiveProjectId', projectId)
        },
        
        setBoundSonWinProjects({ commit }, projects) {
            commit('setBoundSonWinProjects', projects)
        },
        
        setProjectInstallations({ commit }, installations) {
            commit('setProjectInstallations', installations)
        }
    },

    getters: {
        userProfile: state => {
            return state.userProfile
        },
        authProviderUser: state => {
            return state.authProviderUser
        },
        activeProject: state => {
            return state.activeProject
        },
        activeProjectId: state => {
            return state.activeProjectId
        },
        projectInstallations: state => {
            return state.projectInstallations
        },
        projectInstallationById: state => id => {
            return state.projectInstallations.find(installation => installation.id === id)
        },
        boundSonWinProjects: state => {
            return state.boundSonWinProjects
        },
        adobeClientId: state => {
            return state.adobeClientId
        },
        useCache: state => {
            return state.useCache
        },
        saveTasksInCache: state => {
            return state.saveTasksInCache
        },
        useAPiV3: state => {
            return state.useAPiV3
        },
    }
})

export default store
