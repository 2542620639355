<template>
    <div class="container-fluid">
        <portal to="page-title">
            <div class="row" style="margin-top:-10px">
                <div class="col-sm-1">
                    <sui-button 
                        style="font-size:14px"
                        icon="arrow left" 
                        @click="up" />
                    
                    
                    <sui-button v-if="showSettings"
                    title="Åben Afbudsliste"
                        style="font-size:14px"
                        @click="onBDOpenCancellationListButtonClicked()"
                    >
                        <i class="fa-kit fa-solid-list-clock"></i>
                    </sui-button>

                    <sui-button v-if="showSettings" 
                        style="font-size:14px"
                        class="labeled icon button"
                        icon="cog"
                        @click="onBDEditSettingsButtonClicked()"
                    >
                        Indstillinger
                    </sui-button>
                </div>
                <div class="col-sm-auto" v-if="!moveProjectLead" style="line-height: 70% !important; margin-top:10px; margin-bottom:-11px">
                    <span>
                        {{ this.project.Name }}<br/>
                        <small><small><small><small><em>
                            Projektleder: {{ projectLeads }}
                        </em></small></small></small></small>
                    </span>
                </div>
                <div class="col-sm-auto" v-if="moveProjectLead" style="margin-top:10px;">
                    <div> 
                        <span>
                            {{ this.project.Name }}
                        </span>
                    </div>
                    <div style="margin-top: -17px !important;"> 
                        <span>
                            <small><small><small><small><em>
                                Projektleder: {{ projectLeads }}
                            </em></small></small></small></small>
                        </span>
                    </div>
                </div>
            </div>
        </portal>

        <router-view v-if="!showNotFoundPage"></router-view>

        <div v-if="showNotFoundPage" class="row justify-content-center">
            <div class="col-sm-6 text-center">
                <h2>Projekt ikke fundet</h2>
                <p>
                    Projekt med ID "<span>{{ this.$route.params.projectIdentifier }}</span>" blev desværre ikke fundet i databasen.
                    <br>
                    Hvis du mener dette er en fejl, kontakt venligst IT afdelingen
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { DataAPI } from '../../lib/DataAPI'
import EventBus from '../../EventBus'

export default {
    name: 'ProjectWrapper',
    mixins: [DataAPI],

    data() {
        return {
            showNotFoundPage: false,
            allProjects: [],
        }
    },

    computed: {
        ...mapGetters({
            project: 'activeProject',
            projectID: 'activeProjectId'
        }),

        currentMedia(){
            return this.$root.$children[0].currentMedia
        },

        showSettings(){
            return (
                this.currentMedia.type == 'Mobile' && this.currentMedia.orientation == 'Portrait' &&
                (this.$route.name == 'ProjectBooking' ||
                this.$route.name == 'ProjectCabinetDashboard')
            )
        },

        moveProjectLead(){
            if (this.currentMedia.type == 'Mobile' && this.currentMedia.orientation == 'Portrait') return true
            return false
        },

        projectLeads() {
            if (!this.project || !this.project.Contacts) return null
            let projectLeads = this.project.Contacts.filter((contact) => contact.Roles.includes('ProjectLead'))
            if (!projectLeads.length) return null
            let projectLeadNames = projectLeads.reduce((array, contact) => {
                array.push(contact.Name)
                return array
            }, [])
            return projectLeadNames.join(' & ')
        },
    },

    watch: {
        '$route': 'fetchData',

        project: {
            immediate: true,
            handler() {
                if (this.project && this.project?.BackgroundColor){
                    EventBus.$emit('update-project-background-color',this.project.BackgroundColor)
                } else {
                    EventBus.$emit('update-project-background-color','')
                }
            }
        }
    },

    async created() {
        EventBus.$emit('function-activity', {functionName: 'ProjectWrapper_created', isActive: true})
        await this.fetchData()

        EventBus.$emit('function-activity', {functionName: 'ProjectWrapper_created', isActive: false})
    },


    methods: {

        ...mapMutations([
            'setBoundSonWinProjects'
        ]),

        onBDEditSettingsButtonClicked(){
            EventBus.$emit('edit-settings-modal-open')
        },

        onBDOpenCancellationListButtonClicked(){
            EventBus.$emit('cancellation-list-modal-open')
        },

        async fetchFirebaseProjects() {
            EventBus.$emit('function-activity', {functionName: 'ProjectWrapper_fetchFirebaseProjects', isActive: true})
            /*
                $store.sonWinProjects: {
                    'firebase-id': ['343', '150a'],
                    'firebase-id': ['345', '188b']
                }
            */
           let projects = await this.dataGetAllProjects()
           this.allProjects = projects

           let firebaseBoundSonWinProjects = projects.reduce((obj, item) => {
                return {
                    ...obj,
                    [item.id]: item.SonWinIDs,
                }
            }, {})

            this.setBoundSonWinProjects(firebaseBoundSonWinProjects)
            EventBus.$emit('function-activity', {functionName: 'ProjectWrapper_fetchFirebaseProjects', isActive: false})
        },

        fetchAllProjects() {
            console.log('all projects')

            if (!this.allProjects.length) {
                return
            }

            this.$store.commit('setActiveProject', this.allProjects)
            this.$store.commit('setActiveProjectId', 'all')
        },

        async fetchCurrentProject() {
            EventBus.$emit('function-activity', {functionName: 'ProjectWrapper_fetchCurrentProject', isActive: true})
            try {
                var project = await this.dataGetProjectById(this.$route.params.projectIdentifier)
            }
            catch (err) {
                this.showNotFoundPage = true
                EventBus.$emit('function-activity', {functionName: 'ProjectWrapper_fetchCurrentProject', isActive: false})
                return
            }

            this.showNotFoundPage = false
            this.$store.commit('setActiveProject', project)
            this.$store.commit('setActiveProjectId', project.id)
            EventBus.$emit('function-activity', {functionName: 'ProjectWrapper_fetchCurrentProject', isActive: false})
        },

        async fetchData() {
            EventBus.$emit('function-activity', {functionName: 'ProjectWrapper_fetchData', isActive: true})

            await this.fetchFirebaseProjects()

            if (this.$route.params.projectIdentifier == 'all') {
                this.fetchAllProjects()
            }

            // Fallback when data is not cached to fetch data from Firestore.
            else if (
                !Object.prototype.hasOwnProperty.call(this.project, 'Name')
                || this.$route.params.projectIdentifier != this.project.id
            ) {
                await this.fetchCurrentProject()
            }
            EventBus.$emit('function-activity', {functionName: 'ProjectWrapper_fetchData', isActive: false})
        },
    }
}
</script>
<!-- Must not be scoped! -->
<style>
    .hacked .tabular.menu a.item:nth-child(6)  {
        display: flex;
        margin-left: auto!important;
    }
</style>
