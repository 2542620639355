<template>
    <div class="illnessStatsFitter" :id="`illnessStatsFitter-${fitterIllness ? fitterIllness.fitterSalaryID : 'undefined'}`">
        <IllnessPeriodModal v-if="selectedPeriod" :isOpen="illnessPeriodModalOpen" @close="illnessPeriodModalOpen=false" :illnessPeriod="selectedPeriod" :fitterName="fitterIllness?.fitterName" :fitterInitials="fitterIllness?.fitterSalaryID" :handledFitterIllness="handledFitterIllness" @deepclose="deepClose()" />
        <div class="row">
            <div class="col-sm-4">
                <Bar
                    :chart-id="`chart-illnessHoursByWeekday-${fitterIllness ? fitterIllness.fitterSalaryID : 'undefined'}`" 
                    :chart-data="fitterIllnessByWeekday" 
                    :chart-options="illnessChartOptions"
                />
            </div>
            <div class="col-sm-4">
                <Bar
                    :chart-id="`chart-illnessHoursByWeekday-${fitterIllness ? fitterIllness.fitterSalaryID : 'undefined'}`" 
                    :chart-data="fitterIllnessByMonth" 
                    :chart-options="illnessChartOptions"
                />
            </div>
            <div class="col-sm-4" v-if="fitterIllness">
                <sui-statistics-group horizontal>
                    <sui-statistic in-group>
                        <sui-statistic-value>{{ fitterIllness.totalIllnessHours }}</sui-statistic-value>
                        <sui-statistic-label>Sygetimer</sui-statistic-label>
                    </sui-statistic>
                    <sui-statistic in-group v-if="typeof fitterIllness.illnessHoursPercentage  == 'number'">
                        <sui-statistic-value>{{ fitterIllness.illnessHoursPercentage.toFixed(1) }} %</sui-statistic-value>
                        <sui-statistic-label>Sygetimer</sui-statistic-label>
                    </sui-statistic>
                    <sui-statistic in-group>
                        <sui-statistic-value>{{ fitterIllness.totalIllnessDays }}</sui-statistic-value>
                        <sui-statistic-label>Syge dage</sui-statistic-label>
                    </sui-statistic>
                    <sui-statistic in-group v-if="typeof fitterIllness.illnessDaysPercentage == 'number'" >
                        <sui-statistic-value>{{ fitterIllness.illnessDaysPercentage.toFixed(1) }} %</sui-statistic-value>
                        <sui-statistic-label>Syge dage</sui-statistic-label>
                    </sui-statistic>
                    <sui-statistic in-group v-if="fitterIllness.illnessPeriods && typeof fitterIllness.nonHandledIllnessPeriodsCount == 'number'">
                        <sui-statistic-value>{{ fitterIllness.nonHandledIllnessPeriodsCount }}</sui-statistic-value>
                        <sui-statistic-label>Uhåndtere{{ fitterIllness.nonHandledIllnessPeriodsCount != 1 ? 'de' : 't' }} sygdoms periode{{ fitterIllness.nonHandledIllnessPeriodsCount != 1 ? 'r' : '' }}</sui-statistic-label>
                    </sui-statistic>
                    <sui-statistic in-group v-if="fitterIllness.illnessPeriods && typeof fitterIllness.illnessPeriods.length  == 'number'">
                        <sui-statistic-value>{{ fitterIllness.illnessPeriods.length }}</sui-statistic-value>
                        <sui-statistic-label>Sygdoms periode{{ fitterIllness.illnessPeriods.length != 1 ? 'r' : '' }} i alt</sui-statistic-label>
                    </sui-statistic>
                    <sui-statistic in-group>
                        <sui-statistic-value>{{ fitterIllness.totalHours }}</sui-statistic-value>
                        <sui-statistic-label>Timer totalt</sui-statistic-label>
                    </sui-statistic>
                </sui-statistics-group>
            </div>
        </div>
        <div v-if="fitterIsNotSelf && fitterIllness && fitterIllness.illnessPeriods && fitterIllness.illnessPeriods.length">
            <h2 style="display: inline-block;">Sygdoms-perioder</h2>
            <sui-button positive style="float:right; display: inline" @click="markSelectedPeriodsHandled()">Markér {{ [0,fitterIllness.illnessPeriods.length].includes(selectedPeriods.length) ? 'alle' : 'valgte' }} perioder som håndteret</sui-button>
            <sui-button primary style="float:right; display: inline;" @click="selectPeriods = !selectPeriods">{{ selectPeriods ? 'Fjern vælger' : 'Vælg perioder' }}</sui-button>
        </div>
        <sui-table striped selectable v-if="fitterIllness && fitterIllness.illnessPeriods && fitterIllness.illnessPeriods.length">
            <sui-table-header>
                <sui-table-row>
                    <sui-table-header-cell v-if="selectPeriods"><i class="fa-solid fa-square-check"></i></sui-table-header-cell>
                    <sui-table-header-cell><i class="fas fa-check" title="Sygeperioden er markeret som 'håndteret'" style="color: green;"></i></sui-table-header-cell>
                    <sui-table-header-cell>Første sygedag</sui-table-header-cell>
                    <sui-table-header-cell>Sidste sygedag</sui-table-header-cell>
                    <sui-table-header-cell>Antal dage</sui-table-header-cell>
                    <sui-table-header-cell>Antal timer</sui-table-header-cell>
                </sui-table-row>
            </sui-table-header>
            <sui-table-body>
                <sui-table-row v-for="illnessPeriod of fitterIllness.illnessPeriods" :key="illnessPeriod.precededByDate" class="clickable" @click="selectIllnessPeriod(illnessPeriod)">
                    <sui-table-cell v-if="selectPeriods">
                        <i v-if="selectedPeriods.includes(illnessPeriod.precededByDate)" @click.stop="selectedPeriods.splice(selectedPeriods.indexOf(illnessPeriod.precededByDate))" class="fa-regular fa-square-check"></i>
                        <i v-else @click.stop="selectedPeriods.push(illnessPeriod.precededByDate)" class="fa-regular fa-square"></i>
                    </sui-table-cell>
                    <sui-table-cell>
                        <i v-if="illnessPeriod.illnessPeriodHandled" class="fas fa-check" title="Sygeperioden er markeret som 'håndteret'" style="color: green;"></i>
                        <i v-else-if="illnessPeriod.illnessPeriodPartiallyHandled" class="fa-solid fa-triangle-exclamation" title="Sygeperioden er delvist markeret som 'håndteret'" style="color: orange;"></i>
                    </sui-table-cell>
                    <sui-table-cell>{{ toUserFriendlyDate(illnessPeriod.firstIllnessDate) }}</sui-table-cell>
                    <sui-table-cell>{{ toUserFriendlyDate(illnessPeriod.lastIllnessDate) }}</sui-table-cell>
                    <sui-table-cell>{{ illnessPeriod.illnessDates.length }}</sui-table-cell>
                    <sui-table-cell>{{ illnessPeriod.totalIllnessHours }}</sui-table-cell>
                </sui-table-row>
            </sui-table-body>
        </sui-table>
        <!-- <sui-table>
            <sui-table-header>
                <sui-table-row>
                    <sui-table-header-cell>Dato</sui-table-header-cell>
                    <sui-table-header-cell>Timer</sui-table-header-cell>
                    <sui-table-header-cell>Løndel</sui-table-header-cell>
                    <sui-table-header-cell>Sag</sui-table-header-cell>
                    <sui-table-header-cell>Evt note</sui-table-header-cell>
                </sui-table-row>
            </sui-table-header>
            <sui-table-body>
                <sui-table-row v-for="illness of fitterIllness" :key="illness.id">
                    <sui-table-cell>{{ toUserFriendlyDate(illness.Date) }}</sui-table-cell>
                    <sui-table-cell>{{ illness.Hours }}</sui-table-cell>
                    <sui-table-cell>{{ illness.CategoryName }}</sui-table-cell>
                    <sui-table-cell>{{ illness.ProjectDescription }}</sui-table-cell>
                    <sui-table-cell>{{ illness.Note }}</sui-table-cell>
                </sui-table-row>
            </sui-table-body>
        </sui-table> -->
    </div>
</template>
<script>
import { DateMixin } from '../../lib/Mixins/dateMixin'
// import PureVueChart from 'pure-vue-chart';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import { Bar } from 'vue-chartjs/legacy'
import IllnessPeriodModal from './IllnessPeriodModal.vue'
import swal from 'sweetalert';
import { db } from '@/firebase';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
    name: 'illnessStatsFitter',
    components: {
        Bar,
        IllnessPeriodModal,
    },
    mixins: [DateMixin],

    props: {
        fitterIllness: Object,
        handledFitterIllness: Array,
        dataIsReady: Boolean,
    },

    data(){
        return {
            illnessChartOptions: {
                responsive: true,
                scales: {
                    y: {
                        type: 'linear',
                        display: true,
                        position: 'left',
                    },
                    y1: {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        grid: {
                            drawOnChartArea: false,
                        },
                    },
                },
            },
            selectedPeriod: null, //Selected illness period, to show in modal
            illnessPeriodModalOpen: false,

            selectPeriods: false, //Allow selecting multiple periods
            selectedPeriods: [], //Array of selected periods
        }
    },

    computed: {
        fitterIllnessByWeekday() {
            if (!this.fitterIllness?.illnessHoursByWeekday || !this.fitterIllness?.illnessHoursPercentageByWeekday) return {datasets: [], labels: []}
            let tempObj = {
                datasets: [
                    { label: 'Sygdoms %', data: this.fitterIllness.illnessHoursPercentageByWeekday.map((val, i) => {return {x: i, y: val}}), backgroundColor: '#d69d56', yAxisID: 'y' },
                    { label: 'Sygdoms timer', data: this.fitterIllness.illnessHoursByWeekday.map((val, i) => {return {x: i, y: val}}), backgroundColor: '#ebd4be', yAxisID: 'y1' },
                ],
                labels: ['man', 'tirs', 'ons', 'tors', 'fre', 'lør', 'søn'],
            }
            if (!tempObj.datasets[1].data[6]?.y || tempObj.datasets[1].data[6].y == 0) {//Remove sunday if no hours
                tempObj.datasets[0].data.splice(6)
                tempObj.datasets[1].data.splice(6)
                tempObj.labels.splice(6)
            }
            if (!tempObj.datasets[1].data[5]?.y || tempObj.datasets[1].data[5].y == 0) {//Remove saturday if no hours
                tempObj.datasets[0].data.splice(5)
                tempObj.datasets[1].data.splice(5)
                tempObj.labels.splice(5)
            }
            // console.log(tempObj)
            return tempObj
        },
        fitterIllnessByMonth() {
            if (typeof this.fitterIllness?.statsByMonth?.[0]?.illnessHoursPercentage == 'undefined' && typeof this.fitterIllness?.statsByMonth?.[0]?.illnessHours == 'undefined') return {datasets: [], labels: []}
            let tempObj = {
                datasets: [
                    { label: 'Sygdoms %', data: this.fitterIllness.statsByMonth.map(month => month.illnessHoursPercentage).map((val, i) => {return {x: i, y: val}}), backgroundColor: '#d69d56', yAxisID: 'y' },
                    { label: 'Sygdoms timer', data: this.fitterIllness.statsByMonth.map(month => month.illnessHours).map((val, i) => {return {x: i, y: val}}), backgroundColor: '#ebd4be', yAxisID: 'y1' },
                ],
                labels: this.fitterIllness.statsByMonth.map(month => month.label),
            }
            return tempObj
        },
        containerWidth() {
            return document?.getElementById(`illnessStatsFitter-${this.fitterIllness ? this.fitterIllness.fitterSalaryID : 'undefined'}`)?.offsetWidth || window.innerWidth*0.8
        },
        graphWidth() {
            // if (!this.dataIsReady) return 300
            console.log(document.getElementsByClassName('col-sm-4')[0]?.offsetWidth - 50)
            return document.getElementsByClassName('col-sm-4')[0]?.offsetWidth - 50 || 300
            // return (this.containerWidth / 3) - 20
        },
        user() {
            return this.$root.$children[0].user
        },
        fitterIsNotSelf() {
            if (!this.fitterIllness?.fitterSalaryID || !this.user?.email) return false;
            return this.fitterIllness?.fitterSalaryID?.toLowerCase() != this.user.email.split('@')[0]?.toLowerCase()
        },
    },

    methods: {
        selectIllnessPeriod(illnessPeriod) {
            // this.$emit('selectIllnessPeriod', illnessPeriod)
            this.selectedPeriod = illnessPeriod
            this.illnessPeriodModalOpen = true
            console.log(illnessPeriod)
        },
        deepClose() {
            this.selectPeriods = false;
            this.selectedPeriods = [];
            this.illnessPeriodModalOpen = false;
            this.$emit('deepclose');
        },
        findRelevantHandledIllnessPeriod(illnessPeriod) {
            if (!this.handledFitterIllness) {
                // console.log('No handled periods')
                return null;
            }
            //Find the handled period that matches the illness period based on matching most illness IDs - if multiple handled periods match, return the one with the most matching illness IDs, but show a warning in the console, as periods should not overlap
            let thisHandledPeriod = null;
            let mostIDMatches = 0;
            for (let handledPeriod of this.handledFitterIllness) {
                let idMatches = handledPeriod.illnessIds.reduce((acc, curr) => {
                    if (illnessPeriod.illnesses.find(illness => illness.ID == curr)) {
                        acc++;
                    }
                    return acc;
                }, 0);
                if (idMatches > mostIDMatches) {
                    if (mostIDMatches > 0) {
                        console.warn(`Multiple handled periods match the same illness period - this should not happen`);
                    }
                    thisHandledPeriod = handledPeriod;
                    mostIDMatches = idMatches;
                }
            }
            // console.log(thisHandledPeriod)
            return thisHandledPeriod;
        },
        async markSelectedPeriodsHandled() {
            let swalResponse = await swal({
                content: {
                    text: "Evt note for håndtering af sygeperioderne",
                    element: "input",
                    attributes: {
                        placeholder: "Valgfri note der skrives på de valge perioder",
                        type: "textArea",
                        value: '',
                    },
                },
                buttons: {
                    cancel: {
                        text: 'Annulér',
                        value: 'CANCEL',
                        visible: true,
                    },
                    confirm: {
                        text: 'Håndtér valgte perioder',
                        visible: true,
                    },
                },
            })
            // console.log(swalResponse);
            if (swalResponse == 'CANCEL') {
                return;
            }

            if (!this.selectedPeriods.length) {
                this.selectedPeriods = this.fitterIllness.illnessPeriods.map(period => period.precededByDate)
            }

            for (let period of this.fitterIllness.illnessPeriods) {
                if (this.selectedPeriods.includes(period.precededByDate)) {

                    //Match the period to any already handled periods that match the same illnesses
                    
                    // let thisHandledPeriod = null;
                    // let mostIDMatches = 0;
                    // for (let handledPeriod of this.handledFitterIllness) {
                    //     let idMatches = handledPeriod.illnessIds.reduce((acc, curr) => {
                    //         if (this.fitterIllness.illnessPeriods.find(illness => illness.ID == curr)) {
                    //             acc++;
                    //         }
                    //         return acc;
                    //     }, 0);
                    //     if (idMatches > mostIDMatches) {
                    //         if (mostIDMatches > 0) {
                    //             console.warn(`Multiple handled periods match the same illness period - this should not happen`);
                    //         }
                    //         thisHandledPeriod = handledPeriod;
                    //         mostIDMatches = idMatches;
                    //     }
                    // }
                    let thisHandledPeriod = this.findRelevantHandledIllnessPeriod(period);
                    // console.log(thisHandledPeriod)

                    let updateDoc = {
                        fitterInitials: this.fitterIllness.fitterSalaryID,
                        handledAt: new Date().toISOString(),
                        handledBy: this.user.email,
                        note: swalResponse,
                        firstIllnessDate: thisHandledPeriod ? 
                            (new Date(thisHandledPeriod.firstIllnessDate) < new Date(period.firstIllnessDate) ? thisHandledPeriod.firstIllnessDate : period.firstIllnessDate) : 
                            period.firstIllnessDate,
                        lastIllnessDate: thisHandledPeriod ? 
                            (new Date(thisHandledPeriod.lastIllnessDate) > new Date(period.lastIllnessDate) ? thisHandledPeriod.lastIllnessDate : period.lastIllnessDate) : 
                            period.lastIllnessDate,
                        illnessIds: thisHandledPeriod ? 
                            Array.from(new Set([...thisHandledPeriod.illnessIds, ...period.illnesses.map(illness => illness.ID)])) : 
                            period.illnesses.map(illness => illness.ID),
                    }

                    if (thisHandledPeriod) {
                        await db.collection('HandledIllnessPeriods').doc(thisHandledPeriod.id).update(updateDoc);
                    } else {
                        await db.collection('HandledIllnessPeriods').add(updateDoc);
                    }
                }
            }

            this.deepClose()
        },
    },
}
</script>
<style scoped>
    .graph-col {
        width: 100%;
    }
    .clickable:hover {
        cursor: pointer;
    }
</style>