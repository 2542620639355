<template>
    <sui-container>
        <sui-message
            error
            v-if="showErrorMessage"
        >
            Fejl i {{ isEditing ? 'redigeringen' : 'oprettelsen' }} af projektet. Fejl: {{ errorMessage }}
        </sui-message>
        <sui-form :loading="isLoading">
            <div class="row">
                <div class="col-sm-12">
                    <DataCard :title="`${isEditing ? `Rediger` : `Opret`} Projekt`">
                        <sui-form-fields fields="two">
                            <sui-form-field required :error="formErrors.indexOf('projectName') !== -1">
                                <label for="projectName">Projekt Navn</label>
                                <input type="text" placeholder="Projekt Navn" id="projectName" v-model="projectName" @change="validateField('projectName')" />
                            </sui-form-field>
                            <sui-form-field required :error="formErrors.indexOf('projectStatus') !== -1">
                                <label>Status</label>
                                <sui-dropdown
                                    placeholder="Status"
                                    search
                                    selection
                                    :options="projectStatuses"
                                    v-model="projectStatus"
                                    @input="validateField('projectStatus')"
                                />
                            </sui-form-field>
                        </sui-form-fields>
                        <sui-form-fields fields="two">
                            <sui-form-field required :error="formErrors.indexOf('projectManager') !== -1">
                                <label>Projektleder</label>
                                <sui-dropdown
                                    placeholder="Projektleder"
                                    search
                                    selection
                                    :options="projectManagerList"
                                    v-model="projectManager"
                                    @input="validateField('projectManager')"
                                />
                            </sui-form-field>
                            <sui-form-field required :error="formErrors.indexOf('EFBContact') !== -1">
                                <label>EFB Kontakt</label>
                                <sui-dropdown
                                    placeholder="EFB Kontakt"
                                    search
                                    selection
                                    :options="EFBContacts"
                                    v-model="EFBContact"
                                    @input="validateField('EFBContact')"
                                />
                            </sui-form-field>
                        </sui-form-fields>
                        <sui-form-fields fields="two">
                            <sui-form-field>
                                <label>Kræver rolle for adgang</label>
                                <sui-dropdown
                                    placeholder="Rolle"
                                    search
                                    selection
                                    :options="UserRoles.DROPDOWN_OPTIONS_DATA_PROVIDER"
                                    v-model="requiredRole"
                                    @input="validateField('projectManager')"
                                />
                            </sui-form-field>
                        </sui-form-fields>
                    </DataCard>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-6">
                    <DataCard title="Projekt Typer" subtitle="Vælg relevante opgave-typer">
                        <sui-dropdown
                            multiple
                            fluid
                            placeholder="Projekt Typer"
                            search
                            selection
                            :options="projectTypes"
                            v-model="projectType"
                        />
                    </DataCard>

                    <DataCard title="Reference ID'er" subtitle="Vælg relevante reference ID'er">
                        <div v-if="referenceListType === 'whitelist'"> 
                            <sui-form-field>
                                <sui-checkbox 
                                    id="refIdWhitelistIsExclusive"
                                    label="Inkludér KUN opgaver med disse referenceID'er" 
                                    v-model="refIdWhitelistIsExclusive"
                                ></sui-checkbox>
                            </sui-form-field>
                            <sui-form-field>
                                <label v-if="refIdWhitelistIsExclusive" for="refIdWhitelistIsExclusive"> Opgaver UDEN disse reference ID'er FJERNES fra projektet </label>
                                <label v-if="!refIdWhitelistIsExclusive" for="refIdWhitelistIsExclusive"> Opgaver MED disse reference ID'er TILFØJES til projektet </label>
                            </sui-form-field>
                        </div>
                        <div v-else>
                            <sui-form-field>
                                <label> Opgaver MED disse reference ID'er FJERNES fra projektet </label>
                            </sui-form-field>
                        </div>

                        <div>
                            <sui-checkbox label="Whitelist" id="referenceId" title="Kun indtastede referenceID'er er med i projektet" value="whitelist" v-model="referenceListType" radio/> &nbsp; &nbsp; &nbsp;
                            <sui-checkbox label="Blacklist" id="referenceId" title="Indtastede referenceID'er er IKKE med i projektet" value="blacklist" v-model="referenceListType" radio/>
                        </div>
                        <div v-for="referenceId, index in referenceList" :key="index">
                            <sui-input v-model="referenceList[index]"/> &nbsp;
                            <sui-button negative @click.prevent="referenceList.splice(index, 1)"><i class="fa-solid fa-trash"></i> Slet</sui-button>
                        </div>
                        <sui-button positive @click.prevent="referenceList.push('')">
                            Tilføj Reference ID
                        </sui-button>
                    </DataCard>

                    <DataCard title="Tilknyt Son Win Områder"  subtitle="Vælg relevante områder">
                        <div>
                            <sui-checkbox label="Whitelist" id="areaCodes" title="Kun indtastede Son Win områder er med i projektet" value="whitelist" v-model="selectedAreaCodesType" radio/> &nbsp; &nbsp; &nbsp;
                            <sui-checkbox label="Blacklist" id="areaCodes" title="Indtastede Son Win områder er IKKE med i projektet" value="blacklist" v-model="selectedAreaCodesType" radio/>
                        </div>
                        <sui-dropdown
                            multiple
                            fluid
                            placeholder="Son Win Områder"
                            search
                            selection
                            :loading="isLoadingSonWin"
                            :options="sonWinProjectOptions"
                            v-model="selectedAreaCodes"
                        />
                    </DataCard>

                    <DataCard title="Tilknyt Accesshuse" subtitle="Vælg et eller flere teknikhuse">
                        <sui-dropdown
                            multiple
                            fluid
                            placeholder="Accesshuse"
                            search
                            selection
                            :loading="!hubs.length"
                            :options="hubOptions"
                            v-model="selectedHubs"
                        />
                    </DataCard>

                    <DataCard title="Tilknyt skabe" subtitle="Vælg et eller flere skabe">
                        <sui-form>
                            <sui-form-fields>
                                <sui-form-field>
                                    <label>Skabsnummer</label>
                                    <sui-input placeholder="999-101" v-model="cabinetInput"/>
                                </sui-form-field>
                                <sui-button size="tiny" positive @click.prevent="addCabinet()">Tilføj</sui-button>
                            </sui-form-fields>
                            <sui-form-field>
                                <sui-dropdown
                                    multiple
                                    fluid
                                    placeholder="Ingen skabe valgt"
                                    search 
                                    selection 
                                    direction="upward"
                                    :options="cabinetOptions"
                                    v-model="selectedCabinets"
                                />
                            </sui-form-field>
                        </sui-form>
                    </DataCard>

                    <DataCard title="Medarbejdere" no-padding>
                        <sui-form-field required :error="formErrors.indexOf('selectedWorkers') !== -1">
                            <sui-table compact definition striped selectable class="workers">
                                <sui-table-header full-width>
                                    <sui-table-row>
                                        <sui-table-header-cell :width="2" />
                                        <sui-table-header-cell>Navn</sui-table-header-cell>
                                        <sui-table-header-cell>Email</sui-table-header-cell>
                                    </sui-table-row>
                                </sui-table-header>
                                <sui-table-body v-if="avilableWorkers.length != 0">
                                    <sui-table-row v-for="(worker, index) in avilableWorkers" :key="index" :error="formErrors.indexOf('selectedWorkers') !== -1">
                                        <sui-table-cell :width="1">
                                            <sui-checkbox toggle v-model="worker.selected" @change="validateField('selectedWorkers')" />
                                        </sui-table-cell>
                                        <sui-table-cell>
                                            {{ worker.Name }}
                                        </sui-table-cell>
                                        <sui-table-cell>{{ worker.Email }}</sui-table-cell>
                                    </sui-table-row>
                                </sui-table-body>
                                <sui-table-body v-if="false && avilableWorkers.length == 0">
                                    <sui-table-row>
                                        <sui-table-cell colspan="3">
                                            <TableLoader />
                                        </sui-table-cell>
                                    </sui-table-row>
                                </sui-table-body>
                                <sui-table-footer full-width>
                                    <sui-table-row>
                                        <sui-table-header-cell :width="2" />
                                        <sui-table-header-cell colspan="2">
                                            <sui-button size="mini" @click.prevent="selectAllWorkers">
                                                Vælg alle
                                            </sui-button>
                                            <sui-button size="mini" @click.prevent="deselectAllWorkers" :disabled="!hasSelectedWorkers">
                                                Fjern alle
                                            </sui-button>
                                        </sui-table-header-cell>
                                    </sui-table-row>
                                </sui-table-footer>
                            </sui-table>
                        </sui-form-field>
                    </DataCard>
                    <DataCard title="Booking-tidsrum" no-padding>
                        <sui-form-field required :error="formErrors.indexOf('selectedWorkers') !== -1">
                            <sui-table compact striped class="workers">
                                <sui-table-header full-width>
                                    <sui-table-row>
                                        <sui-table-header-cell :width="5">Fra kl</sui-table-header-cell>
                                        <sui-table-header-cell :width="5">Til kl</sui-table-header-cell>
                                        <sui-table-header-cell :width="4">Navn i dropdown</sui-table-header-cell>
                                        <sui-table-header-cell :width="2"></sui-table-header-cell>
                                    </sui-table-row>
                                </sui-table-header>
                                <sui-table-body>
                                    <sui-table-row v-for="(timeWindow, index) in projectTimeWindows" :key="index" :error="formErrors.indexOf('projectTimeWindows') !== -1">
                                        <sui-table-cell :width="5">
                                            <sui-input size="small" v-model="timeWindow.from" style="margin-top: -5px; padding-bottom: 3px;"/>
                                        </sui-table-cell>
                                        <sui-table-cell :width="5">
                                            <sui-input size="small" v-model="timeWindow.to" style="margin-top: -5px; padding-bottom: 3px;"/>
                                        </sui-table-cell>
                                        <sui-table-cell :width="4">{{ formatTimeWindowDropDownName(timeWindow) }}</sui-table-cell>
                                        <sui-table-cell :width="2">
                                            <sui-button
                                                floated="right"
                                                size="mini"
                                                style="padding: 9.87755px; margin-top: -1px;"
                                                negative
                                                @click.prevent="projectTimeWindows.splice(index,1)"
                                            ><i class="fas fa-trash-alt"></i></sui-button>
                                        </sui-table-cell>
                                    </sui-table-row>
                                </sui-table-body>
                                <sui-table-footer full-width>
                                    <sui-table-row>
                                        <sui-table-header-cell colspan="3">
                                            <sui-button size="mini" @click.prevent="addTimeWindow">
                                                Tilføj tidsrum
                                            </sui-button>
                                        </sui-table-header-cell>
                                    </sui-table-row>
                                </sui-table-footer>
                            </sui-table>
                        </sui-form-field>
                    </DataCard>
                    <DataCard title="Mærkater" subtitle="Omdøb mærkaterne til at passe til projektet" no-padding>
                        <sui-form-field>
                            <sui-table compact definition striped selectable class="workers">
                                <sui-table-body>
                                    <sui-table-row v-for="(tag) in tags" :key="tag.value">
                                        <sui-table-cell :width="1">
                                            <sui-checkbox toggle v-model="tag.include"/>
                                        </sui-table-cell>
                                        <sui-table-cell :width="1" style="padding: 11px 7px 0px 5px;">
                                            <i :class="`fa-solid fa-tag fa-2xl ${tag.value}`"></i>
                                        </sui-table-cell>
                                        <sui-table-cell>
                                                <sui-input size="small" v-model="tag.text" style="margin-top: -4px; padding-bottom: 4px;"/>
                                            </sui-table-cell>
                                    </sui-table-row>
                                </sui-table-body>
                            </sui-table>
                        </sui-form-field>
                    </DataCard>
                    <DataCard title="Handlinger på forgæves kontakt" subtitle="Vælg handlinger ved forgæves kontakt">
                        <sui-divider horizontal style="padding-left: 10px; padding-right: 10px">
                            Handling for ProjectTasks
                        </sui-divider>
                        <sui-form-field>
                            <sui-dropdown
                                fluid
                                placeholder="Handling ved forgæves kontakt"
                                search 
                                selection 
                                direction="upward"
                                :options="failedContactProjectTaskActions"
                                v-model="selectedFailedContactProjectTaskAction"
                            />
                        </sui-form-field>
                        <sui-form-field>
                            <sui-dropdown
                                :disabled="selectedFailedContactProjectTaskAction == 'none'"
                                fluid
                                placeholder="Mærkat ved forgæves kontakt"
                                search 
                                selection 
                                direction="upward"
                                :options="failedContactTagOptions"
                                v-model="selectedFailedContactProjectTaskTag"
                            />
                        </sui-form-field>
                        <span style="white-space: pre-wrap;">
                            {{ failedContactActionProjectTaskDescription }}
                        </span>

                        <sui-divider horizontal style="padding-left: 10px; padding-right: 10px">
                            Handling for TroubleTickets
                        </sui-divider>
                        <sui-form-field>
                            <sui-dropdown
                                fluid
                                placeholder="Handling ved forgæves kontakt"
                                search 
                                selection 
                                direction="upward"
                                :options="failedContactTicketActions"
                                v-model="selectedFailedContactTicketAction"
                            />
                        </sui-form-field>
                        <sui-form-field>
                            <sui-dropdown
                                :disabled="selectedFailedContactTicketAction == 'none'"
                                fluid
                                placeholder="Mærkat ved forgæves kontakt"
                                search 
                                selection 
                                direction="upward"
                                :options="failedContactTagOptions"
                                v-model="selectedFailedContactTicketTag"
                            />
                        </sui-form-field>
                        <span style="white-space: pre-wrap;">
                            {{ failedContactActionTicketDescription }}
                        </span>

                    </DataCard>
                </div>
                <div class="col-sm-6">


                    <DataCard title="Fakturering" subtitle="Vælg relevant faktureringsmetode" no-padding>

                        <div style="padding-left: 20px; padding-top: 10px;">
                            <sui-form-field>
                                <label for="billingCategory"> Projekt Fakturering </label>
                            </sui-form-field>
                            <div v-for="option in projectBillingOptions" :key="option.text"> 
                                <sui-form-field>
                                    <sui-checkbox radio
                                        name="billingCategory" 
                                        :label="option.text" 
                                        :value="option.value" 
                                        v-model="projectBillingCategory"
                                    />
                                </sui-form-field>
                            </div>
                        </div>

                        <div v-if="projectBillingCategory == 'billingProject'"> 
                            <div v-for="(task, indexOne) in taskCompletionUnitworkUnitOptions" :key="indexOne">
                                <sui-divider horizontal style="padding-left: 10px; padding-right: 10px">
                                    {{task.header}}
                                </sui-divider>
                                <sui-table compact striped class="workers">
                                    <sui-table-header full-width>
                                        <sui-table-row>
                                            <sui-table-header-cell :width="6">Vist tekst</sui-table-header-cell>
                                            <sui-table-header-cell :width="8">Arbejdsenhed</sui-table-header-cell>
                                            <sui-table-header-cell :width="2"></sui-table-header-cell>
                                        </sui-table-row>
                                    </sui-table-header>
                                    <sui-table-body style="overflow: visible;">
                                        <sui-table-row v-for="(unitWorkOption, indexTwo) in task.options" :key="`${indexOne}:${indexTwo}`">
                                            <sui-table-cell :width="6">
                                                <sui-input size="small" v-model="unitWorkOption.text" style="margin-top: -4px; padding-bottom: 4px;"/>
                                            </sui-table-cell>
                                            <sui-table-cell :width="8" style="overflow: visible;">
                                                <sui-dropdown
                                                    fluid
                                                    placeholder="Arbejdsenhed"
                                                    style="margin-top: -6px;"
                                                    search
                                                    selection
                                                    :options="unitWorkCompositeUnitsOptions"
                                                    v-model="unitWorkOption.value"
                                                />
                                            </sui-table-cell>
                                            <sui-table-cell :width="2">
                                                <sui-button
                                                    floated="right"
                                                    size="mini"
                                                    style="padding: 9.87755px;"
                                                    negative
                                                    @click.prevent="deleteUnitWorkOption(indexOne, indexTwo)"
                                                ><i class="fas fa-trash-alt"></i></sui-button>
                                            </sui-table-cell>
                                        </sui-table-row>
                                    </sui-table-body>
                                    <sui-table-footer full-width>
                                        <sui-table-row>
                                            <sui-table-header-cell colspan="3">
                                                <sui-button size="mini" @click.prevent="addUnitWorkOption(indexOne)">
                                                    Tilføj mulighed
                                                </sui-button>
                                                <sui-button size="mini" @click.prevent="resetUnitWorkOptions(indexOne)">
                                                    Nulstil til standard
                                                </sui-button>
                                            </sui-table-header-cell>
                                        </sui-table-row>
                                    </sui-table-footer>
                                </sui-table>
                            </div>
                        </div>
                        <div v-else style="padding: 20px">
                            <span> Dette projekt faktureres af et andet projekt </span>
                        </div>
                    </DataCard>

                    <!-- TODO: Insert settings here -->
                    <DataCard title="Indstillinger" no-padding>
                        <sui-form-field>
                            <sui-table compact definition striped selectable class="workers">
                                <sui-table-body>
                                    <sui-table-row v-for="(setting) in projectSettings" :key="setting.name">
                                        <sui-table-cell :width="1">
                                            <sui-checkbox toggle v-model="setting.value"/>
                                        </sui-table-cell>
                                        <sui-table-cell>
                                            {{ setting.text }}
                                        </sui-table-cell>
                                    </sui-table-row>

                                    <sui-table-row>
                                        <sui-table-cell>
                                            <div class="color-square" :class="getBackgroundColor()" style="margin-left: 6px; margin-bottom: 7px;" @click="openSelectProjectColorModal = true"></div>
                                        </sui-table-cell>
                                        <sui-table-cell>
                                            <sui-button @click.prevent="onSelectProjectColorClick" style="margin-top: 2px;">
                                                Vælg Baggrundsfarve
                                            </sui-button>
                                        </sui-table-cell>
                                    </sui-table-row>
                                </sui-table-body>
                            </sui-table>
                        </sui-form-field>
                    </DataCard>

                    <DataCard title="Integrationer">
                        <sui-form-field>
                            <sui-table compact definition striped selectable class="workers">
                                <sui-table-body>
                                    <sui-table-row>
                                        <sui-table-cell :width="1">
                                            <sui-checkbox toggle v-model="integrations.formToPDF.enabled"/>
                                        </sui-table-cell>
                                        <sui-table-cell>
                                            Integrér PDF Generator
                                        </sui-table-cell>
                                    </sui-table-row>
                                </sui-table-body>
                            </sui-table>
                        </sui-form-field>
                        
                        <sui-form-field v-if="integrations.formToPDF.enabled">
                            <label for="intPDFGeneratorName">Navn på PDF Integration</label>
                            <input type="text" placeholder="Etableringsaftaler" id="intPDFGeneratorName" v-model="integrations.formToPDF.name" @change="validateField('projectName')" />
                        </sui-form-field>
                        
                        <sui-form-field v-if="integrations.formToPDF.enabled">
                            <label>Standard skabelon</label>
                            <sui-dropdown
                                fluid
                                placeholder="Formular skabelon"
                                search
                                selection
                                :options="formToPDFTemplates"
                                v-model="integrations.formToPDF.defaultTemplate"
                            />
                        </sui-form-field>
                    </DataCard>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <sui-button v-if="isEditing" @click.prevent="deleteButtonClicked" negative>
                        <sui-icon name="trash" />
                        Slet Projekt
                    </sui-button>
                </div>
                <div class="col-md-6" style="text-align: right">
                    <sui-button @click.prevent="saveProject" primary>
                        <sui-icon name="save" />
                        Gem Projekt
                    </sui-button>
                </div>
            </div>       
        </sui-form>

        <sui-modal
            size="mini"
            id="selectProjectBackgroundColorModal"
            :open="openSelectProjectColorModal"
            v-on:clickAwayModal="openSelectProjectColorModal = false"
        >
            <sui-modal-header>
                Vælg Projektfarve
            </sui-modal-header>
            <sui-modal-content>
                <div style="display: flex;">
                    <div :class="setColorSquareClasses('')" @click="setBackgroundColor('')"></div>
                    <div :class="setColorSquareClasses('background-1')" @click="setBackgroundColor('background-1')"></div>
                    <div :class="setColorSquareClasses('background-2')" @click="setBackgroundColor('background-2')"></div>
                    <div :class="setColorSquareClasses('background-3')" @click="setBackgroundColor('background-3')"></div>
                </div>
                <div style="display: flex;">
                    <div :class="setColorSquareClasses('background-4')" @click="setBackgroundColor('background-4')"></div>
                    <div :class="setColorSquareClasses('background-5')" @click="setBackgroundColor('background-5')"></div>
                    <div :class="setColorSquareClasses('background-6')" @click="setBackgroundColor('background-6')"></div>
                    <div :class="setColorSquareClasses('background-7')" @click="setBackgroundColor('background-7')"></div>
                </div>
                <div style="display: flex;">
                    <div :class="setColorSquareClasses('background-8')" @click="setBackgroundColor('background-8')"></div>
                    <div :class="setColorSquareClasses('background-9')" @click="setBackgroundColor('background-9')"></div>
                    <div :class="setColorSquareClasses('background-10')" @click="setBackgroundColor('background-10')"></div>
                    <div :class="setColorSquareClasses('background-11')" @click="setBackgroundColor('background-11')"></div>
                </div>
            </sui-modal-content>
            <sui-modal-actions>
                <sui-button  
                    @click.prevent="openSelectProjectColorModal = false"
                >
                    Annullér
                </sui-button>
            </sui-modal-actions>
        </sui-modal>

    </sui-container>
</template>

<script>
import { db } from '../../firebase'
import { DataAPI } from '../../lib/DataAPI'
import {LPCMixin} from '../../lib/LPCMixin'
import { Mixin } from '../../lib/Mixins/mixin'
import { DateMixin } from '../../lib/Mixins/dateMixin'
import { ProjectSettingsMixin } from '@/modules/ProjectSettings/projectSettingsMixin.js'
import { unitWorkMixin } from '../../lib/unitWorkMixin'
import ProjectStatus from '../../lib/Enums/ProjectStatus'
import ProjectType from '../../lib/Enums/ProjectType'
import TableLoader from '../../components/TableLoader'
import ContactRole from '../../lib/Enums/ContactRole'
import UserRoles from '../../lib/Enums/UserRoles'
import swal from 'sweetalert'
import EventBus from '../../EventBus'
import TaskCode from '../../lib/Enums/TaskCode'
import TicketState from '../../lib/Enums/TicketState'
import TaskState from '../../lib/Enums/TaskState'

export default {
    name: 'ProjectCreateView',
    mixins: [DataAPI, LPCMixin, Mixin, DateMixin, ProjectSettingsMixin, unitWorkMixin],

    components: {
        TableLoader
    },

    enums: {
        ProjectStatus,
        ProjectType,
        ContactRole,
        UserRoles,
        TaskCode,
        TicketState,
        TaskState
    },

    data() {
        return {
            // State and storage
            isEditing: false,
            isLoading: false,
            showSuccessMessage: false,
            showErrorMessage: false,

            errorMessage: null,
            contacts: [],
            avilableWorkers: [],
            formErrors: [], // Array of strings
            projects: [],
            isLoadingSonWin: true,
            requiredRole: UserRoles.EFB_API_USER,

            // Integrations
            integrations: {
                formToPDF: {
                    enabled: false,
                    name: '',
                    defaultTemplate: null
                }
            },

            formToPDFTemplates: [],

            usePDFGenerator: false,
            intPDFGeneratorName: '',

            projectStatuses: [
                { value: 0, text: ProjectStatus.getValueFromCode(ProjectStatus.PLANNED) },
                { value: 1, text: ProjectStatus.getValueFromCode(ProjectStatus.IN_PROGRESS) },
                { value: 2, text: ProjectStatus.getValueFromCode(ProjectStatus.CLOSED) }
            ],

            projectTypes: ProjectType.dropdownOptions,

            defaultTaskCompletionUnitworkUnitOptions: {
                [`${TaskCode.TICKET}`]: {
                    header: 'Arbejdsenheder på driftsager (TICKET)',
                    options: [
                        {text: 'Netselskab', value: 'F.TRT.EFB'},
                        {text: 'Indholdsudbyder', value: 'F.TRT.SP'},
                        {text: 'AX-nummer', value: 'F.TRT.AX'},
                    ],
                    appliesToState: [TicketState.RESOLVED, TicketState.CLOSED],
                },
                [`${TaskCode.CPE}`]: {
                    header: 'Arbejdsenheder på kundeinstallationer (CPE)',
                    options: [
                        {text: 'Installation (akkord)', value: '931.F.08'},
                        {text: 'Installation (timeløn)', value: '931.F.08t'},
                    ],
                    appliesToState: [TaskState.CLOSED_COMPLETE],
                },
                [`${TaskCode.FIBERKONSU}`]: {
                    header: 'Arbejdshenheder på besigtigelse (FIBERKONSU)',
                    options: [
                        {text: 'Besigtigelse', value: '931.F.08b'},
                    ],
                    appliesToState: [TaskState.CLOSED_COMPLETE],
                },
                [`${TaskCode.UDVID}`]: {
                    header: 'Arbejdshenheder på udvidet installation (UDVID)',
                    options: [
                        {text: 'Udvidet installation', value: '931.F.08u'},
                    ],
                    appliesToState: [TaskState.CLOSED_COMPLETE],
                },
                [`${TaskCode.PATCH}`]: {
                    header: 'Arbejdshenheder på patch opgave (PATCH)',
                    options: [
                        {text: 'Ingen Arbejdsenhed', value: 'F.NULL'},
                    ],
                    appliesToState: [TaskState.CLOSED_COMPLETE],
                },
            },

            taskCompletionUnitworkUnitOptions: {},

            // Project data
            projectId: '', // Auto-gen from Firebase
            projectName: '',
            projectStatus: null,
            projectType: [],
            projectManager: null,
            EFBContact: null,
            selectedAreaCodesType: 'whitelist', //whitelist or blacklist
            selectedAreaCodes: [],
            referenceListType: "blacklist", //whitelist or blacklist
            refIdWhitelistIsExclusive: false, //true or false
            referenceList: [],

            selectedProjectColor: '',
            openSelectProjectColorModal: false,

            hubs: null,
            selectedHubs: null,

            uubs: [],
            cabinetInput: "",
            customCabinetOptions: [],
            selectedCabinets: [],

            standardTimeWindows: [
                { from: '7', to: '11' },
                { from: '10', to: '13' },
                { from: '12', to: '14:30' },
            ],

            projectTimeWindows: [],
            unitWorkCompositeUnits: [],

            projectBillingCategory: 'billingProject',
            projectBillingOptions: [
                { value: 'billingProject', text: 'Primær (Faktureres)' },
                { value: 'subProject', text: 'Sekundær (Faktureres af andet projekt)' },
            ],

            tags: [
                { text: 'Tomatrød', value: 'tag-00', include: false },
                { text: 'Lys Koral', value: 'tag-01', include: false },
                { text: 'Fersken', value: 'tag-02', include: false },
                { text: 'Høstgul', value: 'tag-03', include: false },
                { text: 'Lime', value: 'tag-04', include: false },
                { text: 'Skovgrøn', value: 'tag-05', include: false },
                { text: 'Havbris', value: 'tag-06', include: false },
                { text: "It's actually cerulean", value: 'tag-07', include: false },
                { text: 'Dybhavsblå', value: 'tag-08', include: false }, 
                { text: 'Hibiscus', value: 'tag-09', include: false },
                { text: 'Orkidé', value: 'tag-10', include: false },
                { text: 'Kirsebærblomst', value: 'tag-11', include: false},
                { text: 'Tågegrå', value: 'tag-12', include: false},
                { text: 'Skifergrå', value: 'tag-13', include: false},
            ],

            selectedFailedContactProjectTaskAction: 'none',
            selectedFailedContactTicketAction: 'none',
            selectedFailedContactProjectTaskTag: 'none',
            selectedFailedContactTicketTag: 'none',

            failedContactProjectTaskActions: [
                { text: 'Ingen handling', value: 'none' },
                { text: 'Work In Progress første gang', value: 'projectTasksWorkInProgressFirstTime' },
                { text: 'On Hold første gang', value: 'projectTaskOnHoldFirstTime' },
            ],
            failedContactTicketActions: [
                { text: 'Ingen handling', value: 'none' },
                { text: 'On Hold tredje gang', value: 'ticketOnHoldThirdTime' },
                { text: 'On Hold første gang', value: 'ticketOnHoldFirstTime' },
            ],
        }
    },

    firestore: {
        hubs: db.collection('HUBs'),
    },

    computed: {
        id() {
            if (!this.$route.params?.id) return null
            return this.$route.params.id
        },

        EFBContacts() {
            return this.contacts.map((contact, index) => {
                if (typeof contact.Phone == 'undefined') {
                    contact.Phone = ['N/A']
                }
                return { value: index, text: `${contact.Name} - ${contact.Phone.join(', ')}` }
            })
        },

        failedContactTagOptions() {
            let tagOptions = [{ text: 'Ingen mærkat', value: 'none' }];
            for (let t of this.tags) {
                if (t.include){
                    tagOptions.push({
                        value: t.value,
                        text: t.text,
                    })
                }
            }
            return tagOptions;
        },

        failedContactActionProjectTaskDescription() {
            let tagName = this.failedContactTagOptions.find(tag => tag.value === this.selectedFailedContactProjectTaskTag)?.text
            switch(this.selectedFailedContactProjectTaskAction) {
                case 'none': return `Ingen handling udføres!`;
                case 'projectTasksWorkInProgressFirstTime': return `Bruger sættes som assignee på BOOK task.\n BOOK task sættes Work In Progress ved første forgæves besøg.\n BOOK task sættes On Hold efter 3. førsøg.\n Der sættes følgende mærkat på installationen efter 3. forgæves besøg: ${tagName}.`;
                case 'projectTaskOnHoldFirstTime': return `Bruger sættes som assignee på BOOK task.\n Book task sættes On Hold ved første forgæves besøg.\n Der sættes følgende mærkat på installationen efter 3. forgæves besøg: ${tagName}.`;
                default: return 'Ukendt handling';
            }
        },

        failedContactActionTicketDescription() {
            let tagName = this.failedContactTagOptions.find(tag => tag.value === this.selectedFailedContactTicketTag)?.text
            switch(this.selectedFailedContactTicketAction) {
                case 'none': return `Ingen handling udføres!`;
                case 'ticketOnHoldThirdTime': return `Bruger sættes som assignee på ticket.\n Ticket sættes On Hold efter 3. førsøg.\n Der sættes følgende mærkat på installationen efter 3. forgæves besøg: ${tagName}.`;
                case 'ticketOnHoldFirstTime': return `Bruger sættes som assignee på ticket.\n Ticket sættes On Hold ved første forgæves besøg.\n Der sættes følgende mærkat på installationen efter 3. forgæves besøg: ${tagName}.`;
                default: return 'Ukendt handling';
            }
        },

        projectManagerList() {
            return this.avilableWorkers.map((worker, index) => {
                return { value: index, text: `${worker.Name} - ${worker.Email}`}
            })
        },

        selectedWorkers() {
            let workers = []
            this.avilableWorkers.forEach((worker) => {
                if (worker.selected) {
                    worker = JSON.parse(JSON.stringify(worker))
                    let workerClone = this.cloneJson(worker)
                    delete workerClone.selected
                    workers.push(workerClone)
                }
            })
            return workers
        },

        unitWorkCompositeUnitsOptions() {
            let options = []
            options = this.unitWorkCompositeUnits
            .map(unit => {
                return {
                    text: this.shortenString(unit.Name, 30),
                    value: unit.id,
                }
            })
            return options
        },

        hasSelectedWorkers() {
            let containsTruthyValue = false
            this.avilableWorkers.forEach((worker) => {
                if (worker.selected) {
                    containsTruthyValue = true
                }
            })
            return containsTruthyValue
        },

        uniqueAreas() {
            if (this.projects.length === 0) return []
            let areas = {}
            this.projects.forEach((task) => {
                if (task.configurationItem.area == null || task.configurationItem.area.sonWinProjectId == null) return
                areas[task.configurationItem.area.sonWinProjectId] = {
                    id: task.configurationItem.area.sonWinProjectId,
                    name: task.configurationItem.area.name
                }
            })
            return Object.values(areas)
        },

        sonWinProjectOptions() {
            if (this.uniqueAreas.length == 0) return []
            return this.uniqueAreas.map((area, index) => {
                return { key: index, value: area.id, text: `${area.id} - ${area.name}`}
            })
        },

        hubOptions() {
            let hubOptions = []
            for (let i in this.hubs) {
                let hub = this.hubs[i]
                hubOptions.push({
                    value: hub.id,
                    text: `${hub.Number} - ${hub.Name}`
                })
            }
            return hubOptions
        },

        cabinetOptions() {
            let cabinetOptionsFromHubs = []
            for (let uub of this.uubs) {
                cabinetOptionsFromHubs.push({
                    text: uub.identification,
                    value: uub.identification
                })
            }
            return [
                ...cabinetOptionsFromHubs,
                ...this.customCabinetOptions,
            ].sort((a,b) => {
                return a.text > b.text ? 1 : -1
            })
        },
    },

    methods: {
        shortenString(str, maxLenth){
            if (!str) return ''
            if (str.length > maxLenth){
                return str.substring(0, maxLenth) + '...'
            }
            return str
        },

        addUnitWorkOption(index){
            this.taskCompletionUnitworkUnitOptions[index].options.push({
                text: '',
                value: ''
            })
        },

        deleteUnitWorkOption(indexOne, indexTwo){
            this.taskCompletionUnitworkUnitOptions[indexOne].options.splice(indexTwo, 1)
        },

        resetUnitWorkOptions(index){
            this.taskCompletionUnitworkUnitOptions[index].options = this.defaultTaskCompletionUnitworkUnitOptions[index].options
        },

        onSelectProjectColorClick(){
            this.openSelectProjectColorModal = true
        },

        getBackgroundColor(){
            return this.selectedProjectColor
        },

        setBackgroundColor(color){
            this.selectedProjectColor = color
            this.openSelectProjectColorModal = false
        },

        setColorSquareClasses(color){
            if (color == this.selectedProjectColor){
                return 'color-square selected ' + color
            }
            return 'color-square selectable ' + color
        },

        selectAllWorkers(event) {
            event.preventDefault()
            this.avilableWorkers.forEach((worker) => {
                worker.selected = true
            })

            this.validateField('selectedWorkers')
        },
        deselectAllWorkers() {
            this.avilableWorkers.forEach((worker) => {
                worker.selected = false
            })

            this.validateField('selectedWorkers')
        },
        validateField(field) {
            let containsError = false
            switch(field) {
                case 'selectedWorkers': break
                case 'projectTimeWindows':
                    if (this[field].length === 0) {
                        this.validatePushField(field)
                        containsError = true
                    } else {
                        this.validateRemoveField(field)
                    }
                    break;
                
                case 'EFBContact': break
                case 'taskTypeUnitworkUnitOptions':
                    if (!this.validateTaskCompletionUnitworkUnitOptions()) {
                        this.validatePushField(field)
                        containsError = true
                    } else {
                        this.validateRemoveField(field)
                    }
                    break;
                case 'projectManager': break
                case 'projectStatus':
                    if (this[field] === null) {
                        containsError = true
                        this.validatePushField(field)
                    } else {
                        this.validateRemoveField(field)
                    }
                    break

                default:
                    if (this[field] === '') {
                        containsError = true
                        this.validatePushField(field)
                    } else {
                        this.validateRemoveField(field)
                    }
                    break
            }

            return !containsError
        },
        validatePushField(field) {
            // Only push if the field is not already in the array
            if (!this.formErrors.includes(field)) {
                this.formErrors.push(field)
            }
        },
        validateRemoveField(field) {
            let index = this.formErrors.indexOf(field)
            if (index !== -1) {
                this.formErrors.splice(index, 1)
            }
        },
        validateTaskCompletionUnitworkUnitOptions() {
            for (let taskType in this.taskCompletionUnitworkUnitOptions) {
                if (this.taskCompletionUnitworkUnitOptions[taskType].options.length === 0) {
                    return false
                }
            }
            return true
        },

        validateAllFields() {
            let containsError = false
            const fields = ['projectName', 'projectStatus', 'projectManager', 'EFBContact', 'selectedWorkers', 'timeWindows', 'taskTypeUnitworkUnitOptions']
            fields.forEach((field) => {
                let _result = this.validateField(field)
                if (!_result) {
                    containsError = true
                }
            })
            console.log("Contains error: ", containsError)

            if (containsError) {
                console.log("Throwing error")
                throw new Error('Udfyld venligst alle felter!')
            }
        },
        async saveProject(event) {
            EventBus.$emit('function-activity', {functionName: 'Project/Create_saveProject', isActive: true})
            event.preventDefault()
            this.isLoading = true

            try {
                this.validateAllFields()
            }
            catch {
                this.isLoading = false
                swal('Fejl', 'Udfyld venligst alle felter!', 'error')
                EventBus.$emit('function-activity', {functionName: 'Project/Create_saveProject', isActive: false})
                return
            }
            

            const projectLeadWorker = this.avilableWorkers[this.projectManager]
            // const fiberlanCompanyRef = db.collection('Companies').doc('Fiberlan');
            const fiberlanCompanyRef = 'Fiberlan';
            console.log('FiberLAN Company Ref:', fiberlanCompanyRef);

            let selectedProjectTypes = []
            for (let i in this.projectType) {
                selectedProjectTypes.push(String(this.projectType[i]))
            }

            let workersCopy = this.cloneJson(this.selectedWorkers)
            for (let i in workersCopy) {
                delete workersCopy[i].FireBaseUID
                delete workersCopy[i].MicrosoftUserId
                delete workersCopy[i].LastLogin
                delete workersCopy[i].Photo
                delete workersCopy[i].TokenLastRevoked
                delete workersCopy[i].TokenLastRevokedSeconds
            }

            const projectData = {
                Name: this.projectName,
                Status: ProjectStatus.getCodeFromValue(this.projectStatuses.find(s => s.value == this.projectStatus).text),
                Type: selectedProjectTypes,
                Contacts: [
                    {
                        Roles: [ContactRole.EFB_CONTACT],
                        ...this.contacts[this.EFBContact]
                    },
                    {
                        Company: fiberlanCompanyRef,
                        Email: projectLeadWorker.Email,
                        Name: `${projectLeadWorker.Name}`,
                        Phone: [projectLeadWorker.Mobile],
                        Roles: [ContactRole.PROJECT_LEAD]
                    }
                ],
                Workers: workersCopy,
                TimeWindows: this.projectTimeWindows,
                AreaCodes: this.selectedAreaCodes,
                AreaCodesType: this.selectedAreaCodesType,
                Hubs: this.selectedHubs ? this.selectedHubs : [],
                Uubs: this.selectedCabinets ? this.selectedCabinets : [],
                ReferenceIdWhitelist: this.referenceListType == 'whitelist' ? this.referenceList : [],
                ReferenceIdBlacklist: this.referenceListType == 'blacklist' ? this.referenceList : [],
                RefIdWhitelistIsExclusive: this.refIdWhitelistIsExclusive,
                ProjectSettings: this.projectSettings,
                BackgroundColor: this.selectedProjectColor,
                Integrations: this.integrations,
                RequiredRole: this.requiredRole,
                TaskCompletionUnitworkUnitOptions: this.taskCompletionUnitworkUnitOptions,
                ProjectBillingCategory: this.projectBillingCategory,
                Tags: this.tags,
                FailedContactAction: {
                    projectTaskAction: this.selectedFailedContactProjectTaskAction,
                    ticketAction: this.selectedFailedContactTicketAction,
                    projectTaskTag: this.selectedFailedContactProjectTaskTag,
                    ticketTag: this.selectedFailedContactTicketTag
                },
                Updated: new Date().toISOString(),
            }

            console.log("Project data: ", projectData)

            try {
                let id
                if (this.isEditing) {
                    await db.collection('Projects').doc(this.id).set(projectData)
                    id = this.id
                } else {
                    let documentRef = await db.collection('Projects').add(projectData)
                    id = documentRef.id
                }

                const date = new Date()
                await db.collection(`Projects/${id}/History`).doc(date.toISOString()).set({
                    ZuluDateTime: date.toISOString(),
                    User: this.$store.state.userProfile.Initials,
                    Action: this.isEditing ? 'edited' : 'created',
                    Data: projectData,
                })

                this.showSuccessMessage = true
                this.isLoading = false

                const word = (this.isEditing ? 'redigeret' : 'oprettet')
                swal('Projekt gemt', `Projektet er nu ${word}, og siden opdaterer af sig selv indenfor 5 sekunder.\n\n vent venligst...`, 'success')

                EventBus.$emit('function-activity', {functionName: 'Project/Create_saveProject', isActive: false})
                setTimeout(() => {
                    // this.$router.push(`/projects/${id}`)
                    window.location.href = `/projects/${id}`
                }, 1000)
            }
            catch (error) {
                this.errorMessage = error
                this.showErrorMessage = true
                this.isLoading = false
                EventBus.$emit('function-activity', {functionName: 'Project/Create_saveProject', isActive: false})
            }
        },

        async populateDataFromProject(projectId) {
            EventBus.$emit('function-activity', {functionName: 'Project/Create_populateDataFromProject', isActive: true})
            //Retrieve data
            let project = await this.dataGetProjectById(projectId)

            if (project.BackgroundColor){
                this.selectedProjectColor = project.BackgroundColor
            }

            if (project.Integrations){
                this.integrations = project.Integrations
            }

            if (project.RequiredRole){
                this.requiredRole = project.RequiredRole
            }

            if (project.ProjectBillingCategory){
                this.projectBillingCategory = project.ProjectBillingCategory
            }

            if (project.TaskCompletionUnitworkUnitOptions){
                this.taskCompletionUnitworkUnitOptions = this.cloneJson(this.defaultTaskCompletionUnitworkUnitOptions)
                for (let taskCode in project.TaskCompletionUnitworkUnitOptions) {
                    this.taskCompletionUnitworkUnitOptions[taskCode].options = project.TaskCompletionUnitworkUnitOptions[taskCode].options
                }
            } else {
                this.taskCompletionUnitworkUnitOptions = this.cloneJson(this.defaultTaskCompletionUnitworkUnitOptions)
            }
            
            //Project Name, Status, Types
            this.projectName = project.Name
            this.projectStatus = this.projectStatuses.find(p => p.text == ProjectStatus.getValueFromCode(project.Status))?.value
            if (project.Type == 'TroubleTickets') {
                this.projectType = ['TroubleTickets']
            } else {
                this.projectType = project.Type.map(type => {
                    return parseInt(type)
                })
            }
            
            if (project.Contacts){
                // Project Lead
                this.projectManager = this.avilableWorkers.findIndex(w => {
                    for (let i in project.Contacts) {
                        if (!project.Contacts[i].Roles.includes(ContactRole.PROJECT_LEAD)) continue
                        if (w?.Email == project.Contacts[i]?.Email) {
                            return true
                        }
                    }
                })
    
                // EFB Contact
                this.EFBContact = this.contacts.findIndex(c => {
                    for (let i in project.Contacts) {
                        if (!project.Contacts[i].Roles.includes(ContactRole.EFB_CONTACT)) continue
                        if (c.Email == project.Contacts[i]?.Email) {
                            return true
                        }
                    }
                })
            }

            // Selected workers
            this.avilableWorkers.forEach((worker, index) => {
                if (project.Workers.find(w => w.Email == worker.Email)) {
                    worker.selected = true
                    this.$set(this.avilableWorkers, index, worker)
                }
            })

            //Time windows
            this.projectTimeWindows = project.TimeWindows || this.standardTimeWindows

            // Son Win Projects
            this.selectedAreaCodes = project.AreaCodes
            this.selectedAreaCodesType = project.AreaCodesType || 'whitelist';

            //Hubs
            this.selectedHubs = project.Hubs 

            //Cabinets / UUbs
            this.selectedCabinets = project.Uubs

            //Reference ID's
            if (project.ReferenceIdWhitelist?.length){
                this.referenceListType = 'whitelist'
                this.referenceList = project.ReferenceIdWhitelist
            } else if (project.ReferenceIdBlacklist?.length) {
                this.referenceListType = 'blacklist'
                this.referenceList = project.ReferenceIdBlacklist
            } else {
                this.referenceList = []
            }
            if (project?.RefIdWhitelistIsExclusive === true || project?.RefIdWhitelistIsExclusive === false){
                this.refIdWhitelistIsExclusive = project.RefIdWhitelistIsExclusive
            }

            //Tags
            if (project.Tags){
                this.tags = project.Tags;
            }

            //Failed Contact Actions
            if (project.FailedContactAction){
                this.selectedFailedContactProjectTaskAction = project.FailedContactAction.projectTaskAction
                this.selectedFailedContactTicketAction = project.FailedContactAction.ticketAction
                this.selectedFailedContactProjectTaskTag = project.FailedContactAction.projectTaskTag
                this.selectedFailedContactTicketTag = project.FailedContactAction.ticketTag
            }

            EventBus.$emit('function-activity', {functionName: 'Project/Create_populateDataFromProject', isActive: false})

            //Project Settings
            this.loadProjectSettings(project)
        },

        async deleteButtonClicked(){
            EventBus.$emit('function-activity', {functionName: 'Project/Create_deleteButtonClicked', isActive: true})
            console.log('you pressed delete')
            let result = await swal({
                title: 'Vil du slette hele projektet?',
                text: 'Sletter projektet, og alt data knyttet til det\n(medmindre det data også er knyttet til et andet projekt)\nProcessen med at slette et helt projekt kan tage flere minutter, og kræver en stabil internetforbindelse, for at undgå at efterlade korrumperet data i databasen.',
                icon: 'warning', //Can be warning, error, succress, info
                dangerMode: true,
                buttons: {
                    cancel: {
                        text: 'Annuller',
                        value: false,
                        visible: true,
                    },
                    confirm: {
                        text: 'Slet projekt',
                        value: true,
                        visible: true,
                    },
                }
            })

            if(!result) {
                EventBus.$emit('function-activity', {functionName: 'Project/Create_deleteButtonClicked', isActive: false})
                return
            }

            try {
                await this.LPCDeleteProject(this.id)
                swal('Projekt slettet', 'Projektet er nu blevet slettet, og siden opdaterer af sig selv indenfor 5 sekunder.\n\n vent venligst...', 'success')
                EventBus.$emit('function-activity', {functionName: 'Project/Create_deleteButtonClicked', isActive: false})
                setTimeout(() => {
                    this.$router.push('/')
                }, 1500)
            }
            catch (error) {
                swal('Fejl i sletning', error.message, 'error')
                EventBus.$emit('function-activity', {functionName: 'Project/Create_deleteButtonClicked', isActive: false})
            }
        },

        addCabinet(){
            this.selectedCabinets.push(this.cabinetInput)
            this.customCabinetOptions.push({text: this.cabinetInput, value: this.cabinetInput})
            this.cabinetInput = ""
        },

        async getCabinetsOnHubs(){
            EventBus.$emit('function-activity', {functionName: 'Project/Create_getCabinetsOnHubs', isActive: true})
            let allUubs = []
            for (let i in this.selectedHubs) {
                let hub = this.selectedHubs[i]
                let uubs = await this.dataGetUubs(`${hub}-`)
                // console.log(`Got Uubs for hub '${hub}': ${JSON.stringify(uubs)}`)
                allUubs = [...allUubs, ...uubs]
                this.uubs = allUubs
            }
            EventBus.$emit('function-activity', {functionName: 'Project/Create_getCabinetsOnHubs', isActive: false})
            // return allUubs
        },

        addTimeWindow(){
            this.projectTimeWindows.push({from: '7', to: '15'})
        },

        formatTimeWindowDropDownName(timeWindowObj) {
            return `${this.formatTimeString(timeWindowObj.from)} - ${this.formatTimeString(timeWindowObj.to)}`
        },
    },

    async mounted() {
        EventBus.$emit('function-activity', {functionName: 'Project/Create_mounted', isActive: true})

        this.isLoading = true

        // Get users from firebase.
        let users = await db.collection('Users').get().then((querySnapshot) => {
            let us = []
            querySnapshot.forEach((doc) => {
                us.push(doc.data())
            })
            return us
        })

        // Ensure users have the `selected` property.
        users.map((user) => {
            Object.assign(user, {
                selected: false
            })
            return user
        })
        this.avilableWorkers = users.sort((a,b) => a.Name > b.Name ? 1 : -1)

        // Get unit work units
        this.$bind('unitWorkCompositeUnits', db.collection('UnitWorkCompositeUnits'))

        // Get contacts from energifyn.
        let contacts = await this.dataGetContactsFromCompany('Energifyn');
        this.$set(this, 'contacts', contacts)

        // Populate fields when in edit mode.
        this.isEditing = !!this.$route.params.id
        if (this.isEditing) {
            await this.populateDataFromProject(this.$route.params.id)
        } else {
            this.taskCompletionUnitworkUnitOptions = this.cloneJson(this.defaultTaskCompletionUnitworkUnitOptions)
        }

        this.isLoading = false
        
        // Get project tasks to get Sonwin ID's
        let projects = await this.dataGetAllProjectTasks()
        this.projects = projects
        this.isLoadingSonWin = false
        EventBus.$emit('function-activity', {functionName: 'Project/Create_mounted', isActive: false})
    },

    watch: {
        selectedHubs: {
            deep: true,
            immediate: true,
            async handler() {
                console.log('change detected in selected hubs')
                await this.getCabinetsOnHubs()
            }
        }
    },
}
</script>
<style scoped>

.color-square {
    height: 45px;
    width: 45px;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    cursor: pointer;
}

.color-square.selectable {
    height: 55px;
    width: 55px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    display: grid;
    margin: 8px 8px;
}

.color-square.selected {
    height: 55px;
    width: 55px;
    box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset !important;
    display: grid;
    margin: 8px 8px;
}

.color-square:hover {
    transform: scale(1.125);
}

.workers tbody {
    display: block;
    max-height: 265px;
    overflow: auto;
}

.workers thead,
.workers tfoot,
.workers tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

table.workers tr td:first-child {
    padding-left: 0.5rem !important;
    width: 4.5rem !important;
}

.ui.compact.table td {
    padding: 0.5em .7em 0em;
}
</style>
