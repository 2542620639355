<template>
    <div>
        <!-- Info -->     
        <sui-accordion id="ai-0" styled fluid v-if="activeInstallationData && !loadingInstallationData">
            <sui-accordion-title :active="getToggle(0)" @click="toggleAccordion(0)">
                <sui-icon name="dropdown"/>
                <strong>Info</strong>
            </sui-accordion-title>
            <sui-accordion-content :active="getToggle(0)" style="padding: 0px;">
                <sui-table striped compact size="small">
                    <sui-table-row v-if="hasTasks">
                        <sui-table-cell>Adresse</sui-table-cell>
                        <sui-table-cell>
                            <span v-if="getConfiguration(activeInstallationData.tasks[0]).address">
                                <i
                                    class="fas fa-map-marker-alt text-primary"
                                    :title="`${formatAddress(getConfiguration(activeInstallationData.tasks[0]).address, false)}\nKlik for at navigere i Google Maps`"
                                    @click.stop="linkToGoogleMapsDirections(activeInstallationData.coordinates, getConfiguration(activeInstallationData.tasks[0]).address)"
                                    style="cursor: pointer;"
                                ></i>
                                {{ formatAddress(getConfiguration(activeInstallationData.tasks[0]).address, false) }}
                            </span>
                        </sui-table-cell>
                    </sui-table-row>
                    <sui-table-row v-if="hasTasks">
                        <sui-table-cell>Inst Nr.</sui-table-cell>
                        <sui-table-cell>{{ getConfiguration(activeInstallationData.tasks[0]).number }} <sui-button primary size="mini" compact @click="goToBroadBandSupplierPortal(getConfigurationLabel(activeInstallationData.tasks[0]))">Bredbåndsportal</sui-button> </sui-table-cell>
                    </sui-table-row>
                    <sui-table-row v-if="activeHub">
                        <sui-table-cell>Accesshus&nbsp;{{this.getHubFromAsrName()}}</sui-table-cell>
                        <sui-table-cell>
                            <i
                                class="fas fa-map-marker-alt text-primary"
                                :title="`${formatAddress(activeHub.Address,false)}\nKlik for at navigere i Google Maps`"
                                @click.stop="linkToGoogleMapsDirections(activeHub.Coordinates, activeHub.Address)"
                                style="cursor: pointer;"
                            ></i>
                            {{formatAddress(this.activeHub.Address,false)}}
                        </sui-table-cell>
                    </sui-table-row>
                    <sui-table-row>
                        <sui-table-cell>
                            Indholdsudbyder<br>
                            {{activeServiceProvider.CustomerSupportHours}}
                        </sui-table-cell>
                        <sui-table-cell>
                            <span v-if="activeServiceProvider.Name">
                                {{activeServiceProvider.Name}}<br>
                                {{activeServiceProvider.CustomerSupportPhone}}
                            </span>
                            <span v-else>
                                {{activeServiceProvider.number}}
                            </span>
                        </sui-table-cell>
                    </sui-table-row>
                    <sui-table-row v-if="hasTasks && getConfiguration(activeInstallationData.tasks[0]).association && getConfiguration(activeInstallationData.tasks[0]).association.name">
                        <sui-table-cell>Forening</sui-table-cell>
                        <sui-table-cell>{{ getConfiguration(activeInstallationData.tasks[0]).association.name }}</sui-table-cell>
                    </sui-table-row>
                    <sui-table-row v-if="firstProductDeliveryDate">
                        <sui-table-cell>Produktopstart</sui-table-cell>
                        <sui-table-cell>
                            <span v-if="loadingProducts" class="loading-text"></span>
                            {{ toUserFriendlyDate(firstProductDeliveryDate) }}
                            <i v-if="formatMachineDate(firstProductDeliveryDate) < formatMachineDate(lastAppointmentDate)" title="Aftalt dato ligger efter dato for produktopstart" class="fas fa-exclamation-triangle color-c7141a"></i>
                        </sui-table-cell>
                    </sui-table-row>
                    <!-- Until and unless we start checking for a specific task code, plannedStart and plannedEnd are useless -->
                    <!-- <sui-table-row>
                        <sui-table-cell>Planlagt Start</sui-table-cell>
                        <sui-table-cell>{{ toUserFriendlyTimestamp(activeInstallationData.tasks[0].plannedStart, false) }}</sui-table-cell>
                    </sui-table-row>
                    <sui-table-row>
                        <sui-table-cell>Planlagt Slut</sui-table-cell>
                        <sui-table-cell>{{ toUserFriendlyTimestamp(activeInstallationData.tasks[0].plannedEnd, false) }}</sui-table-cell>
                    </sui-table-row> -->
                    <sui-table-row v-if="firstConnectionDate">
                        <sui-table-cell>Connection dato</sui-table-cell>
                        <sui-table-cell colspan="2">
                            {{ toUserFriendlyDate(firstConnectionDate) }}
                            <i 
                                v-if="showConnectionDateWarning" 
                                title="Opstartsdato i PilotBI og Aftale dato i FiberTeam matcher ikke" 
                                class="fas fa-exclamation-triangle color-c7141a"
                            ></i>
                            <sui-button
                                v-if="firstConnectionDate"
                                floated="right"
                                negative
                                icon="trash"
                                size="mini"
                                title="Slet Connection Dato"
                                @click="sendEvent('delete-connection-date')"
                            />
                        </sui-table-cell>
                    </sui-table-row>
                    <sui-table-row v-if="activeInstallationData && activeInstallationData.installationFirstSeen">
                        <sui-table-cell>Installation først set</sui-table-cell>
                        <sui-table-cell v-if="activeInstallationData.installationFirstSeen == 'N/A'"> N/A </sui-table-cell>
                        <sui-table-cell v-else>{{ toUserFriendlyTimestamp(activeInstallationData.installationFirstSeen) }}</sui-table-cell>
                    </sui-table-row>
                </sui-table>
            </sui-accordion-content>
        </sui-accordion>
        
        <!-- Projekt-inddeling -->
        <sui-accordion id="ai-1" styled fluid v-if="hasTasks && !loadingInstallationData && hasProjectTasks">
            <sui-accordion-title :active="getToggle(1)" @click="toggleAccordion(1)">
                <sui-icon name="dropdown"/>
                <strong>Projekt-inddeling</strong>
            </sui-accordion-title>
            <sui-accordion-content :active="getToggle(1)" style="padding: 0px;">
                <sui-table striped compact size="small">    
                    <sui-table-row>
                        <sui-table-cell>Type</sui-table-cell>
                        <sui-table-cell><span v-if="activeInstallationData.tasks[0].project && activeInstallationData.tasks[0].project.type">{{activeInstallationData.tasks[0].project.type.label}}</span></sui-table-cell>
                        </sui-table-row>
                    <sui-table-row>
                        <sui-table-cell>Område</sui-table-cell>
                        <sui-table-cell><span v-if="getConfiguration(activeInstallationData.tasks[0]).area">{{getConfiguration(activeInstallationData.tasks[0]).area.sonWinProjectId}} - {{getConfiguration(activeInstallationData.tasks[0]).area.name}}</span></sui-table-cell>
                    </sui-table-row>
                    <sui-table-row>
                        <sui-table-cell>Reference</sui-table-cell>
                        <sui-table-cell><span v-if="activeInstallationData.tasks[0].referenceId">{{activeInstallationData.tasks[0].referenceId}}</span></sui-table-cell>
                    </sui-table-row>
                </sui-table>
            </sui-accordion-content>
        </sui-accordion>

        <!-- Kontaktpersoner -->
        <sui-accordion id="ai-2" styled fluid v-if="activeInstallationData && !loadingInstallationData">   
            <sui-accordion-title :active="getToggle(2) && Boolean(contactsCount)" @click="toggleAccordion(2)">
                <sui-icon name="dropdown"/>
                <strong> {{ `Kontaktpersoner (${contactsCount})`  }}</strong>
            </sui-accordion-title>
            <sui-accordion-content :active="getToggle(2) && Boolean(contactsCount)" style="padding: 0px;">
                <sui-table striped compact size="small">  
                    <sui-table-row v-if="!hasTasks || !activeInstallationData.tasks[0].contacts || !activeInstallationData.tasks[0].contacts.length">
                        <sui-table-cell colspan="2">
                            <em>Ingen kontaktpersoner</em>
                        </sui-table-cell>
                    </sui-table-row>
                    <template v-else>
                        <template v-for="(contact, index) in activeInstallationData.tasks[0].contacts">
                            <sui-table-row :key="`c-${index}`" v-if="contact">
                                <sui-table-cell>{{ contact.name }}</sui-table-cell>
                                <sui-table-cell>
                                    <span v-if="contact.email">{{ contact.email }}<a :href="'mailto:' + contact.email"><sui-icon name="envelope" link/></a><br /></span>
                                    <span v-if="contact.mobile">
                                        {{ formatPhoneNumber(contact.mobile) }}
                                        <a :href="'tel:' + contact.mobile"><sui-icon name="phone" link/></a> 
                                        <i class="fas fa-sms link" v-if="checkIfMobile(contact.mobile)" @click="sendEvent('open-sms-modal',contact.mobile)"></i>
                                        <br /> 
                                    </span>
                                    <span v-if="contact.phone && contact.phone != contact.mobile">
                                        {{ formatPhoneNumber(contact.phone) }}
                                        <a :href="'tel:' + contact.phone"><sui-icon name="phone" link/></a> 
                                        <i class="fas fa-sms link" v-if="checkIfMobile(contact.phone)" @click="sendEvent('open-sms-modal',contact.phone)"></i>
                                    </span>
                                </sui-table-cell>
                            </sui-table-row>
                        </template>
                    </template>
                    <!-- TODO: Func not implemented for TT yet -->
                    <sui-table-row v-if="hasTasks && activeInstallationData.tasks[0].contacts && activeInstallationData.tasks[0].contacts.length">
                        <sui-table-cell></sui-table-cell>
                        <sui-table-cell>
                            <sui-button size="mini" negative @click="sendEvent('open-failed-contact-modal')">
                                Send besked om forgæves kontakt
                            </sui-button>
                        </sui-table-cell>
                    </sui-table-row>
                </sui-table>
            </sui-accordion-content>
        </sui-accordion>

        <!-- Aftaler -->
        <sui-accordion id="ai-3" styled fluid v-if="activeInstallationData && !loadingInstallationData">
            <sui-accordion-title :active="getToggle(3) && Boolean(appointmentsCount)" @click="toggleAccordion(3)">
                <sui-icon name="dropdown" />
                <strong> {{ `Aftaler (${appointmentsCount})`  }}</strong>
            </sui-accordion-title>
            <sui-accordion-content :active="getToggle(3) && Boolean(appointmentsCount)" style="padding: 0px;">
                <sui-button
                    v-if="settings.get('showBtnForTaskData')"
                    floated="right"
                    size="mini"
                    style="padding: 9.87755px; margin-top: -40px; margin-right: 10px;"
                    @click="toggleShowApptId()"
                >{{ showApptIdBtnTest }}</sui-button> 
                <sui-table striped compact size="small">  
                    <sui-table-row v-if="hasTasks && activeInstallationData.tasks[0].noAppointmentFound && appointmentExists(activeInstallationLabel)">
                        <sui-table-cell colspan="2">
                            <div class="ui negative message">
                                Fejl! Aftale blev ikke fundet i lokal database.
                            </div>
                        </sui-table-cell>
                    </sui-table-row>
                    <sui-table-row v-if="!appointmentExists(activeInstallationLabel)">
                        <sui-table-cell colspan="2">
                            <strong>Ingen aftale(r) fundet.</strong>
                        </sui-table-cell>
                    </sui-table-row>
                    <template v-for="(appointment, key) in activeInstallationData.appointments">
                        <sui-table-row :key="`${key}-header`">
                            <sui-table-cell colspan="2">
                                <strong>Aftale</strong> ({{AppointmentType.translate(appointment.AppointmentType)}})
                                <small v-if="settings.get('showBtnForTaskData') && showApptId"><br>{{key}}</small>
                                <sui-button
                                    floated="right"
                                    size="mini"
                                    title="Aflys aftale"
                                    color="red"
                                    style="padding: 9.87755px;"
                                    @click="sendEvent('on-delete-appointment-button-click', {showRemoveAppointmentModal: true, selectedAppointmentId: key})"
                                ><i class="fa-solid fa-trash-can"></i></sui-button>
                                <sui-button
                                    v-if="firebaseUser.Roles.includes(UserRoles.EFB_BYPASS_BUSINESS_RULES)"
                                    floated="right"
                                    size="mini"
                                    title="Opdatér state"
                                    color="red"
                                    style="padding: 9.87755px;"
                                    @click="sendEvent('on-edit-appointment-state-click', {showEditAppointmentStateModal: true, selectedAppointmentId: key})"
                                ><i class="fa-solid fa-floppy-disk-pen"></i></sui-button>
                                <sui-button
                                    floated="right"
                                    size="mini"
                                    title="Forgæves besøg"
                                    color="red"
                                    style="padding: 9.87755px;"
                                    @click="sendEvent('visit-in-vain', {activeInstallationData: activeInstallationData, appointment: appointment, id: key})"
                                ><i class="fa-solid fa-house-person-leave"></i></sui-button>
                                <sui-button
                                    v-if="settings.get('showBtnForTaskData') && appointment.changeLog"
                                    floated="right"
                                    size="mini"
                                    title="Vis redigeringshistorik"
                                    style="padding: 9.87755px;"
                                    @click="sendEvent('on-show-appointment-change-log-history-clicked', {doc: appointment, id: key})"
                                ><i class="fa-solid fa-rectangle-history"></i></sui-button>
                                <sui-button
                                    floated="right"
                                    size="mini"
                                    title="Redigér aftale"
                                    color="blue"
                                    style="padding: 9.87755px;"
                                    @click="sendEvent('on-book-button-click',key)"
                                ><i class="fa-solid fa-pen-to-square"></i></sui-button>     
                            </sui-table-cell>
                        </sui-table-row>
                        <sui-table-row :key="`${key}-callInAdvance`" v-if="appointment.CallInAdvance && appointment.CallInAdvance.call">
                            <sui-table-cell colspan="2">
                                <span><i class="fa-solid fa-phone" style="color: orange"></i></span>
                                {{ appointment.CallInAdvance.noteBody }}
                            </sui-table-cell>
                        </sui-table-row>
                        <sui-table-row :key="`${key}-dateAndTime`">
                            <sui-table-cell>Aftalt tidspunkt</sui-table-cell>
                            <sui-table-cell> 
                                <span v-if="appointment.Date">{{ toUserFriendlyDate(appointment.Date, false) }}</span>
                                <span v-if="appointment.Date && appointment.TimeWindowString"> <i class="fa-solid fa-clock" style="margin-left: 10px; margin-right:3px;"></i> </span>
                                <span v-if="appointment.TimeWindowString">{{shortenTimeString(readTimeWindowString(appointment.TimeWindowString).Time.From)}} - {{shortenTimeString(readTimeWindowString(appointment.TimeWindowString).Time.To)}}</span>
                            </sui-table-cell>
                        </sui-table-row>
                        <sui-table-row :key="`${key}-worker`">
                            <sui-table-cell>Medarbejder</sui-table-cell>
                            <sui-table-cell>
                                <span v-if="appointment.Worker">
                                    {{ appointment.Worker.Name }} ({{String(appointment.Worker.Email).substr( 0 , String(appointment.Worker.Email).indexOf("@") ).toUpperCase()}})
                                </span>
                            </sui-table-cell>
                        </sui-table-row>
                        <sui-table-row :key="`${key}-updatedConfirmed`">
                            <sui-table-cell>Opdateret (varslet)</sui-table-cell>
                            <sui-table-cell>
                                {{toUserFriendlyTimestamp(appointment.Updated, false)}}
                                (
                                <i v-if="appointment.Confirmed" class="fas fa-check" title="Aftalen er markeret som varslet"></i>
                                <i v-if="!appointment.Confirmed" class="fas fa-divide fa-rotate-135" :title="`Aftalen er IKKE markeret som varslet,\neller matcher ikke data i PilotBI`"></i>  
                                )
                            </sui-table-cell>
                        </sui-table-row>
                    </template>
                </sui-table>
            </sui-accordion-content>
        </sui-accordion>

        <!-- Opgaver -->
        <sui-accordion id="ai-4" styled fluid v-if="activeInstallationData && !loadingInstallationData">
            <sui-accordion-title :active="getToggle(4) && Boolean(taskCount)" @click="toggleAccordion(4)">
                <sui-icon name="dropdown" />
                <strong> {{ `Opgaver (${taskCount})`  }}</strong>
            </sui-accordion-title>
            <sui-accordion-content :active="getToggle(4) && Boolean(taskCount)" style="padding: 0px;">
                <sui-button
                    v-if="settings.get('showBtnForTaskData')"
                    floated="right"
                    size="mini"
                    style="padding: 9.87755px; margin-top: -40px; margin-right: 10px;"
                    @click="toggleShowTaskId()"
                >{{ showTaskIdBtnTest }}</sui-button> 
                <sui-table striped compact size="small" style="margin-top: 0px"> 
                    <sui-table-row v-if="!activeInstallationData.tasks.length">
                        <sui-table-cell colspan="2">
                            <em>Ingen opgaver fundet</em>
                        </sui-table-cell>
                    </sui-table-row>

                    <!-- Opgaver - Trouble Tickets -->
                    <template v-for="task in activeInstallationTroubleTicketsSortedCurrent">
                        <sui-table-row :key="task.id+'a'">
                            <sui-table-cell style="line-height: 1">
                                <strong>{{task.shortDescription}}</strong>
                                <small v-if="settings.get('showBtnForTaskData') && showTaskId"><br>{{task.id}}</small>
                                <small v-if="task.number"><br>{{task.number}}</small>
                                <small v-if="task.TaskFirstSeen && settings.get('showTaskFirstSeenInActiveInst')"><br><strong>Først set i FiberTeam</strong> {{ toUserFriendlyTimestamp(task.TaskFirstSeen, false) }}</small>
                                <small v-if="task.state.value == TicketState.ON_HOLD"><br><strong>On Hold Reason</strong> {{ task.state.reason.label }}</small>
                            </sui-table-cell>
                            <sui-table-cell>
                                <sui-button
                                    v-if="settings.get('showBtnForTaskData')"
                                    size="mini"
                                    title="Vis task info"
                                    @click="showTaskModal(task)"
                                    class="square-icon-button"
                                ><i class="fa-solid fa-info"></i></sui-button> 
                                <sui-button 
                                    size="mini" 
                                    :color="getTtStateColor(task.state)" 
                                    @click.stop="sendEvent('status-clicked',task)" 
                                    class="task-button"
                                >{{ task.state.label }}</sui-button>
                            </sui-table-cell>
                        </sui-table-row>
                        <sui-table-row :key="task.id+'b'">
                            <sui-table-cell>
                                Beskrivelse
                            </sui-table-cell>
                            <sui-table-cell>
                                {{task.description}}
                            </sui-table-cell>
                        </sui-table-row>
                        <sui-table-row :key="task.id+'companyName'" v-if="task.company && task.company.name">
                            <sui-table-cell>Afdeling</sui-table-cell>
                            <sui-table-cell>{{task.company.name}}</sui-table-cell>
                        </sui-table-row>
                        <sui-table-row :key="task.id+'created'" v-if="task.createdAt">
                            <sui-table-cell>Oprettet</sui-table-cell>
                            <sui-table-cell>{{ toUserFriendlyTimestamp(task.createdAt, false) }}</sui-table-cell>
                        </sui-table-row>
                        <sui-table-row :key="task.id+'c'" v-if="task.dueDate">
                            <sui-table-cell>Deadline</sui-table-cell>
                            <sui-table-cell>{{ toUserFriendlyDate(task.dueDate) }}</sui-table-cell>
                        </sui-table-row>
                        <sui-table-row :key="task.id+'d'" v-if="task.plannedStart">
                            <sui-table-cell>Planlagt Start</sui-table-cell>
                            <sui-table-cell>{{ toUserFriendlyTimestamp(task.plannedStart, false) }}</sui-table-cell>
                        </sui-table-row>
                        <sui-table-row :key="task.id+'e'" v-if="task.plannedEnd">
                            <sui-table-cell>Planlagt Slut</sui-table-cell>
                            <sui-table-cell>{{ toUserFriendlyTimestamp(task.plannedEnd, false) }}</sui-table-cell>
                        </sui-table-row>
                        <sui-table-row :key="task.id+'f'" v-if="task.priority">
                            <sui-table-cell>Priority</sui-table-cell>
                            <sui-table-cell>
                                <span v-if="task.priority">{{task.priority.label}}</span>
                            </sui-table-cell>
                        </sui-table-row>
                        <sui-table-row :key="task.id+'g'" v-if="task.urgency">
                            <sui-table-cell>Urgency</sui-table-cell>
                            <sui-table-cell>{{task.urgency.label}}</sui-table-cell>
                        </sui-table-row>
                        <sui-table-row :key="task.id+'h'" v-if="task.impact">
                            <sui-table-cell>Impact</sui-table-cell>
                            <sui-table-cell>{{task.impact.label}}</sui-table-cell>
                        </sui-table-row>
                    </template>

                    <!-- Opgaver - Project Tasks -->
                    <sui-table-row v-for="task in activeInstallationProjectTasksSorted" :key="task.id">
                        <sui-table-cell style="line-height: 1">
                            {{task.shortDescription}}
                            <small v-if="settings.get('showBtnForTaskData') && showTaskId"><br>{{task.id}}</small>
                            <small><br>{{task.number}}</small>
                            <small v-if="task.TaskFirstSeen && settings.get('showTaskFirstSeenInActiveInst')"><br><strong>Først set i FiberTeam</strong> {{ toUserFriendlyTimestamp(task.TaskFirstSeen, false) }}</small>
                            <small v-if="task.state.value == TaskState.ON_HOLD"><br><strong>On Hold Reason</strong> {{ task.state.reason.label }}: {{ task.state.reason.subReason.label }}</small>
                            <small v-if="settings.get('showSLAInActiveInst') && task.serviceOrder && task.serviceOrder.deliverySlaDate"><br><strong>SLA Deadline</strong> {{ toUserFriendlyDate(task.serviceOrder.deliverySlaDate) }}</small>
                            <small v-if="settings.get('showSLAInActiveInst') && task.serviceOrder && task.serviceOrder.deliverySlaDate && task.serviceOrder.deliverySlaBreachReason.value"><br><strong>Årsag til brud</strong> {{ task.serviceOrder.deliverySlaBreachReason.label }}</small>
                        </sui-table-cell>
                        <sui-table-cell>
                            <sui-button
                                v-if="settings.get('showBtnForTaskData')"
                                size="mini"
                                title="Vis task info"
                                @click="showTaskModal(task)"
                                class="square-icon-button"
                            ><i class="fa-solid fa-info"></i></sui-button> 
                            <sui-button 
                                size="mini" 
                                class="task-button"
                                :color="getStateColor(task.state)" 
                                @click.stop="sendEvent('status-clicked',task)"
                            >{{ task.state.label }}</sui-button>
                        </sui-table-cell>
                    </sui-table-row>
                </sui-table>
            </sui-accordion-content>
        </sui-accordion>

        <!-- Opgavehistorik -->
        <sui-accordion id="ai-5" styled fluid v-if="activeInstallationData && !loadingInstallationData && Boolean(activeInstallationTroubleTicketsSortedHistory.length)">
            <sui-accordion-title :active="getToggle(5) && Boolean(activeInstallationTroubleTicketsSortedHistory.length)" @click="toggleAccordion(5)">
                <sui-icon name="dropdown" />
                <strong> {{ `Opgavehistorik (${activeInstallationTroubleTicketsSortedHistory.length})`  }}</strong>
            </sui-accordion-title>
            <sui-accordion-content :active="getToggle(5) && Boolean(taskCount)" style="padding: 0px;">
                <sui-button
                    v-if="settings.get('showBtnForTaskData')"
                    floated="right"
                    size="mini"
                    style="padding: 9.87755px; margin-top: -40px; margin-right: 10px;"
                    @click="toggleShowTaskId()"
                >{{ showTaskIdBtnTest }}</sui-button> 
                <sui-table striped compact size="small" style="margin-top: 0px">
                    <!-- Opgaver - Trouble Tickets -->
                    <template v-for="task in activeInstallationTroubleTicketsSortedHistory">
                        <sui-table-row :key="task.id+'a'">
                            <sui-table-cell style="line-height: 1">
                                <strong>{{task.shortDescription}}</strong>
                                <small v-if="settings.get('showBtnForTaskData') && showTaskId"><br>{{task.id}}</small>
                                <small v-if="task.TaskFirstSeen && settings.get('showTaskFirstSeenInActiveInst')"><br><strong>Først set i FiberTeam</strong> {{ toUserFriendlyTimestamp(task.TaskFirstSeen, false) }}</small>
                                <small v-if="task.number"><br>{{task.number}}</small>
                                <small v-if="task.state.value == TicketState.ON_HOLD"><br><strong>On Hold Reason</strong> {{ task.state.reason.label }}</small>
                            </sui-table-cell>
                            <sui-table-cell>
                                <sui-button
                                    v-if="settings.get('showBtnForTaskData')"
                                    size="mini"
                                    title="Vis task info"
                                    @click="showTaskModal(task)"
                                    class="square-icon-button"
                                ><i class="fa-solid fa-info"></i></sui-button> 
                                <sui-button 
                                    :class="firebaseUser.Roles.includes(UserRoles.EFB_BYPASS_BUSINESS_RULES) ? '': 'disabled'" 
                                    size="mini" 
                                    :color="getTtStateColor(task.state)" 
                                    @click.stop="sendEvent('status-clicked',task)" 
                                    class="task-button"
                                >{{ task.state.label }}</sui-button>
                            </sui-table-cell>
                        </sui-table-row>
                        <sui-table-row :key="task.id+'b'">
                            <sui-table-cell>
                                Beskrivelse
                            </sui-table-cell>
                            <sui-table-cell>
                                {{task.description}}
                            </sui-table-cell>
                        </sui-table-row>
                        <sui-table-row :key="task.id+'companyName'" v-if="task.company && task.company.name">
                            <sui-table-cell>Afdeling</sui-table-cell>
                            <sui-table-cell>{{task.company.name}}</sui-table-cell>
                        </sui-table-row>
                        <sui-table-row :key="task.id+'created'" v-if="task.createdAt">
                            <sui-table-cell>Oprettet</sui-table-cell>
                            <sui-table-cell>{{ toUserFriendlyTimestamp(task.createdAt, false) }}</sui-table-cell>
                        </sui-table-row>
                        <sui-table-row :key="task.id+'c'" v-if="task.dueDate">
                            <sui-table-cell>Deadline</sui-table-cell>
                            <sui-table-cell>{{ toUserFriendlyDate(task.dueDate) }}</sui-table-cell>
                        </sui-table-row>
                        <sui-table-row :key="task.id+'d'" v-if="task.plannedStart">
                            <sui-table-cell>Planlagt Start</sui-table-cell>
                            <sui-table-cell>{{ toUserFriendlyTimestamp(task.plannedStart, false) }}</sui-table-cell>
                        </sui-table-row>
                        <sui-table-row :key="task.id+'e'" v-if="task.plannedEnd">
                            <sui-table-cell>Planlagt Slut</sui-table-cell>
                            <sui-table-cell>{{ toUserFriendlyTimestamp(task.plannedEnd, false) }}</sui-table-cell>
                        </sui-table-row>
                        <sui-table-row :key="task.id+'f'" v-if="task.priority">
                            <sui-table-cell>Priority</sui-table-cell>
                            <sui-table-cell>
                                <span v-if="task.priority">{{task.priority.label}}</span>
                            </sui-table-cell>
                        </sui-table-row>
                        <sui-table-row :key="task.id+'g'" v-if="task.urgency">
                            <sui-table-cell>Urgency</sui-table-cell>
                            <sui-table-cell>{{task.urgency.label}}</sui-table-cell>
                        </sui-table-row>
                        <sui-table-row :key="task.id+'h'" v-if="task.impact">
                            <sui-table-cell>Impact</sui-table-cell>
                            <sui-table-cell>{{task.impact.label}}</sui-table-cell>
                        </sui-table-row>
                    </template>
                </sui-table>
            </sui-accordion-content>
        </sui-accordion>

        <!-- Interne delopgaver -->
        <sui-accordion id="ai-6" styled fluid v-if="activeInstallationData && !loadingInstallationData && hasProjectTasks">
            <sui-accordion-title :active="getToggle(6)" @click="toggleAccordion(6)">
                <sui-icon name="dropdown" />
                <strong> Interne Delopgaver </strong>
            </sui-accordion-title>
            <sui-accordion-content :active="getToggle(6)" style="padding: 0px;">
                <sui-table striped compact size="small"> 
                    <sui-table-row v-for="subtask in internalSubTasks[activeInstallationLabel]" :key="subtask.code">
                        <sui-table-cell>
                            <i v-if="subtask.Deferred && subtask.Deferred.Value" class="fas fa-calendar" style="color: #2185d0;" title="Gøres på dagen for installation"></i>
                            <span v-if="subtask.Responsible">
                                <i v-if="specialOccasion == 'christmas' && subtask.Responsible.Email == user.email" class="fa-kit fa-nisse" style="color: #2185d0;" title="Du har ansvar for denne delopgave"></i>
                                <i v-else-if="subtask.Responsible.Email == user.email" class="fas fa-user-hard-hat" style="color: #2185d0;" title="Du har ansvar for denne delopgave"></i>
                                <i v-else class="fad fa-user-slash" style="--fa-primary-color: tomato; --fa-secondary-color: #2185d0; --fa-secondary-opacity: 1;" :title="subtask.Responsible.Name+' har ansvar for denne delopgave'"></i>
                            </span>
                            {{subtask.name}} 
                            <span v-if="subtask.Responsible">({{subtask.Responsible.Initials}})</span>
                        </sui-table-cell>
                        <sui-table-cell>
                            <sui-button v-if="subtask.state" size="mini" :title="subtask.state.isPositive ? subtask.titles.positive : subtask.titles.negative" :color="getSubtaskStateColor(subtask.state)" @click.stop="sendEvent('active-inst-subtask-clicked',subtask)">
                                <span v-if="subtask.state.isPositive"><i class="fas fa-check" :title="subtask.titles.positive"></i></span>
                                <span v-else><i class="fas fa-times" :title="subtask.titles.negative"></i></span> &nbsp;
                                <span>{{subtask.state.text}}</span>
                            </sui-button>
                        </sui-table-cell>
                    </sui-table-row>
                </sui-table>
            </sui-accordion-content>
        </sui-accordion>

        <!-- Teknisk Data -->
        <sui-accordion id="ai-7" styled fluid v-if="activeInstallationData && hasTasks && !loadingInstallationData && ((activeInstallationData.tasks[0] && getConfiguration(activeInstallationData.tasks[0]) && getConfiguration(activeInstallationData.tasks[0]).technicalData) || hasProjectTasks)">
            <sui-accordion-title :active="getToggle(7)" @click="toggleAccordion(7)">
                <sui-icon name="dropdown" />
                <strong> Teknisk Data </strong>
            </sui-accordion-title>
            <sui-accordion-content :active="getToggle(7)" style="padding: 0px;">
                <sui-button
                    floated="right"
                    size="mini"
                    title="Redigér teknisk data"
                    color="blue"
                    style="padding: 9.87755px; margin-top: -40px; margin-right: 10px;"
                    @click="sendEvent('open-edit-tech-data-modal')"
                ><i class="fa-solid fa-pen-to-square"></i></sui-button> 
                <sui-table striped compact size="small" style="margin-top: 0px;"> 
                    <template v-if="activeInstallationData.tasks[0] && getConfiguration(activeInstallationData.tasks[0]) && getConfiguration(activeInstallationData.tasks[0]).technicalData">
                        <sui-table-row>
                            <sui-table-cell>Rack</sui-table-cell>
                            <sui-table-cell>{{ getConfiguration(activeInstallationData.tasks[0]).technicalData.rack }}</sui-table-cell>
                        </sui-table-row>
                        <sui-table-row>
                            <sui-table-cell>ASR Name</sui-table-cell>
                            <sui-table-cell>{{ getConfiguration(activeInstallationData.tasks[0]).technicalData.asrName }}</sui-table-cell>
                        </sui-table-row>
                        <sui-table-row>
                            <sui-table-cell>ASR Port</sui-table-cell>
                            <sui-table-cell>{{ getConfiguration(activeInstallationData.tasks[0]).technicalData.asrPort }}</sui-table-cell>
                        </sui-table-row>
                        <sui-table-row>
                            <sui-table-cell>CATV Port</sui-table-cell>
                            <sui-table-cell>{{ getConfiguration(activeInstallationData.tasks[0]).technicalData.caTVPort }}</sui-table-cell>
                        </sui-table-row>
                        <sui-table-row v-if="getConfiguration(activeInstallationData.tasks[0]).technicalData.signalStrengthData">
                            <sui-table-cell>Signalstyrke Data</sui-table-cell>
                            <sui-table-cell>{{getConfiguration(activeInstallationData.tasks[0]).technicalData.signalStrengthData}}</sui-table-cell>
                        </sui-table-row>
                        <sui-table-row v-if="getConfiguration(activeInstallationData.tasks[0]).technicalData.signalStrengthCaTV">
                            <sui-table-cell>Signalstyrke CATV</sui-table-cell>
                            <sui-table-cell>{{getConfiguration(activeInstallationData.tasks[0]).technicalData.signalStrengthCaTV}}</sui-table-cell>
                        </sui-table-row>
                        <sui-table-row>
                            <sui-table-cell>Port tjekket</sui-table-cell>
                            <sui-table-cell> {{stringToBool(getConfiguration(activeInstallationData.tasks[0]).technicalData.portCheckPerformed) ? 'Ja' : 'Nej'}} </sui-table-cell>
                        </sui-table-row>
                        <sui-table-row v-if="getConfiguration(activeInstallationData.tasks[0]).technicalData.speedTestFiberbox">
                            <sui-table-cell>Hastighedstest</sui-table-cell>
                            <sui-table-cell>{{getConfiguration(activeInstallationData.tasks[0]).technicalData.speedTestFiberbox}}</sui-table-cell>
                        </sui-table-row>
                    </template>
                    <sui-table-row v-else>
                        <sui-table-cell colspan="2">
                            <em>Ingen teknisk data</em>
                        </sui-table-cell>
                    </sui-table-row>
                </sui-table>
            </sui-accordion-content>
        </sui-accordion>

        <!-- Udført Arbejde -->
        <sui-accordion id="ai-8" styled fluid v-if="activeInstallationData && !loadingInstallationData && (activeInstallationUnitWork.length || hasProjectTasks)">
            <sui-accordion-title :active="getToggle(8) && Boolean(unitWorkCount)" @click="toggleAccordion(8)">
                <sui-icon name="dropdown" />
                <strong> {{ `Udført Arbejde (${unitWorkCount})`  }}</strong>
            </sui-accordion-title>
            <sui-accordion-content :active="getToggle(8) && Boolean(unitWorkCount)" style="padding: 0px;">
                <sui-button
                    floated="right"
                    size="mini"
                    title="Registrér ekstra arbejde"
                    color="blue"
                    style="padding: 9.87755px; margin-top: -40px; margin-right: 10px;"
                    @click="sendEvent('add-new-unit-work')"
                >
                    <i class="fa-solid fa-file-invoice-dollar" style="margin-right: 5px;"></i>
                    Registrér
                </sui-button> 
                <sui-table striped compact size="small" style="margin-top: 0px"> 
                    <sui-table-row v-for="unitWork in activeInstallationUnitWork" :key="unitWork.id" class="hover-pointer" @click="sendEvent('unit-work-clicked',unitwork)">
                        <template v-if="unitWork.ConfigurationItem.Label == activeInstallationLabel">
                            <sui-table-cell><i v-if="unitWork.Billed && unitWork.Billed.Bool" class="fas fa-lock" title="Denne postering er markeret som faktureret, og derfor låst for redigering"/> {{unitWork.Unit.Name}}</sui-table-cell>
                            <sui-table-cell>{{unitWork.Unit.Unit != 't' ? unitWork.Amount : decimalToHourString(unitWork.Amount)}} {{unitWork.Unit.Unit}} ({{unitWork.Workers.join("/").toUpperCase().replaceAll("@FIBERLAN.DK","")}} - {{toUserFriendlyDate(unitWork.Date, false)}})</sui-table-cell>
                        </template>
                    </sui-table-row>
                </sui-table>
            </sui-accordion-content>
        </sui-accordion>

        <!-- Produkter -->
        <sui-accordion id="ai-9" styled fluid v-if="activeInstallationData && hasTasks && !loadingInstallationData && !(!loadingProducts && !activeInstallationData.tasks[0].products && !productError)">
            <sui-accordion-title :active="getToggle(9)" @click="toggleAccordion(9)">
                <sui-icon name="dropdown" />
                <strong> Produkter </strong>
            </sui-accordion-title>
            <sui-accordion-content :active="getToggle(9)" style="padding: 0px;">
                <sui-table striped compact size="small"> 
                    <sui-table-row>
                        <sui-table-cell colspan="2">
                            <strong>Produkter</strong>
                        </sui-table-cell>
                    </sui-table-row>
                    <sui-table-row v-if="loadingProducts">
                        <sui-table-cell colspan="2">
                            <TableLoader />
                        </sui-table-cell>
                    </sui-table-row>
                    <sui-table-row v-if="productError && !loadingProducts">
                        <sui-table-cell colspan="2"><em style="color: red">{{ productError }}</em></sui-table-cell>
                    </sui-table-row>
                    <sui-table-row v-if="!loadingProducts && !activeInstallationData.tasks[0].products && !productError">
                        <sui-table-cell colspan="2"><em>Ingen produkter.</em></sui-table-cell>
                    </sui-table-row>
                    <template v-for="(orderLine, index) in activeInstallationData.tasks[0].products">
                        <sui-table-row v-if="productError == null && !loadingProducts && orderLine.product" :key="`p-${index}`">
                            <sui-table-cell><span v-if="orderLine.product.productGroups && orderLine.product.productGroups[0]"></span>{{ orderLine.product.productGroups[0].name }}</sui-table-cell>
                            <sui-table-cell>
                                {{ orderLine.product.description }} <br>
                                {{ toUserFriendlyDate(orderLine.expectedDeliveryDate) }}
                            </sui-table-cell>
                        </sui-table-row>
                    </template>
                    <sui-table-row>
                        <sui-table-cell colspan="2">
                            <sui-button primary size="mini" compact @click="goToBroadBandSupplierPortal(getConfiguration(activeInstallationData.tasks[0]).label)">Se alle produkter på Bredbåndsportalen</sui-button>
                        </sui-table-cell>
                    </sui-table-row>
                </sui-table>
            </sui-accordion-content>
        </sui-accordion>

        <!-- Noter -->
        <sui-accordion id="ai-10" styled fluid v-if="activeInstallationData && !loadingInstallationData">
            <sui-accordion-title v-if="lastTask" :active="getToggle(10) && Boolean(notesCount)" @click="toggleAccordion(10)">
                <sui-icon name="dropdown" />
                <strong> {{ `Noter (${notesCount})`  }}</strong>
            </sui-accordion-title>
            <sui-accordion-content v-if="lastTask" :active="getToggle(10) && Boolean(notesCount)" style="padding: 0px;">
                <sui-button
                    floated="right"
                    size="mini"
                    title="Skriv note"
                    style="padding: 9.87755px; margin-top: -40px; margin-right: 10px;"
                    @click="sendEvent('open-upload-note-modal')"
                ><i class="fas fa-plus"></i></sui-button> 
                <sui-table striped compact size="small" style="margin-top: 0px"> 
                    <sui-table-row v-for="(note, index) in lastTask.notes" :key="`n-${index}`">
                        <sui-table-cell colspan="2">
                            <p>
                                <strong><i v-if="note.createdBy.includes('@fiberlan.dk')" class="fa-solid fa-eye-slash" title="Intern note, kan ikke ses af andre end FiberLAN"></i> {{ note.createdBy.replace('FiberLan','FiberLAN') }}<span v-if="parseNote(note.value).author"> ({{ parseNote(note.value).author }})</span></strong> - 
                                <span>{{ toUserFriendlyTimestamp(note.createdAt) }}</span>
                                <span v-if="parseNote(note.value).origin"><br><small>{{ parseNote(note.value).origin }}</small></span>
                            </p>
                            <p class="note-body">{{ parseNote(note.value).body }}</p>
                        </sui-table-cell>
                    </sui-table-row>
                    <sui-table-row v-if="!lastTask.notes || !lastTask.notes.length">
                        <sui-table-cell colspan="2">
                            <em>Ingen noter</em>
                        </sui-table-cell>
                    </sui-table-row>
                </sui-table>
            </sui-accordion-content>
        </sui-accordion>

        <!-- Filer -->
        <sui-accordion id="ai-11" styled fluid v-if="activeInstallationData && !loadingInstallationData">
            <sui-accordion-title v-if="lastTask" :active="getToggle(11) && Boolean(filesCount)" @click="toggleAccordion(11)">
                <sui-icon name="dropdown"/>
                <strong> {{ `Filer (${filesCount})`  }}</strong>
            </sui-accordion-title>
            <sui-accordion-content v-if="lastTask" :active="getToggle(11) && Boolean(filesCount)" style="padding: 0px;">
                <sui-button
                    floated="right"
                    size="mini"
                    title="Upload fil"
                    style="padding: 9.87755px; margin-top: -40px; margin-right: 10px;"
                    @click="sendEvent('open-file-modal')"
                ><i class="fa-solid fa-arrow-up-from-bracket"></i></sui-button> 
                <sui-table striped compact size="small" style="margin-top: 0px"> 
                    <sui-table-row v-for="(file) in lastTask.attachments" :key="file.id" @click="sendEvent('open-attachment',{task: lastTask, attachment: file})" class="hover-pointer">
                        <sui-table-cell colspan="2">
                            <p>
                                <strong>{{ file.name }}</strong> - 
                                <span>{{ file.type }}</span>
                            </p>
                        </sui-table-cell>
                    </sui-table-row>
                    <sui-table-row v-if="!lastTask.attachments || !lastTask.attachments.length">
                        <sui-table-cell colspan="2">
                            <em>Ingen filer</em>
                        </sui-table-cell>
                    </sui-table-row>
                </sui-table>
            </sui-accordion-content>
        </sui-accordion>

        <!-- Splidseplaner -->
        <sui-accordion id="ai-12" styled fluid v-if="activeInstallationData && !loadingInstallationData">
            <sui-accordion-title :active="getToggle(12)" @click="toggleAccordion(12)">
                <sui-icon name="dropdown" />
                <strong> Splidseplaner </strong>
            </sui-accordion-title>
            <sui-accordion-content :active="getToggle(12)" style="padding: 0px;">
                <sui-table striped compact size="small"> 
                    <sui-table-row 
                        v-for="technical in activeInstallationData.technicals" 
                        :key="technical.identification"
                        @click="sendEvent('open-download-report-modal',technical)"
                        class="hover-pointer"
                    >
                        <sui-table-cell>{{technical.typeSource}}</sui-table-cell>
                        <sui-table-cell>{{technical.identification}}</sui-table-cell>
                    </sui-table-row>
                    <sui-table-row v-if="loadingTechnicals">
                        <sui-table-cell colspan="2">
                            <TableLoader />
                        </sui-table-cell>
                    </sui-table-row>
                    <sui-table-row v-else-if="!hasTasks && (!activeInstallationData.technicals || !activeInstallationData.technicals.length)">
                        <sui-table-cell colspan="2">
                            <em>{{(getConfiguration(activeInstallationData.tasks[0]) && getConfiguration(activeInstallationData.tasks[0]).cabinet && getConfiguration(activeInstallationData.tasks[0]).cabinet.name) ? `Fandt ingen splidseplaner for skab ${getConfiguration(activeInstallationData.tasks[0]).cabinet.name}` : 'Denne installation har ikke et tilknyttet skab'}}</em>
                        </sui-table-cell>
                    </sui-table-row>
                </sui-table>
            </sui-accordion-content>
        </sui-accordion> 

        <show-task-data-modal 
            :isOpen="showTaskDataModal"
            :task="taskDataModalTask"
        />
    </div>
</template>

<script>
import { Mixin } from '../../lib/Mixins/mixin.js'
import { DateMixin } from '../../lib/Mixins/dateMixin'
import { SortingMixin } from '../../lib/Mixins/SortingMixin.js'
import TaskCode from '../../lib/Enums/TaskCode'
import EventBus from '../../EventBus'
import UserRoles from '../../lib/Enums/UserRoles'
import AppointmentType from '../../lib/Enums/AppointmentType'
import TableLoader from '@/components/TableLoader.vue'
import TicketState from '../../lib/Enums/TicketState.js'
import TaskState from '../../lib/Enums/TaskState.js'
import ShowTaskDataModal from '@/components/Project/ShowTaskDataModal.vue'
import { specialOccasionsMixin } from '../../lib/Mixins/specialOccasionsMixin.js'

export default {
    mixins: [Mixin, DateMixin, SortingMixin, specialOccasionsMixin],

    components: {
        TableLoader,
        ShowTaskDataModal,
    },


    enums: {
        AppointmentType,
        TaskCode,
        UserRoles,
        TicketState,
        TaskState,
    },

    props: {
        activeInstallationLabel: String,
        activeInstallationData: Object,
        loadingInstallationData: Boolean,
        appointments: Array,
        activeHub: Object,
        activeServiceProvider: Object,
        loadingProducts: Boolean,
        loadingTechnicals: Boolean,
        firstProductDeliveryDate: String,
        internalSubTasks: Object,
        activeInstallationUnitWork: Array, 
        productError: String,
        firebaseUser: Object,
        settings: Object,
        getHubFromAsrName: {
            type: Function,
            required: true,
        },
    },

    data(){
        return {
            // dataLoading: false,
            // apiResponse: null,
            accordionToggle: [],
            showTaskId: false,
            showTaskIdBtnTest: 'Vis Task ID',
            showApptId: false,
            showApptIdBtnTest: 'Vis Appt ID',
            showTaskDataModal: false,
            taskDataModalTask: {},
        }
    },

    computed: {
        user() {
            return this.$root.$children[0].user
        },

        hasTasks() {
            return this.activeInstallationData && this.activeInstallationData.tasks && this.activeInstallationData.tasks.length;
        },

        contactsCount(){
            if (this.activeInstallationData?.tasks[0]?.contacts)
                return this.activeInstallationData.tasks[0].contacts.length
            return 0
        },

        appointmentsCount(){
            if (this.activeInstallationData?.appointments){
                let count = 0
                for (let i in this.activeInstallationData.appointments){
                    console.log(i)
                    count += 1
                }
                return count
            }
            return 0
        },

        taskCount(){
            return this.activeInstallationProjectTasksSorted.length + this.activeInstallationTroubleTicketsSortedCurrent.length
        },

        unitWorkCount() {
            return this.activeInstallationUnitWork.length
        },

        notesCount() {
            return this.lastTask.notes.length
        },

        filesCount() {
            return this.lastTask.attachments.length
        },

        activeInstallationProjectTasksSorted() {
            let tasksClone = []
            for (let i in this.activeInstallationData.tasks){
                if (this.activeInstallationData.tasks[i].code != TaskCode.TICKET){
                    tasksClone.push(this.cloneJson(this.activeInstallationData.tasks[i]))
                }
            }
            return tasksClone.sort(this.sortProjectTasks)
        },

        activeInstallationTroubleTicketsSorted() {
            let tasksClone = []
            for (let i in this.activeInstallationData.tasks){
                if (this.activeInstallationData.tasks[i].code === TaskCode.TICKET){
                    tasksClone.push(this.cloneJson(this.activeInstallationData.tasks[i]))
                }
            }
            return tasksClone.sort(this.sortTroubleTickets).reverse()
        },

        activeInstallationTroubleTicketsSortedCurrent() {
            let ticketClone = []
            if (!this.activeInstallationTroubleTicketsSorted.length) return ticketClone
            for (let t of this.activeInstallationTroubleTicketsSorted){
                if (t.state.value != TicketState.CLOSED){
                    ticketClone.push(this.cloneJson(t))
                }
            }

            if (ticketClone.length == 0){
                let ticket = this.activeInstallationTroubleTicketsSorted[0]
                ticketClone.push(this.cloneJson(ticket))
            }

            return ticketClone
        },     

        activeInstallationTroubleTicketsSortedHistory() {
            if (!this.activeInstallationTroubleTicketsSortedCurrent.length) return []
            return this.cloneJson(this.activeInstallationTroubleTicketsSorted
                .filter((t) => {
                    return !this.activeInstallationTroubleTicketsSortedCurrent.some(b => {
                        return t.id == b.id
                    })
                })
            )
        },

        hasProjectTasks() {
            return this.activeInstallationProjectTasksSorted.length
        },

        hasTickets() {
            return this.activeInstallationTroubleTicketsSorted.length
        },

        firstConnectionDate() {
            let firstDate = '9999-12-31T23:59:59'
            for (let task of this.activeInstallationData.tasks) {
                if (task.connectionDate && task.connectionDate < firstDate) {
                    firstDate = task.connectionDate
                }
            }
            if (firstDate != '9999-12-31T23:59:59') return firstDate
            return null
        },

        showConnectionDateWarning() {
            if (!this.activeInstallationData?.appointments) return false

            //Make an array of the appointment(s) that should match the ConnectionDate
            const appointmentArray = Object.values(this.activeInstallationData.appointments)
            const connectionAppointmentTypes = [AppointmentType.INSTALLATION, AppointmentType.PATCH]
            const foundConnectionAppointments = appointmentArray.filter((appointment) => connectionAppointmentTypes.includes(appointment.AppointmentType))

            //Reduce the appointment array from above to an array of unique dates
            const appointmentDates = foundConnectionAppointments.reduce((dateArr, app) => {
                let formattedDate = this.formatMachineDate(app.Date, '-')
                if (dateArr.includes(formattedDate)) return dateArr
                return [...dateArr, formattedDate]
            }, [])

            return !(
                appointmentDates.length == 1 && //Comment out this line if any matching appointment is good enough - with this line all connection appointments must match
                appointmentDates.includes(this.formatMachineDate(this.firstConnectionDate, '-')) //The array of unique appointment dates contains the connectionDate
            )
        },

        lastTask() {
            if (!this.activeInstallationData) return null
            let lastTask = null
            let allNotes = []
            let allAttachments = []

            for (let task of this.activeInstallationData.tasks) {
                if (task?.notes){
                    for (let note of task.notes){
                        if (!this.containsNote(allNotes, note)){
                            allNotes.push(note)
                        }
                        // allNotes.push(note);
                    }
                }
                if (task?.attachments){
                    for (let attachment of task.attachments){
                        if (!this.containsAttachtment(allAttachments, attachment)){
                            allAttachments.push(attachment)
                        }
                    }
                }

                if (lastTask == null || task.number > lastTask.number) {
                    lastTask = task
                }
            }
            allNotes = allNotes.sort((a,b) => {
                let timeA = new Date(a.createdAt)
                let timeB = new Date(b.createdAt)
                if (timeA < timeB) return 1
                if (timeA > timeB) return -1
                return 0
            })

            lastTask.notes = allNotes
            lastTask.attachments = allAttachments
            return lastTask
        },

        lastAppointmentDate() {
            if (!this.activeInstallationData || !this.activeInstallationData.appointments) return null
            let lastDate = null
            for (let app of Object.values(this.activeInstallationData.appointments)) {
                if (!lastDate || app.Date > lastDate) {
                    lastDate = app.Date
                }
            }
            return lastDate
        },
    },

    methods: {
        closeTaskDataModal(){
            this.showTaskDataModal = false
            this.taskDataModalTask = {}
        },

        showTaskModal(task){
            this.taskDataModalTask = task
            this.showTaskDataModal = true
        },

        toggleShowTaskId(){
            this.showTaskId = !this.showTaskId

            if (this.showTaskId) this.showTaskIdBtnTest = 'Skjul Task ID'
            else this.showTaskIdBtnTest = 'Vis Task ID'
        },

        toggleShowApptId(){
            this.showApptId = !this.showApptId

            if (this.showApptId) this.showApptIdBtnTest = 'Skjul Aftale ID'
            else this.showApptIdBtnTest = 'Vis Aftale ID'
        },

        sendEvent(eventName, payload = null){
            if (payload === null) EventBus.$emit(eventName)
            EventBus.$emit(eventName, payload)
        },

        appointmentExists(label) {
            return this.appointments.some((a) => {
                return a.InstallationLabel == label
            })
        },

        toggleAccordion(index){
            let accordionIndex = 0
            for (let i in this.$children){if (this.$children[i].$attrs.id == `ai-${index}`) accordionIndex = i}

            setTimeout(() => {
                this.accordionToggle[index] = this.$children[accordionIndex].$children[1].dataActive
                let filePath = `active-inst-component-accordion-toggle-${index}`
                localStorage.setItem(filePath, this.accordionToggle[index])
            }, 100)
            
        },

        getToggle(index){
            return this.accordionToggle[index]
        },

        convertBool(cacheResponse, defaultValue){
            if (cacheResponse){
                if (cacheResponse == "true") return true
                if (cacheResponse == "false") return false
            }
            return defaultValue
        },

        containsNote(allNotes, note){
            for (let i in allNotes){
                if (allNotes[i]?.id && note?.id && allNotes[i]?.id == note?.id) return true
                if (allNotes[i].createdAt == note.createdAt && allNotes[i].createdBy == note.createdBy && this.parseNote(allNotes[i].value).body == this.parseNote(note.value).body) return true
                if (note.createdBy != 'FiberLan' && allNotes[i].createdBy == note.createdBy && this.parseNote(allNotes[i].value).body == this.parseNote(note.value).body) return true
            }
            return false
        },

        containsAttachtment(allAttachments, attachment){
            for (let i in allAttachments){
                if (allAttachments[i].id == attachment.id) return true
                if (allAttachments[i].name == attachment.name && allAttachments[i].sizeBytes == attachment.sizeBytes) return true
            }
            return false
        },
        
    },

    beforeMount() {
        this.accordionToggle.push(this.convertBool(window.localStorage.getItem('active-inst-component-accordion-toggle-0'),true))   // Info
        this.accordionToggle.push(this.convertBool(window.localStorage.getItem('active-inst-component-accordion-toggle-1'),false))  // Projekt-inddeling
        this.accordionToggle.push(this.convertBool(window.localStorage.getItem('active-inst-component-accordion-toggle-2'),false))  // Kontakt Personer
        this.accordionToggle.push(this.convertBool(window.localStorage.getItem('active-inst-component-accordion-toggle-3'),false))  // Aftaler
        this.accordionToggle.push(this.convertBool(window.localStorage.getItem('active-inst-component-accordion-toggle-4'),true))   // Opgaver
        this.accordionToggle.push(this.convertBool(window.localStorage.getItem('active-inst-component-accordion-toggle-5'),false))   // Opgavehistorik
        this.accordionToggle.push(this.convertBool(window.localStorage.getItem('active-inst-component-accordion-toggle-6'),false))  // Interne Delopgaver
        this.accordionToggle.push(this.convertBool(window.localStorage.getItem('active-inst-component-accordion-toggle-7'),false))  // Teknisk Data
        this.accordionToggle.push(this.convertBool(window.localStorage.getItem('active-inst-component-accordion-toggle-8'),false))  // Udført Arbejde
        this.accordionToggle.push(this.convertBool(window.localStorage.getItem('active-inst-component-accordion-toggle-9'),false))  // Produkter
        this.accordionToggle.push(this.convertBool(window.localStorage.getItem('active-inst-component-accordion-toggle-10'),true))   // Noter 
        this.accordionToggle.push(this.convertBool(window.localStorage.getItem('active-inst-component-accordion-toggle-11'),true))  // Filer
        this.accordionToggle.push(this.convertBool(window.localStorage.getItem('active-inst-component-accordion-toggle-12'),false)) // Splidseplaner

        EventBus.$on('show-task-data-modal-close', () => {this.closeTaskDataModal()})
    },
}
</script>

<style scoped>
@import '@/assets/styles/buttons.css';

</style>