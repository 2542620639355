<template>
    <div>
        <div class="ui negative message">
            <div class="header">
                Warning!
            </div>
            <p>
                Gets all tasks from PilotBI, and updates cache with their data
            </p>
        </div>
        <hr />

        <sui-button primary size="tiny" :loading="isRunning" :disabled="isRunning" @click="triggerDry">Run Dry</sui-button>
        <sui-button negative size="tiny" :loading="isRunning" :disabled="isRunning" @click="triggerNormal">Run</sui-button>
    </div>
</template>
<script>
import { db } from '@/firebase.js'
import PilotBi from '@/lib/DataProviders/Pilotbi.js'
const APIInstance = new PilotBi();

APIInstance.BaseURL = "https://dispatch.pilotbi.dk/api"
APIInstance.SupplierID = "fb3c06781b9420103f16c656464bcbe0"
APIInstance.APIKey = "1c8d9f3c-8ff3-4eda-8d34-160865901cfb"

export default {
    name: 'SaveAllTasksToCache',

    data() {
        return {
            isRunning: false,
            dryRun: false,
        }
    },

    methods: {
        log(message) {
            this.$emit('log', message);
        },

        async triggerDry() {
            this.dryRun = true;
            await this.trigger();
        },

        async triggerNormal() {
            this.dryRun = false;
            await this.trigger();
        },

        async trigger() {
            this.isRunning = true;
            this.log(`----------------`);
            this.log(`Starting process`);

            if (this.dryRun) {
                this.log(`Dry mode - No updates will actually occur.`);
            }
            this.log('----------------');

            this.log('Getting all tasks');
            const allTasks = await APIInstance.getProjectTasksV2All()
            this.log(`Got ${allTasks.length} tasks`);

            this.log('Looping through tasks');
            let updateCount = 0;
            let failedCount = 0;
            for (let task of allTasks){
                let fullTask = null;
                try {
                    fullTask = await APIInstance.getProjectTask(task.id);
                } catch (error) {
                    failedCount++;
                    this.log(`Error getting task: ${task.id} ; ERROR: ${error}`);
                    continue;
                }
                if (!this.dryRun) {
                    try {
                        await db.collection('cacheData/pilotBiTasksAndTickets/tasks').doc(task.id).update(fullTask);
                        this.log(`Task updated: ${fullTask.id}`);
                        updateCount++;
                    } catch (error) {
                        this.log(`Error updating task, will try setting: ${task.id} ; ERROR: ${error}`);
                        try {
                            await db.collection('cacheData/pilotBiTasksAndTickets/tasks').doc(fullTask.id).set(fullTask);
                        } catch (error) {
                            this.log(`Error setting task: ${task.id} ; ERROR: ${error}`);
                        }
                    } 
                } else {
                    this.log(`Dry run - Task would be updated: ${task.id}`);
                    updateCount++;
                }
            }
            this.log(`Updated ${updateCount} tasks, ${failedCount} failed`);

            this.log('----------------');

            this.log(`Finished process`);

            this.isRunning = false
        }
    }
}
</script>
<style scoped>
.float.right {
    float: right;
}
</style>