<template>
    <div>
    <nav class="main-header navbar navbar-expand navbar-white navbar-light">
        <!-- Left navbar links -->
        <ul class="navbar-nav">
            <li class="nav-item">
                <a class="nav-link sidebar-menu-button" data-widget="pushmenu" @click.stop="toggleSideBar" role="button"><i class="fas fa-bars"></i></a>
            </li>
            <li class="nav-item d-none d-sm-inline-block" v-if="isUser && showMenuContent">
                <router-link
                to="/projects"
                active-class="active"
                class="nav-link"
                >
                Projekter
                </router-link>
            </li>
            <li class="nav-item d-none d-sm-inline-block" v-if="isUser && showMenuContent">
                <router-link
                to="/hubs"
                active-class="active"
                class="nav-link"
                >
                    Accesshuse
                </router-link>
            </li>
            <li class="nav-item d-none d-sm-inline-block" v-if="isUser && showMenuContent">
                <router-link
                to="/installationCheck"
                active-class="active"
                class="nav-link"
                >
                    Installationsopslag
                </router-link>
            </li>
            <li class="nav-item d-none d-sm-inline-block" v-if="isUser && showMenuContent">
                <router-link
                to="/files"
                active-class="active"
                class="nav-link"
                >
                    Filer
                </router-link>
            </li>
            <li class="nav-item d-none d-sm-inline-block" v-if="showMenuContent">
                <router-link
                to="/hourcheck"
                active-class="active"
                class="nav-link"
                >
                Løntimer
                </router-link>
            </li>

            <li class="nav-item d-none d-sm-inline-block dropdown" v-if="isUser && showMenuContent">
                <a class="nav-link" data-toggle="dropdown" @click.stop="toggleMiscMenu">
                    Diverse
                </a>
                <div :class="`dropdown-menu dropdown-menu-right ${ isMiscMenuOpen ? 'show' : '' }`">
                    <h6 class="dropdown-header">Diverse Menu</h6>
                    <router-link
                        to="/signaturforklaring"
                        active-class="active"
                        class="nav-link"
                    >
                        Signaturforklaring
                    </router-link>
                    <router-link
                        to="/sygdom"
                        active-class="active"
                        class="nav-link"
                    >
                        Sygdoms-statistik
                    </router-link>
                    <router-link
                        to="/pdfgenerator"
                        active-class="active"
                        class="nav-link"
                    >
                        PDF Generator
                    </router-link>
                    <a class="nav-link" href="https://app.virkplan.com/home/dashboard">
                        Pilot-BI
                    </a>
                    <router-link
                        to="/addresssearch"
                        active-class="active"
                        class="nav-link"
                    >
                        DAR Søgning
                    </router-link>
                    <router-link
                        to="/logs"
                        active-class="active"
                        class="nav-link"
                    >
                        Log Filer
                    </router-link>
                </div>
            </li>
        </ul>
        
        <ul class="navbar-nav ml-auto">
            <li class="nav-item top-menu-loader">
                <sui-popup>
                    <div slot="trigger">
                        <i v-if="currentlyRunningFunctions.length && specialOccasion == 'christmas'" class="fa-duotone fa-solid fa-hat-santa fa-spin fa-spin-reverse fa-2xl" style="--fa-primary-color: #ff0000; --fa-primary-opacity: 0.9; --fa-secondary-color: #cccccc; --fa-secondary-opacity: 0.9;" @click="logRunningFunctions()"></i>
                        <scotty-beam-me-up size="fa-2x" v-else-if="currentlyRunningFunctions.length && specialOccasion == 'star-trek-day'"/>
                        <i v-else-if="currentlyRunningFunctions.length" class="fa-light fa-face-thinking fa-spin fa-2xl" style="color: black;" @click="logRunningFunctions()"></i>
                    </div>
                    <sui-popup-content>
                        <sui-table compact>
                            <sui-table-header>
                                <sui-table-row>
                                    <sui-table-header-cell>Func</sui-table-header-cell>
                                    <sui-table-header-cell>#</sui-table-header-cell>
                                </sui-table-row>
                            </sui-table-header>
                            <sui-table-body>
                                <sui-table-row v-for="runningFunc of currentlyRunningFunctions" :key="runningFunc.key">
                                    <sui-table-cell>{{ runningFunc.key }}</sui-table-cell>
                                    <sui-table-cell>{{ runningFunc.activeCount }}</sui-table-cell>
                                </sui-table-row>
                            </sui-table-body>
                        </sui-table>
                    </sui-popup-content>
                </sui-popup>
            </li>
            <li class="nav-item" v-if="!hideReloadButton">
                <a class="nav-link" @click="reload()" data-widget="control-sidebar" data-slide="true" href="#" role="button">
                    <i class="fa-solid fa-rotate-right"></i>
                </a>
            </li>

            <li class="nav-item top-menu-search">
                <portal-target v-if="!compressSearchBar" name="top-search"></portal-target>
            </li>
            <portal to="top-search">
                <sui-input v-if="showSearch"
                    @input="onSearch"
                    v-model="searchInput"
                    icon="search"
                    placeholder="Søg..."
                />
            </portal>

            <portal to="top-search-mobile">
                <sui-popup position="bottom center">
                    <sui-grid centered divided :columns="1">
                        <sui-input
                            @input="onSearch"
                            v-model="searchInput"
                            icon="search"
                            placeholder="Søg..."
                        />
                    </sui-grid>
                    <sui-button 
                        slot="trigger"
                        icon="search"
                    />
                </sui-popup>
            </portal>

            <li v-if="compressSearchBar && showSearch" class="nav-item">
                <a class="nav-link" @click="toggleSearhBar()" data-widget="control-sidebar" data-slide="true" href="#" role="button">
                    <i class="fa-solid fa-magnifying-glass"></i>
                </a>
            </li>

            <!-- Notifications Dropdown Menu -->
            <div :title="`Du kører en ${this.appEnv} version af FiberTeam`" :class="this.classes(
                    'dev-label',
                    'nav-item',
                    this.appEnv == 'staging' && 'staging',
                    this.appEnv == 'beta' && 'beta',
                    this.appEnv == 'production' && 'production'
                )" @click="appEvnBtnClicked()">
                {{ appEnvText(this.appEnv) }}
            </div>
            <li class="nav-item dropdown" v-if="isAdmin">
                <a class="nav-link" data-toggle="dropdown" @click.stop="toggleAdminMenu">
                    Admin
                </a>
                <div :class="`dropdown-menu dropdown-menu-right ${ isAdminMenuOpen ? 'show' : '' }`">
                    <h6 class="dropdown-header">Admin Menu</h6>
                    <router-link
                        v-if="isSuperAdmin"
                        to="/admin/migrations"
                        active-class="active"
                        class="dropdown-item"
                    >
                        Migrations
                    </router-link>
                    <router-link
                        v-if="isUserAdmin"
                        to="/admin/workers"
                        active-class="active"
                        class="dropdown-item"
                    >
                        Brugere
                    </router-link>
                    <router-link
                        v-if="isEFBUnitworkAdmin"
                        to="/admin/UnitWorkUnits"
                        active-class="active"
                        class="dropdown-item"
                    >
                        Arbejds-enheder
                    </router-link>
                    <!-- <div class="dropdown-divider"></div>
                    <a href="#" class="dropdown-item">
                    <i class="fas fa-users mr-2"></i> 8 friend requests
                    <span class="float-right text-muted text-sm">12 hours</span>
                    </a>
                    <div class="dropdown-divider"></div>
                    <a href="#" class="dropdown-item">
                    <i class="fas fa-file mr-2"></i> 3 new reports
                    <span class="float-right text-muted text-sm">2 days</span>
                    </a>
                    <div class="dropdown-divider"></div>
                    <a href="#" class="dropdown-item dropdown-footer">See All Notifications</a>
                    -->
                    <div
                        v-if="isSuperAdmin"
                        @click="toggleIgnoreLeadPermissions"
                        class="dropdown-item hover"
                    >
                        <i :class="ignoreLeadPermissions ? 'far fa-check-circle' : 'far fa-circle'"></i>
                        Ignorér leder-roller
                    </div>
                    <div
                        v-if="isSuperAdmin"
                        @click="migrateDataToThisProject()"
                        class="dropdown-item hover"
                    >
                        Migrér data til dette projekt
                    </div>
                </div>
            </li>
            <li class="nav-item">
                <a class="nav-link" @click="logout()" data-widget="control-sidebar" data-slide="true" href="#" role="button">
                    <i class="fas fa-sign-out-alt"></i>
                </a>
            </li>
        </ul>
    </nav>
    <div>
        <sui-container v-if="isSearchBarOpen" attached="top">
            <portal-target name="top-search"></portal-target>
        </sui-container>
  </div>
</div>
<!-- /.navbar -->

</template>
<script>
// import { emit } from 'process'
import EventBus from '../../EventBus'
import UserRoles from '../../lib/Enums/UserRoles'
import { Mixin } from '../../lib/Mixins/mixin'
import { specialOccasionsMixin } from '../../lib/Mixins/specialOccasionsMixin';
import ScottyBeamMeUp from './ScottyBeamMeUp.vue';

export default {
    components: { ScottyBeamMeUp },
    name: 'TopMenu',
    mixins: [Mixin, specialOccasionsMixin],

    enums: {
        UserRoles
    },

    props: ['media'],

    data() {
        return {
            appEnv: null,
            isAdminMenuOpen: false,
            isMiscMenuOpen: false,
            isSearchBarOpen: false,
            ignoreLeadPermissions: window.localStorage.getItem('admin-ignore-lead-permissions') == 'true',
            searchInput: null,
        }
    },

    computed: {
        isSuperAdmin() {
            try {
                return this.$store.state.userProfile.Roles.includes(UserRoles.SUPER_ADMIN)
            } catch {
                return false
            }
        },

        isUserAdmin() {
            try {
                return this.$store.state.userProfile.Roles.includes(UserRoles.USER_ADMIN)
            } catch {
                return false
            }
        },

        isEFBUnitworkAdmin() {
            try {
                return this.$store.state.userProfile.Roles.includes(UserRoles.EFB_UNITWORK_ADMIN)
            } catch {
                return false
            }
        },


        isAdmin() {
            try {
                return this.isSuperAdmin || this.isUserAdmin
            }
            catch {
                return false
            }
        },

        isUser() {
            return this.userProfile && this.userProfile.Roles && this.userProfile.Roles.includes(UserRoles.USER)
        },

        anyFunctionIsRunning() {
            return this.$parent.anyFunctionIsRunning
        },

        showSearch() {
            return (
                this.$route.name == 'ProjectBooking' ||
                this.$route.name == 'HubsListView' ||
                this.$route.name == 'AdminWorkers'
            )
        },

        userProfile() {
            return this.$parent.firebaseUser
        },

        hideReloadButton(){ 
            if (this.media.type == 'Desktop') return true
            return false
        },

        compressSearchBar(){
            if (this.media.type == 'Desktop') return false
            if (this.media.type == 'Tablet') return false
            return true
        },

        showMenuContent(){
            if (this.media.type == 'Desktop') return true
            // if (this.media.type == 'Tablet' && this.media.orientation == 'Landscape') return true
            return false
        },

        currentlyRunningFunctions(){
            let arr = []
            // const now = performance.now()
            for (let funcKey of Object.keys(this.$parent.runningFunctions)) {
                if (!this.$parent.runningFunctions[funcKey].isActive) continue;
                arr.push({
                    key: funcKey,
                    activeCount: this.$parent.runningFunctions[funcKey].isActive,
                    // runtime: Math.round((now - Math.min(this.$parent.runningFunctions[funcKey].activated))/1000)
                })
            }
            // if (arr.length) console.log(arr)
            return arr
        }
    },

    beforeMount() {
        this.appEnv = process.env.VUE_APP_ENV

        EventBus.$on('admin-ignore-lead-permissions', (value) => {this.ignoreLeadPermissions = value})
    },

    mounted() {
        EventBus.$on('close-bs-dropdown', () => {
            this.isAdminMenuOpen = false
            this.isMiscMenuOpen = false
        })

        document.body.classList.remove('hold-transition')
        
        if (this.media.type != 'Desktop') {
            document.body.classList.add('sidebar-collapse')
            document.body.classList.add('sidebar-closed')
        }
    },

    methods: {
        onSearch(payload) {
            EventBus.$emit('top-search-type', payload)
        },

        appEvnBtnClicked(){
            if (this.appEnv == 'production'){ // GOTO Beta
                let link = `https://beta.ftth.fiberlan.dk${this.$route.path}`
                EventBus.$emit('link-to-external-url',link)
            }
            if (this.appEnv == 'beta'){ // GOTO Production
                let link = `https://ftth.fiberlan.dk${this.$route.path}`
                EventBus.$emit('link-to-external-url',link)
            }
            
        },

        logout() {
            this.$store.dispatch('logout', {redirect: '/login?logOutReason=du%20selv%20loggede%20ud.'})
        },

        reload() {
            location.reload()
        },

        toggleSideBar() {
            this.$emit('toggleSideBar')
        },

        logRunningFunctions() {
            this.$emit('logRunningFunctions')
        },

        toggleAdminMenu() {
            this.isAdminMenuOpen = !this.isAdminMenuOpen
        },

        toggleMiscMenu() {
            this.isMiscMenuOpen = !this.isMiscMenuOpen
        },

        appEnvText(appEnv){
            if (appEnv == 'production') return String('P')
            if (appEnv  == 'development') return String('DEV')
            if (appEnv == 'beta') return String('β')
            return String(this.appEnv).toUpperCase()
        },

        toggleSearhBar() {
            this.isSearchBarOpen = !this.isSearchBarOpen
            if (!this.isSearchBarOpen){
                EventBus.$emit('top-search-set', null)
                EventBus.$emit('top-search-type', null)
            } 
        },

        toggleIgnoreLeadPermissions() {
            this.ignoreLeadPermissions = !this.ignoreLeadPermissions
            window.localStorage.setItem('admin-ignore-lead-permissions', this.ignoreLeadPermissions)
            EventBus.$emit('admin-ignore-lead-permissions', this.ignoreLeadPermissions)
        },

        migrateDataToThisProject() {
            EventBus.$emit('data-migration-start-migration')
        },
    }
}
</script>
<style scoped>
a.nav-link {
    padding-top: 9px;
    cursor: pointer;
}

.dev-label {
    color: #fff;
    background: #15b54f;
    padding: 9px 18px 7px;
    border-radius: 7px;
    margin-left: 10px !important;
}

.dev-label.staging {
    background: #f32d2d;
}
.dev-label.beta {
    cursor: pointer;
    background: orange;
}

.dev-label.production {
    cursor: pointer;
    background: #E0E1E2;
    color:#676768;
}

</style>
<style>
.top-menu-search .prompt {
    border-radius: .28571429rem !important;
}

.top-menu-loader {
    padding: 7px;
}

.dropdown-menu-center {
    left: 50%!important;
    right: auto!important;
    text-align: center!important;
    transform: translate(-50%, 0)!important;
}

.sidebar-menu-button {
    cursor: pointer;
}

.hover:hover {
    cursor: pointer;
}
</style>
