// import swal from 'sweetalert'
import PilotBi from './DataProviders/Pilotbi'
import Firebase from './DataProviders/Firebase'
import Google from './DataProviders/Google'
import Dawa from './DataProviders/Dawa'
import { Mixin } from './Mixins/mixin'
import { DateMixin } from './Mixins/dateMixin'
import { unitWorkMixin } from './unitWorkMixin'
import { CoreMixin } from './core/coreMixin'
import { Mime } from './helpers/mime.js'
import TaskCode from './Enums/TaskCode'
import TaskState from './Enums/TaskState'
import TicketState from './Enums/TicketState'
// import AppointmentType from './Enums/AppointmentType'
import EventBus from '../EventBus'
import swal from 'sweetalert'
import ProjectType from './Enums/ProjectType'
import { db } from '../firebase'
import AppointmentType from './Enums/AppointmentType'

import SingletonRunner from './core/singletonRunner.js'

import { cloudFunctions } from '@/firebase.js'
import { mapGetters } from 'vuex'
// import { filterAllTasks } from '@/modules/GetInstallationsFromPilotBi.js'

const FirebaseInstance = new Firebase();
const APIInstance = new PilotBi();
const GoogleAPIInstance = new Google();
const DawaAPIInstance = new Dawa();
const runnerPilotBi = new SingletonRunner();
const runnerAdobe = new SingletonRunner();

export const DataAPI = {
    mixins: [Mixin, DateMixin, Mime, unitWorkMixin, CoreMixin],
    
    /*
     * Naming convention: All mehods should be prefixed with `data` follow
     * by a `get/set` like name to indicate in the vue compontent that it 
     * getting/setting data from any of the external sources.
     */
    data() {
        return {
            productResponses: null,
            gettingProductData: {},
        }
    },

    async mounted() {
        FirebaseInstance.THISROOT = this.$root //Ensure the root is available in Firebase.js
        APIInstance.THISROOT = this.$root //Ensure the root is available in PilotBi.js

        await runnerPilotBi.runOnce(this.loadPilotBiApiMetaData);
        await runnerAdobe.runOnce(this.loadAdobeApiMetaData);
    },

    computed: {
        ...mapGetters({
            useApiV3: 'useAPiV3',
        }),
    },

    methods: {

        loadPilotBiApiMetaData() {
            EventBus.$emit('function-activity', {functionName: 'DataAPI_loadPilotBiApiMetaData', isActive: true})
            console.log('Getting PilotBI API access data')

            this.retryOperation(this.dataGetPilotBiApiMetaData, 5, 500)
            .then(apiData => {
                APIInstance.BaseURL = apiData.BaseURL
                APIInstance.SupplierID = apiData.SupplierId
                APIInstance.APIKey = apiData.Key
                APIInstance.APIOnline = apiData.Online

                this.$store.commit('setUseCache', apiData.UseCache)
                this.$store.commit('setSaveTasksInCache', apiData.SaveTasksInCache)
                this.$store.commit('setUseAPiV3', apiData?.ApiVersion == 'v3' || false)

                if (apiData.Environment && apiData.Environment == 'dev') {
                    APIInstance.BaseURL = apiData.devUrl
                    APIInstance.SupplierID = apiData.devSupplierId
                    APIInstance.APIKey = apiData.devKey
                }
                if (!APIInstance.APIOnline) {
                    swal('API nedetid', 'Pilotbi API er i øjeblikket nede. Du kører i offline mode.\n Energi Fyn er ved at skifte til Virkplan Projects.\n\n Du kan se historiske data, men ikke foretage ændringer!', 'error')
                }

                APIInstance.AxiosConfig.headers['x-api-key'] = APIInstance.APIKey

                if (this.useApiV3 && apiData?.Environment == 'dev') {
                    let testPromises = [];

                    // testPromises.push(APIInstance.getProjectTasksV3({installationLabel: '70401339', updatedSince: new Date(0).toISOString()}).then((tasks) => {
                    //     console.log('Got tasks from V3:', tasks)
                    //     return tasks
                    // }).catch((error) => {
                    //     console.error('Error getting tasks from V3:', error)
                    //     throw error
                    // }));

                    // testPromises.push(APIInstance.getTroubleTicketsV3({installationLabel: '70233640', includeContacts: true}).then((tickets) => {
                    //     console.log('Got tickets from V3:', tickets)
                    //     return tickets
                    // }).catch((error) => {
                    //     console.error('Error getting tickets from V3:', error)
                    //     throw error
                    // }));

                    // testPromises.push(APIInstance.getProjectTasksV3({installationLabel: '70160811'}).then((tasks) => {
                    //     console.log('Got tasks for 70160811:', tasks)
                    //     return APIInstance.getProjectTaskAttachments(tasks[0].id).then((attachments) => {
                    //         console.log('Got attachments for task:', attachments)
                    //         if (!attachments.length){
                    //             return attachments
                    //         }
                    //         return APIInstance.getProjectTaskAttachment(tasks[0].id, attachments[0]).then((attachment) => {
                    //             console.log('Got attachment:', attachment)
                    //             return attachment
                    //         }).catch((error) => {
                    //             console.error('Error getting attachment for task:', error)
                    //             throw error
                    //         })
                    //     }).catch((error) => {
                    //         console.error('Error getting attachments for task:', error)
                    //         throw error
                    //     })
                    // }).catch((error) => {
                    //     console.error('Error getting tasks from V3:', error)
                    //     throw error
                    // }));

                    // testPromises.push(APIInstance.updateProjectTaskV3('e6e5cbbc-35e4-4a76-aceb-8ba77bba5c3c', {state: '1', assignee: 'Marcus Grove'}).then((response) => {
                    //     console.log('Updated task:', response)
                    //     return response
                    // }).catch((error) => {
                    //     console.error('Error updating task:', error)
                    //     throw error
                    // }));

                    Promise.all(testPromises).then(() => {
                        console.log('All tests from V3 succeeded')
                    }).catch((error) => {
                        console.error('Error in promise all:', error)
                    });
                }
                
                // Emit event to indicate that the API data has been loaded and the app is ready to use the API
                EventBus.$emit('api-meta-data-loaded', true) 
            })
            .catch(error => {
                console.error('Operation failed:', error.message);
                swal('Fejl', 'Kunne ikke indlæse PilotBI API meta data', 'error')
            });
            
            EventBus.$emit('function-activity', {functionName: 'DataAPI_loadPilotBiApiMetaData', isActive: false})
        },

        loadAdobeApiMetaData() {
            EventBus.$emit('function-activity', {functionName: 'DataAPI_loadAdobeApiMetaData', isActive: true})
            console.log('Getting Adobe API access data')

            this.retryOperation(this.dataGetAdobeMetaData, 5, 500)
            .then(apiData => {
                this.$store.commit('setAdobeClientId', apiData.AdobeClientId)
            })
            .catch(error => {
                console.error('Operation failed:', error.message);
                swal('Fejl', 'Kunne ikke indlæse Adobe API meta data', 'error')
            });
            
            EventBus.$emit('function-activity', {functionName: 'DataAPI_loadAdobeApiMetaData', isActive: false})
        },

        dataGetPilotBiApiMetaData(){
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetPilotBiApiMetaData', isActive: true})
            return new Promise((resolve, reject) => {
                // Get the API metadata from Firebase
                FirebaseInstance.getPilotBiApiData()
                    .then((apiData) => {
                        if (!apiData) {
                            // Emit event and reject the promise if data is not available
                            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetPilotBiApiMetaData', isActive: false})
                            reject(new Error('Could not get PilotBI API keys from firebase'));
                        } else {
                            // Resolve the promise with the API data if successful
                            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetPilotBiApiMetaData', isActive: false})
                            resolve(apiData);
                        }
                    })
                    .catch((error) => {
                        // Emit event and reject the promise if there was an error during the API call
                        EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetPilotBiApiMetaData', isActive: false})
                        reject(new Error(`Error fetching API metadata: ${error.message}`));
                    });
            });
        },

        async dataGetAdobeMetaData(){
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetAdobeMetaData', isActive: true})
            return new Promise((resolve, reject) => {
                // Get the API metadata from Firebase
                FirebaseInstance.getAdobeApiData()
                    .then((apiData) => {
                        if (!apiData) {
                            // Emit event and reject the promise if data is not available
                            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetAdobeMetaData', isActive: false})
                            reject(new Error('Could not get Adobe API keys from firebase'));
                        } else {
                            // Resolve the promise with the API data if successful
                            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetAdobeMetaData', isActive: false})
                            resolve(apiData);
                        }
                    })
                    .catch((error) => {
                        // Emit event and reject the promise if there was an error during the API call
                        EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetAdobeMetaData', isActive: false})
                        reject(new Error(`Error fetching Adobe metadata: ${error.message}`));
                    });
                });
        },

        dataPilotBiIsOnline(){
            return APIInstance.APIOnline;
        },

        async dataGetAllProjects() {
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetAllProjects', isActive: true})
            let projects = await FirebaseInstance.getAllProjects()
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetAllProjects', isActive: false})
            return projects
        },
        
        async dataGetProjectById(projectId) {
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetProjectById', isActive: true})
            let project = await FirebaseInstance.getProjectById(projectId)
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetProjectById', isActive: false})
            return project
        },

        async dataGetProjectByAreaCode(areaCode) {
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetProjectByAreaCode', isActive: true})
            let project = await FirebaseInstance.getProjectByAreaCode(areaCode)
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetProjectByAreaCode', isActive: false})
            return project
        },

        queryAndSeperator(stringLength){
            if (stringLength) {
                return '^'
            }
            return ''
        },

        dataConstructQueryString(
            areaCodes = [], 
            projectTypes = [], 
            allowedStates = [], 
            disallowedStates = [], 
            allowedCodes = [], 
            disallowedCodes = [], 
            mustHaveConnectionDate, 
            installationLabelWhitelist = [], 
            installationLabelBlacklist = [],
            allowedReferenceIds = [],
            disallowedReferenceIds = [],
        ){
            // console.log('constructing query string')
            let queryString = ''

            // console.log(areaCodes, areaCodes?.length, areaCodes?.length > 1, !!areaCodes?.length)

            if (areaCodes?.length > 1) { //If there are multiple area codes, use SQL operator IN to get them all
                queryString += this.queryAndSeperator(queryString.length)
                queryString += `x_enfy_bb_trt_installation_area.project_idIN(,${areaCodes.join(',')},)` //Ending on ',' to avoid parsing as decimal
            } else if (areaCodes?.length) { //If there is just one area code, use SQL = to get just that one
                queryString += this.queryAndSeperator(queryString.length)
                queryString += `x_enfy_bb_trt_installation_area.project_id=${areaCodes[0]}`
            } //If no area codes are included, don't filter on area code
            // console.log(`Query string after areaCodes considered: ${queryString}`)

            //API has a bug where '.0' is sometimes added to projectType code (probably formatted as float, and back to string), this circumvents that bug:
            if (projectTypes?.length) {
                for (let type of [...projectTypes]) {
                    projectTypes.push(`${type}.0`)
                }
            }

            if (projectTypes?.length > 1) { //If there are multiple project types, use SQL operator IN to get them all
                queryString += this.queryAndSeperator(queryString.length)
                queryString += `sub_tree_root.ref_pm_project.x_enfy_bb_trt_project_typeIN(,${projectTypes.join(',')},)` //Ending on ',' to avoid parsing as decimal
            } else if (projectTypes?.length) { //If there is just one project type, use SQL = to get just that one
                queryString += this.queryAndSeperator(queryString.length)
                queryString += `sub_tree_root.ref_pm_project.x_enfy_bb_trt_project_type=${projectTypes[0]}`
            } //If no project types are included, don't filter on project type
            // console.log(`Query string after projectTypes considered: ${queryString}`)

            if (allowedReferenceIds?.length > 1) {
                queryString += this.queryAndSeperator(queryString.length)
                queryString += `x_enfy_bb_trt_reference_idIN(,${allowedReferenceIds.join(',')},)`
            } else if (allowedReferenceIds?.length) {
                queryString += this.queryAndSeperator(queryString.length)
                queryString += `x_enfy_bb_trt_reference_id=${allowedReferenceIds[0]}`
            } else if (disallowedReferenceIds?.length) { //disallowed reference Id's only considered if there are no allowed Id's
                for (let refId of disallowedReferenceIds) {
                    queryString += this.queryAndSeperator(queryString.length)
                    queryString += `x_enfy_bb_trt_reference_id!=${refId}`
                }
            }

            if (allowedStates?.length > 1) {
                queryString += this.queryAndSeperator(queryString.length)
                queryString += `stateIN(,${allowedStates.join(',')},)` //Ending on ',' to avoid parsing as decimal
            } else if (allowedStates?.length) {
                queryString += this.queryAndSeperator(queryString.length)
                queryString += `state=${allowedStates[0]}`
            }
            // console.log(`Query string after allowed states considered: ${queryString}`)

            if (disallowedStates) {
                for (let i in disallowedStates) {
                    let state = disallowedStates[i]
                    queryString += this.queryAndSeperator(queryString.length)
                    queryString += `state!=${state}`
                }
            }
            // console.log(`Query string after disallowed states considered: ${queryString}`)

            if (allowedCodes?.length > 1) {
                queryString += this.queryAndSeperator(queryString.length)
                queryString += `x_enfy_bb_trt_codeIN(,${allowedCodes.join(',')},)` //Ending on ',' to avoid parsing as decimal
            } else if (allowedCodes?.length) {
                queryString += this.queryAndSeperator(queryString.length)
                queryString += `x_enfy_bb_trt_code=${allowedCodes[0]}`
            }
            // console.log(`Query string after allowed codes considered: ${queryString}`)

            if (disallowedCodes) {
                for (let i in disallowedCodes) {
                    let code = disallowedCodes[i]
                    queryString += this.queryAndSeperator(queryString.length)
                    queryString += `x_enfy_bb_trt_code!=${code}`
                }
            }
            // console.log(`Query string after disallowed codes considered: ${queryString}`)

            if (mustHaveConnectionDate) {
                queryString += this.queryAndSeperator(queryString.length)
                queryString += "x_enfy_bb_trt_connection_date<javascript:gs.dateGenerate('3000-01-01','end')"
            }
            // console.log(`Query string after 'mustHaveConnectionDate' considered: ${queryString}`)

            if (installationLabelWhitelist?.length > 1 || ((!installationLabelWhitelist || installationLabelWhitelist?.length == 0) && projectTypes?.length == 1 && projectTypes?.[0] == ProjectType.CABINET)) {
                queryString += this.queryAndSeperator(queryString.length)
                queryString += `sub_tree_root.cmdb_ci.nameIN(,${installationLabelWhitelist.join(',')},)`
            } else if (installationLabelWhitelist?.length) {
                queryString += this.queryAndSeperator(queryString.length)
                queryString += `sub_tree_root.cmdb_ci.name=${installationLabelWhitelist[0]}`
            }
            // console.log(`Query string after installationLabel whitelist considered: ${queryString}`)

            if (installationLabelBlacklist?.length) {
                for (let label of installationLabelBlacklist) {
                    queryString += this.queryAndSeperator(queryString.length)
                    queryString += `sub_tree_root.cmdb_ci.name!=${label}`
                }
            }

            // console.log(queryString)

            return queryString
        },

        async dataGetAllTasks(params = {}) {

            let {
                projectId = null,
                taskFamily = 'installation',
                flags = null,
                blacklist = null,
                whitelist = null,
            } = params

            // DEFAULT FLAGS
            // includeBookBesigt: true,
            // includeFiberKonsu: true,
            // includePatch: true,
            // includeBlaes: true,
            // includeBookKunde: true,
            // includeCpe: true,
            // includeSplids: true,
            // includeUdvid: true,
            // includeTicket: true,
            // includeGrav: true,
            // includeIndmaal: true,
            // includeKsFiber: true,
            // includeProduktAktiv: true,
            // includeEmpty: true,

            // includePending: true,
            // includeOnHold: true,
            // includeResolved: true,


            
            // console.log('Getting all tasks for project:', projectId);
            // console.log('Other params:', taskFamily, flags, blacklist, whitelist);

            try {
                let apiData = await cloudFunctions.httpsCallable('getFilteredInstallationsFromPilotBi')({
                    projectId,
                    taskFamily,
                    flags,
                    blacklist,
                    whitelist,
                });
                // console.log('Got all tasks:', apiData.data);
                return apiData.data;

            } catch (error) {
                console.error('Error code:', error.code);
                console.error('Error message:', error.message);
                console.error('Error details:', error.details);

                return [];
            }
        },

        async dataGetAllProjectTasks() {
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetProjectTasksV2All', isActive: true})

            try {
                let apiData;
                if (this.useApiV3) {
                    apiData = await APIInstance.getProjectTasksV3();
                } else {
                    apiData = await APIInstance.getProjectTasksV2All();
                }
                EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetProjectTasksV2All', isActive: false});
                return apiData;
            } catch (error) {
                console.error('Error getting project tasks:', error);
                EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetProjectTasksV2All', isActive: false});
                throw error;
            }

        },

        async dataGetTroubleTickets(includeResolved = false){
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetTroubleTickets', isActive: true})

            var disallowedStates = []
            if (!includeResolved) {
                disallowedStates.push(TicketState.RESOLVED)
            }

            var apiData;
            if (this.useApiV3) {
                try {
                    apiData = await APIInstance.getTroubleTicketsV3();
                } catch (error) {
                    EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetTroubleTickets', isActive: false})
                    throw error
                }
            } else {
                try {
                    apiData = await APIInstance.getTroubleTicketsV2();
                } catch (error) {
                    EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetTroubleTickets', isActive: false})
                    throw error
                }
            }

            apiData = apiData.filter((ticket) => {
                return (!disallowedStates.includes(ticket.state.value))
            })

            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetTroubleTickets', isActive: false})
            return apiData;
        },

        async dataGetProjectTasksByInst(instLabel) {
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetProjectTasksByInst', isActive: true})

            if (!instLabel) {
                console.error('Tried to get tasks for falsy instLabel :-(')
                return []
            }

            try {
                var apiData = await APIInstance.getProjectTasksV3({installationLabel: instLabel});
                EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetProjectTasksByInst', isActive: false})
                return apiData; 
            } catch (error) {
                EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetProjectTasksByInst', isActive: false})
                throw error
            }
            
        },

        async dataGetTroubleTicketsByInst(instLabel) {
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetTroubleTicketsByInst', isActive: true})

            if (!instLabel) {
                console.error('Tried to get tickets for falsy instLabel :-(')
                return []
            } 

            var apiData;
            if (this.useApiV3) {
                try {
                    apiData = await APIInstance.getTroubleTicketsV3({installationLabel: instLabel});
                } catch (error) {
                    EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetTroubleTicketsByInst', isActive: false})
                    throw error
                }
            } else {
                try {
                    apiData = await APIInstance.getTroubleTicketsByInst(instLabel);
                } catch (error) {
                    EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetTroubleTicketsByInst', isActive: false})
                    throw error
                }
            }

            for (let ticket of apiData) {ticket.code = TaskCode.TICKET}
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetTroubleTicketsByInst', isActive: false})
            return apiData;
        },

        // TODO: Check if needs to be updated to V3
        // Only used in the cabinet list view, don't bother updating to V3
        async dataGetTasksV2(taskType, installationId, serviceOrderId, query){
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetTasksV2', isActive: true})
            let apiData = await APIInstance.getTasksV2(taskType, installationId, serviceOrderId, query)
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetTasksV2', isActive: false})
            return apiData
        },

        /**
         * @param {Date} since 
         * @returns {Object}
         */
        async dataGetClosedProjectTasks(since, areaCodes = []) {
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetClosedProjectTasks', isActive: true})
            if (this.useApiV3) {
                // Tickets are not included in the V3 API, so we need to get them separately
                // I'm not entirely sure how, the only timestamp you can query on is the resolvedAt timestamp
                // We can query on state the same way, but we may need to filter by date after getting the data
                // Tickets are not yet implemented in this function
                let promises = [];
                for (let state of TaskState.closedStatesArray) {
                    promises.push(APIInstance.getProjectTasksV3({updateSince: since, state: state}));
                }
                return Promise.all(promises)
                    .then(apiData => {
                        apiData = apiData.flat();
                        EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetClosedProjectTasks', isActive: false});
                        return apiData;
                    })
                    .catch(error => {
                        console.error('Error getting closed project tasks:', error);
                        EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetClosedProjectTasks', isActive: false});
                        throw error;
                    });

            } else {
                let apiData = await APIInstance.getClosedProjectTasks(since)
                apiData.projectTasks = apiData.projectTasks.filter(item => this.AreaCodeProjectTypeFilter(item, areaCodes))
                for (let i in apiData.troubleTickets){ apiData.troubleTickets[i].code = TaskCode.TICKET}
                EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetClosedProjectTasks', isActive: false})
                return apiData
            }
        },

        async dataGetClosedProjectTasksOnInst(instLabel) {
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetClosedProjectTasksOnInst', isActive: true});
            let promises = [];
            for (let state of TaskState.closedStatesArray) {
                promises.push(APIInstance.getProjectTasksV3({installationLabel: instLabel, state: state}));
            }
            return Promise.all(promises)
                .then(apiData => {
                    apiData = apiData.flat();
                    EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetClosedProjectTasksOnInst', isActive: false});
                    return apiData;
                })
                .catch(error => {
                    console.error('Error getting closed project tasks:', error);
                    EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetClosedProjectTasksOnInst', isActive: false});
                    throw error;
                });
        },

        combineNoteData(apiData, firNoteData) {
            let combinedNoteData = []
            if (apiData.notes){
                apiData.notes.forEach(note => {
                    combinedNoteData.push(note) //Push PilotBI notes to new combined array
                })
            }

            if (firNoteData){
                firNoteData.forEach(note => {
                    let noteObj = {
                        createdAt: note.timeStamp,
                        createdBy: note.authorEmail,
                        id: note.id,
                        value: note.noteBody
                    }
                    combinedNoteData.push(noteObj) //Push firebase notes to combined array
                })
            }

            return combinedNoteData.sort((a, b) => {
                return String(a.createdAt) < String(b.createdAt) ? 1 : -1
            })
        },

        async dataGetProjectTask(projectTask, getAttachments = false) {
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetProjectTask', isActive: true})
            if (!projectTask || !projectTask.id || !projectTask.number) {
                EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetProjectTask', isActive: false})
                throw new Error('Passed parameter projectTask must contain `id` and `number` properties.')
            }

            let apiData;
            try {
                apiData = await APIInstance.getProjectTask(projectTask.id); //Get all data from PilotBI
            } catch (error) {
                console.error('Error getting project task:', error);
                EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetProjectTask', isActive: false});
                throw error;
            }
            try {
                let firNoteData = await FirebaseInstance.getNotes(apiData.configurationItem.label); //Get notes from firebase
                apiData.notes = this.combineNoteData(apiData, firNoteData);
            } catch (error) {
                console.error('Error getting notes for project task:', error);
            }

            apiData.project = projectTask.project //project property is for some reason not included when getting individual tasks, only in the lists

            if (getAttachments){
                try {
                    var attachments = await APIInstance.getProjectTaskAttachments(projectTask.id)
                    if (!this.useApiV3){
                        attachments = attachments.attachments
                    }
                    apiData.attachments = attachments.filter((value, index, self) => {
                        return self.map(a => a.id).indexOf(value.id) === index
                    })
                } catch (error) {
                    console.error('Error getting attachments for project task:', error)
                }
            }

            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetProjectTask', isActive: false})
            return apiData
        },

        async getInternalNotesForTask(task){
            try {
                let firNoteData = await FirebaseInstance.getNotes(task.configurationItem.label); //Get notes from firebase
                task.notes = this.combineNoteData(task, firNoteData);
            } catch (error) {
                console.error('Error getting notes for project task:', error);
            }
            return task
        },

        async dataGetTroubleTicket(ticketId) {
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetTroubleTicket', isActive: true})

            if (!ticketId) {
                EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetTroubleTicket', isActive: false})
                throw new Error('Passed parameter ticketId must contain a value.')
            }

            var apiData;
            var attachments;
            if (this.useApiV3) {
                try {
                    apiData = await APIInstance.getTroubleTicketV3(ticketId);
                    attachments = await APIInstance.getTroubleTicketAttachments(ticketId);
                } catch (error) {
                    EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetTroubleTicket', isActive: false})
                    throw error
                }
            } else {
                try {
                    apiData = await APIInstance.getTroubleTicket(ticketId)
                    attachments = apiData.attachments
                } catch (error) {
                    EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetTroubleTicket', isActive: false})
                    throw error
                }
            }
            
            apiData.attachments = attachments.filter((value, index, self) => {
                return self.map(a => a.id).indexOf(value.id) === index
            })
            apiData.code = TaskCode.TICKET

            try {
                let firNoteData = this.getConfigurationLabel(apiData) ? await FirebaseInstance.getNotes(this.getConfigurationLabel(apiData)) : null
                apiData.notes = this.combineNoteData(apiData, firNoteData)
            } catch (error) {
                console.error('Error getting notes for ticket:', error)
            }

            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetTroubleTicket', isActive: false})
            return apiData
        },

        async dataGetAttachment(task, attachment){
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetAttachment', isActive: true})

            if (this.useApiV3 && task.code == TaskCode.TICKET) {
                let apiData = await APIInstance.getTroubleTicketAttachment(task.id, attachment)
                attachment.base64Content = apiData.base64Content;
                EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetAttachment', isActive: false})
                return attachment;
            } else if (this.useApiV3) {
                let apiData = await APIInstance.getProjectTaskAttachment(task.id, attachment)
                attachment.base64Content = apiData.base64Content;
                EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetAttachment', isActive: false})
                return attachment;
            } else {
                let apiData = await APIInstance.getAttachment(attachment.id)
                EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetAttachment', isActive: false})
                return apiData
            }


        },

        // TODO: Check if needs to be updated to V3
        async dataGetServiceOrder(serviceOrderId, responseMeta){
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetServiceOrder', isActive: true})
            var apiData
            try {
                apiData = await APIInstance.getServiceOrder(serviceOrderId, responseMeta)
            } catch (error) {
                EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetServiceOrder', isActive: false})
                throw error
            }
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetServiceOrder', isActive: false})
            return apiData
        },

        // TODO: Check if needs to be updated to V3
        async dataGetAllServiceOrdersByInst(instLabel, includeClosed = false, returnRaw = false){
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetAllServiceOrdersByInst', isActive: true})
            let apiData = await APIInstance.getAllServiceOrdersByInst(instLabel)
            let serviceOrders = apiData.data
            if (!includeClosed) {
                serviceOrders = serviceOrders.filter(so => {
                    if (so.state?.value) {
                        return !TaskState.closedStatesArray.includes(so.state.value)
                    }
                    return true //If serviceOrder has no state, include it to be safe
                })
            }
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetAllServiceOrdersByInst', isActive: false})
            if (returnRaw) return apiData
            return serviceOrders
        },

        async dataGetProducts(sonWinId, forcefetch = false, attempt = 0, maxAttempts = 10){
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetProducts', isActive: true})

            if (!forcefetch){
                try {
                    let responses = []
                    if (this.productResponses !== null){
                        responses = [...this.productResponses]
                    }
                    let responseIndex = responses.findIndex(response => response.id == sonWinId)
                    // console.log(`sonWinId found in responses at index ${responseIndex}`)
                    if (responseIndex != -1) { //If response is cached in firebase
                        EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetProducts', isActive: false})
                        return responses[responseIndex].products //Return cached data
                    }
                } catch (error) {
                    console.error('Error in getting cached data from firebase', error)
                }
            }

            //If we get this far, the data is not in cache, so we check to see if we have a pending call
            
            if (this.gettingProductData[sonWinId]) { //If the API call is already started
                // console.log(`Products API call seems to have already been called for ${sonWinId}, retrying in 2s...`)
                let oneMinuteAgo = new Date() //Current dateTime
                oneMinuteAgo = oneMinuteAgo.setMinutes((oneMinuteAgo.getMinutes() -1)) //Subtract 1 minute

                if (this.gettingProductData[sonWinId] < oneMinuteAgo) { //The stored datetime is more than (aka before) 1 minute ago
                    console.error(`API seems to have started more than one minute ago - not expecting a valid response at this point`)
                } else {
                    if (attempt < maxAttempts){
                        return this.sleep(2000).then(async () => { //Wait 2 seconds
                            let data = await this.dataGetProducts(sonWinId, forcefetch, attempt+1, maxAttempts) //Try again after 2 seconds
                            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetProducts', isActive: false})
                            return data
                        })
                    } else {
                        EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetProducts', isActive: false})
                        throw new Error('Max Attempts at getting products exceeded')
                    }
                }
            }

            //If we get this far, the data is either not cached, or forcefetch is true, so we must get data from the API
            try {
                this.gettingProductData[sonWinId] = new Date() //Register a pending call, to prevent double-calling
                var apiData = await APIInstance.getProducts(sonWinId)
            } catch (error) {
                console.error(`Error getting product data for serviceOrder ${sonWinId}`, error)
                delete this.gettingProductData[sonWinId] //Remove reference from list of pending calls
                EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetProducts', isActive: false})
                return apiData
            }

            db.collection('cacheData/products/responseData').doc(sonWinId).set({ //Cache data in Firestore //TODO: Rewrite as reference to function in Firebase.js
                products: apiData,
                gotDataFromAPI: new Date(),
                id: sonWinId,
            }).then(() => {
                // console.log(`Saved products in cache on Firebase, for later reference, with id ${sonWinId}`)
                delete this.gettingProductData[sonWinId] //Remove reference from list of pending calls
            }).catch((error) => {
                console.error('Error in caching API data in firebase', error)
                delete this.gettingProductData[sonWinId] //Remove reference from list of pending calls
            })

            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetProducts', isActive: false})
            return apiData
        },

        async dataGetUser(useremail){
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetUser', isActive: true})
            let response = await FirebaseInstance.getUser(useremail)
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetUser', isActive: false})
            return response
        },

        AreaCodeProjectTypeFilter(task, areaCodes) {

            if (!this.project?.Type || !task.project?.type?.value) return true //if there is no project, or the task has no project type, bypass the filter to be sure

            let allowedTypes = [...this.project.Type]
            if (allowedTypes?.length) {
            let tempAllowedTypes = [...this.project.Type]
                for (let type of tempAllowedTypes){
                    allowedTypes.push(`${type}.0`)
                }
            }

            // const mathcesOnProjectType = allowedTypes == task.project.type.value
            const mathcesOnProjectType = allowedTypes.includes(task.project?.type?.value)

            if (!task?.configurationItem?.area?.sonWinProjectId) {
                // console.log(`no configurationItem area, ${mathcesOnProjectType ? 'Matches project type' : 'Doesn´t match project type'}`)
                return mathcesOnProjectType // areaCodes.length == 0 && mathcesOnProjectType
            }

            let matchesOnAreaCode = true
            if (areaCodes?.length) {
                matchesOnAreaCode = areaCodes.includes(task.configurationItem.area.sonWinProjectId)
            }

            // console.log(matchesOnAreaCode, mathcesOnProjectType)

            return matchesOnAreaCode && mathcesOnProjectType
        },

        async dataPostNote(taskId, installationLabel, noteBody, intOrExt, projectId, isTicket = false) {
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataPostNote', isActive: true})
            console.log('Posting note:', projectId, taskId, noteBody, intOrExt, isTicket, installationLabel);

            if (!noteBody || noteBody == '' || !taskId) {
                EventBus.$emit('function-activity', {functionName: 'DataAPI_dataPostNote', isActive: false});
                throw new Error("Error posting note: noteBody and taskId must be provided");
            }
            
            // Check if intOrExt is valid
            if (intOrExt != 'Intern' && intOrExt != 'Ekstern') {
                EventBus.$emit('function-activity', {functionName: 'DataAPI_dataPostNote', isActive: false});
                throw new Error("Error posting note: intOrExt must be either 'Intern' or 'Ekstern'");
            }

            let user = this.$root.$children[0].user;
            noteBody = `@${user?.displayName}:\n${noteBody}` // Prepend username and ': \n' to beginning of noteBody

            // Internal notes require projectId and installationLabel
            if (intOrExt == 'Intern' && (!projectId|| !installationLabel)) {
                EventBus.$emit('function-activity', {functionName: 'DataAPI_dataPostNote', isActive: false});
                throw new Error("Error posting note: projectId, taskId and installationLabel must be provided for internal notes");
            }

            // Post note on internal task
            if (intOrExt == 'Intern') {
                let noteDoc = {
                    projectTaskId: taskId,
                    configurationItemLabel: installationLabel,
                    noteBody: noteBody,
                    timeStamp: this.formatMachineTimestamp(new Date()),
                    authorEmail: user.email
                };

                try {
                    let response = await FirebaseInstance.postNote(noteDoc, projectId);
                    EventBus.$emit('function-activity', {functionName: 'DataAPI_dataPostNote', isActive: false});
                    return response;
                } catch (error) {
                    console.error('Error posting note:', error);
                    EventBus.$emit('function-activity', {functionName: 'DataAPI_dataPostNote', isActive: false});
                    throw error;
                }
            }

            // Ready note for external task
            if (!this.useApiV3) {
                noteBody = noteBody.replaceAll('\n', ' \\n').replaceAll('"', "\\\""); //Escape \n and \" for external notes only, and add space before all newlines for formatting
            }
            
            // Post note on trouble ticket
            if (isTicket && this.useApiV3) {
                try {
                    let apiData = await APIInstance.postNoteOnTroubleTicket(taskId, noteBody);
                    EventBus.$emit('function-activity', {functionName: 'DataAPI_dataPostNote', isActive: false});
                    return apiData;
                } catch (error) {
                    console.error('Error posting note:', error);
                    EventBus.$emit('function-activity', {functionName: 'DataAPI_dataPostNote', isActive: false});
                    throw error;
                }
            } 
            else if (isTicket && !this.useApiV3) {
                try {
                    let apiData = await APIInstance.postNote(taskId, noteBody, 'TroubleTickets/Note', '');
                    EventBus.$emit('function-activity', {functionName: 'DataAPI_dataPostNote', isActive: false});
                    return apiData;
                } catch (error) {
                    console.error('Error posting note:', error);
                    EventBus.$emit('function-activity', {functionName: 'DataAPI_dataPostNote', isActive: false});
                    throw error;
                }
            } 
            // Post note on project task
            else if (!isTicket && this.useApiV3) {
                try {
                    let apiData = await APIInstance.postNoteOnProjectTask(taskId, noteBody);
                    EventBus.$emit('function-activity', {functionName: 'DataAPI_dataPostNote', isActive: false});
                    return apiData;
                } catch (error) {
                    console.error('Error posting note:', error);
                    EventBus.$emit('function-activity', {functionName: 'DataAPI_dataPostNote', isActive: false});
                    throw error;
                }
            }
            else { 
                try {
                    let apiData = await APIInstance.postNote(taskId, noteBody, 'ProjectTasks/ProjectTask', '/Note');
                    EventBus.$emit('function-activity', {functionName: 'DataAPI_dataPostNote', isActive: false});
                    return apiData;
                } catch (error) {
                    console.error('Error posting note:', error);
                    EventBus.$emit('function-activity', {functionName: 'DataAPI_dataPostNote', isActive: false});
                    throw error;
                }
            }
        },

        async dataPostAttachment(taskId, name, type, base64Content, isTicket = false) {
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataPostAttachment', isActive: true})

            if (!taskId || !name || !type || !base64Content) {
                EventBus.$emit('function-activity', {functionName: 'DataAPI_dataPostAttachment', isActive: false})
                throw new Error("Error posting attachment: taskId, name, type and base64Content must be provided");
            }

            let fileName = name

            const expectedExtension = this.getExtenstionByMimeType(type)
            const fileExtension = this.getFileExtension(fileName)
            if (fileExtension.toLowerCase() != expectedExtension) {
                fileName += `.${expectedExtension}`
            }

            if (isTicket && this.useApiV3) {
                try {
                    let apiData = await APIInstance.postAttachmentOnTroubleTicket(taskId, fileName, type, base64Content);
                    EventBus.$emit('function-activity', {functionName: 'DataAPI_dataPostAttachment', isActive: false});
                    return apiData;
                } catch (error) {
                    console.error('Error posting attachment:', error);
                    EventBus.$emit('function-activity', {functionName: 'DataAPI_dataPostAttachment', isActive: false});
                    throw error;
                }
            }
            else if (isTicket && !this.useApiV3) {
                try {
                    let apiData = await APIInstance.postAttachment(taskId, fileName, type, base64Content, 'TroubleTickets/TroubleTicket', '/Attachment');
                    EventBus.$emit('function-activity', {functionName: 'DataAPI_dataPostAttachment', isActive: false});
                    return apiData;
                } catch (error) {
                    console.error('Error posting attachment:', error);
                    EventBus.$emit('function-activity', {functionName: 'DataAPI_dataPostAttachment', isActive: false});
                    throw error;
                }
            }
            else if (!isTicket && this.useApiV3) {
                try {
                    let apiData = await APIInstance.postAttachmentOnProjectTask(taskId, fileName, type, base64Content);
                    EventBus.$emit('function-activity', {functionName: 'DataAPI_dataPostAttachment', isActive: false});
                    return apiData;
                } catch (error) {
                    console.error('Error posting attachment:', error);
                    EventBus.$emit('function-activity', {functionName: 'DataAPI_dataPostAttachment', isActive: false});
                    throw error;
                }
            }
            else {
                try {
                    let apiData = await APIInstance.postAttachment(taskId, fileName, type, base64Content, 'ProjectTasks/ProjectTask', '/Attachment');
                    EventBus.$emit('function-activity', {functionName: 'DataAPI_dataPostAttachment', isActive: false});
                    return apiData;
                } catch (error) {
                    console.error('Error posting attachment:', error);
                    EventBus.$emit('function-activity', {functionName: 'DataAPI_dataPostAttachment', isActive: false});
                    throw error;
                }
            }
        },

        async dataAddOrUpdateFormToPdfTemplate(docID, data) {
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataAddOrUpdateFormToPdfTemplate', isActive: true})
            let response = await FirebaseInstance.addOrUpdateFormToPdfTemplate(docID, data)
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataAddOrUpdateFormToPdfTemplate', isActive: false})
            return response
        },

        async dataAddOrUpdateFormToPdfForm(docID, data) {
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataAddOrUpdateFormToPdfForm', isActive: true})
            let response = await FirebaseInstance.addOrUpdateFormToPdfForm(docID, data)
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataAddOrUpdateFormToPdfForm', isActive: false})
            return response
        },

        async dataAddSignLedgerEntry(data) {
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataAddSignLedgerEntry', isActive: true})
            let response = await FirebaseInstance.addSignLedgerEntry(data)
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataAddSignLedgerEntry', isActive: false})
            return response
        },

        async dataDeleteFormToPdfForm(docID) {
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataDeleteFormToPdfForm', isActive: true})
            let response = await FirebaseInstance.deleteFormToPdfFormPermanently(docID)
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataDeleteFormToPdfForm', isActive: false})
            return response
        },

        // eslint-disable-next-line
        async dataAddOrUpdateAppointment(appointmentId, data) {
            
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataAddOrUpdateAppointment', isActive: true})
            if (appointmentId && !data.Updated) data.Updated == this.formatMachineTimestamp(new Date()) //Ensure there is always an updated timestamp
            let response = await FirebaseInstance.addOrUpdateAppointment(appointmentId, data)
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataAddOrUpdateAppointment', isActive: false})
            return response
        },

        async dataCancelAppointment(appointmentId) {
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataCancelAppointment', isActive: true})
            let response = await (this.dataAddOrUpdateAppointment(appointmentId, {State: 'cancelled'}))
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataCancelAppointment', isActive: false})
            return response
        },

        async dataUpdateAppointmentState(appointmentId, data){
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataUpdateAppointmentState', isActive: true})
            await FirebaseInstance.addOrUpdateAppointment(appointmentId, data)
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataUpdateAppointmentState', isActive: false})
        },

        /**
         * Updates booking in Firebase and Dispatch-API (PilotBI)
         * @param {String} projectId 
         * @param {String} installationLabel 
         * @param {Array} taskArray 
         * @param {String} appointmentType 
         * @param {String} appointmentId 
         * @param {Object} data 
         * @returns null
         */
        // eslint-disable-next-line
        async dataUpdateBooking(projectId, installationLabel, taskArray, appointmentType, appointmentId, data) {
            if (!installationLabel || !taskArray || !appointmentType || !data) {
                console.error('dataUpdateBooking: Missing required parameters', installationLabel, taskArray, appointmentType, data)
                throw new Error('dataUpdateBooking: Missing required parameters')
            }
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataUpdateBooking', isActive: true})

            // let appointmentBeforeChange = null
            // if (appointmentId) {
            //     appointmentBeforeChange = await this.dataGetAppointmentById(projectId, appointmentId)
            // }

            var reducedProjectTasks
            try {
                reducedProjectTasks = AppointmentType.relevantCompactTasks(taskArray, appointmentType) //Reduce taskArray to simplified version of only relevant tasks
            }
            catch(error) {
                console.log("Error catched, do you want to book anyway?")

                let bookAnyway = await swal({
                    title: 'Ingen bookingopgave, ønsker du at booke alligevel?',
                    text: 'Vær opmærksom på, at aftalen ikke gemmes hos Energi Fyn hvis der trykkes ja!',
                    icon: 'warning',
                    buttons: true,
                    dangerMode: true,
                })

                if (bookAnyway){
                    reducedProjectTasks = AppointmentType.relevantCompactTasks(taskArray, appointmentType, true) //Reduce taskArray to simplified version of only relevant tasks
                } else {
                    console.log(error)
                    return 
                }

            }
            
            const bookTask = reducedProjectTasks.find((task) => task.Role == AppointmentType.TaskRoles.Book) //Find the booking task, if available
            const primaryTask = reducedProjectTasks.find((task) => task.Role == AppointmentType.TaskRoles.Primary) //The task that is primary for the appointmentType
            // console.log('Primary task', primaryTask)

            let taskMap = {}
            for (let i in taskArray) { //TODO: I feel like there is a more elegant solution, that doesn't require this loop
                taskMap[taskArray[i].code] = taskArray[i]
            }

            var assignee = '' //Initialize assignee string, for use with PilotBI API

            //timeWindowString example: '2021/03/26;12:00;14:30'
            var plannedStart = new Date(data.timeWindowString.substr(0,16).replaceAll('/','-').replace(';','T')).toISOString()
            var plannedEnd = new Date(data.timeWindowString.substr(0,10).replaceAll('/','-')+'T'+data.timeWindowString.substr(17,5)).toISOString()

            const taskIsTRT = taskArray[0].number.substr(0,3) == 'TRT' //troubleTickets' numbers start with 'TRT', 'PRJTASK' for projectTasks
            const configItemPropName = taskIsTRT ? 'installation' : 'configurationItem'

            let cabinet = data.cabinet || null
            if (typeof(cabinet?.Hub) == 'undefined' && cabinet != null){
                cabinet.Hub = null
            }

            let projectInstallationType = null
            if (taskIsTRT) {
                projectInstallationType = "TroubleTickets"
            }
            if (taskArray[0].project?.type?.value) {
                projectInstallationType = taskArray[0].project.type.value
            }

            if (!projectInstallationType && projectId === '31Kab0br5wfyWGpFxydR') { // If project is 'Nyborg 500G' and no project type is found
                projectInstallationType = '6' // Set project type to '6' (Installationsprojekt Opsamling)
            }

            let document = { 
                ProjectTasks: reducedProjectTasks,
                InstallationLabel: installationLabel,
                AppointmentType: appointmentType,
                Confirmed: data.confirmed,
                TimeWindowString: data.timeWindowString,
                Updated: this.formatMachineTimestamp(new Date()),
                UpdatedBy: data.user,
                Worker: data.worker,
                Contacts: {
                    Mobiles: data.contactMobiles,
                    Emails: data.contactEmails,
                },
                SendReminder: data.sendReminder,
                Flag: data.flag,
                Lock: data.lock,
                IsHouseAssociation: data.isHouseAssociation,
                State: data.state,
                AddressLong: data.addressLong,    
                AddressShort: data.addressShort,
                LinkedProjects: [projectId],
                ProjectInstallationType: projectInstallationType,
                AreaCode: taskArray[0]?.[configItemPropName]?.area?.sonWinProjectId,
                ReferenceId: data.referenceId,
                Cabinet: cabinet,
                CallInAdvance: data.callInAdvance,
                IsOnCancellationList: data.isOnCancellationList,
                Tags: data.tags,
                changeLog: data.changeLog,
                changeLogOverflow: data.changeLogOverflow,
                metadata: data.metadata
            }

            console.log("Document",document)

            if (data.waitingListTimeStamp != null){
                document.CancellationListTimestamp = data.cancellationListTimestamp
            }

            // console.log("Document",document)

            console.log("Save appointment ", appointmentId, document)
            FirebaseInstance.addOrUpdateAppointment(/*projectId,*/ appointmentId, document) //Save appointment in firebase
            console.log("Appointment saved in Firebase")

            if (taskIsTRT) { //If the task at hand is a Trouble Ticket
                
                if(!data || !data.confirmed || !data.sendToEFB) {
                    console.log("Booking is not confirmed. Exiting function")
                    EventBus.$emit('function-activity', {functionName: 'DataAPI_dataUpdateBooking', isActive: false})
                    return
                }

                assignee = data?.worker?.Name || null

                let reducedTicketsNumbers = []
                reducedProjectTasks.forEach((ticket) => {reducedTicketsNumbers.push(ticket.Number)})

                for (let i in taskArray){
                    let task = taskArray[i]
                    if (reducedTicketsNumbers.includes(task.number)){
                        //update the ticket
                        await this.dataUpdateTroubleTicket(
                            task.id, //Existing ID
                            TicketState.WORK_IN_PROGRESS, //When booking, always set state to WIP
                            assignee, //New assignee
                            null, //On Hold reason - I think null will not overwrite anything
                            plannedStart,
                            plannedEnd,
                        )

                        if (data.sendToEFB){
                            try {
                                // Post note to data provider
                                if (task.id){
                                    await this.dataPostNote(task.id, null, data.externalUpdateNote, 'Ekstern', null, true)
                                }
                            } catch (error) {
                                EventBus.$emit('function-activity', {functionName: 'DataAPI_dataUpdateBooking', isActive: false})
                                let errorMessage = `Could not post note to PilotBI with error:\n${error.message}`
                                console.error(errorMessage)
                            }
                        }
                    }
                }
            }
            else { //If not a trouble ticket, the task is assumed to be a ProjectTask

                const lastTask = this.findLastTask(taskArray) || {}
                const lastTaskId = lastTask.ProjectTaskId || lastTask.id
                const primaryTaskId = primaryTask?.Id || lastTaskId
                const bookTaskId = bookTask?.Id || null

                let retrievedUnitWork = await FirebaseInstance.getUnitWorkForInst(this.installationLabel)
                
                let unitId = '931.F.05' //Booking unitWork
                let user = this.$parent.$parent.user
                let unitWorkDocument = {
                    Unit: {
                        Id: this.unitWorkCompositeUnits.find((u) => u.id == unitId).id,
                        ...this.unitWorkCompositeUnits.find((u) => u.id == unitId)
                    },
                    Amount: 1,
                    EquivalentHours: this.calculateEquivalentHours(unitId, 1),
                    Price: this.calculatePrice(unitId, 1),
                    AreaCode: taskArray[0].configurationItem?.area?.sonWinProjectId,
                    ProjectInstallationType: projectInstallationType,
                    ConfigurationItem: {
                        Label: taskArray[0].configurationItem?.label,
                        Area: taskArray[0].configurationItem?.area,
                        Cabinet: taskArray[0].configurationItem?.cabinet,
                        TechnicalData: taskArray[0].configurationItem?.technicalData,
                        Type: taskArray[0].configurationItem?.type,
                        Value: taskArray[0].configurationItem?.value,
                        Address: this.formatAddress(taskArray[0].configurationItem?.address, false) || null,
                    },
                    ReferenceId: taskArray[0].referenceId,
                    Workers: [user?.email],
                    TimeStamp: new Date(),
                    CreatedBy: {
                        Name: user?.displayName,
                        Email: user?.email,
                        Initials: user?.email?.substr(0, user?.email?.indexOf('@')).toUpperCase()
                    },
                    AutoGenerated: true,
                    Date: this.formatMachineDate(new Date(), '-'),
                    Billed: {
                        Bool: false,
                        Updated: new Date()
                    },
                    LinkedProjects: [this.$route.params.projectIdentifier],
                    FromDocumentation: {},
                }

                if (!appointmentId){
                    let confirm = true
                    if (retrievedUnitWork.findIndex(uw => uw.Unit.Id == unitId) != -1){ //UnitWork already found
                        confirm = await swal({
                            title: 'Tilføj enhed for booking?',
                            text: 'Der er allerede tilføjet enheds-arbejde for booking af denne kunde, vil du tilføje enheden igen?',
                            icon: 'warning', //Can be warning, error, success, info
                            buttons: {
                                cancel: {
                                    text: 'Annuller',
                                    value: false,
                                    visible: true,
                                },
                                confirm: {
                                    text: 'Tilføj enheds-arbejde',
                                    value: true,
                                    visible: true,
                                },
                            }
                        })
                    }
                    if (confirm){
                        FirebaseInstance.addOrUpdateUnitWork(unitWorkDocument)
                    }
                }
    
                if(!data || !data.confirmed || !data.sendToEFB) { //Booking is not confirmed ('Varslet')
                    console.log("Booking is not confirmed. Exiting function")
                    EventBus.$emit('function-activity', {functionName: 'DataAPI_dataUpdateBooking', isActive: false})
                    return
                }

                //Add unitWork for booking installation
                unitId = '931.F.07' //Notifying the customer (by letter in mailbox) unitWork
                if (retrievedUnitWork.findIndex(uw => uw.Unit.Id == unitId) != -1) { //UnitWork already found, do not add again
                    unitWorkDocument.Unit = {
                        Id: this.unitWorkCompositeUnits.find((u) => u.id == unitId).id,
                        ...this.unitWorkCompositeUnits.find((u) => u.id == unitId)
                    }
                    unitWorkDocument.EquivalentHours = this.calculateEquivalentHours(unitId, 1)
                    unitWorkDocument.Price = this.calculatePrice(unitId, 1)
                    FirebaseInstance.addOrUpdateUnitWork(unitWorkDocument)
                }

                assignee = data?.worker?.Name || null

                var connectionDate = null
                if (primaryTask.Code && TaskCode.ConnectionDateArray.includes(primaryTask.Code)) { //If the primary task is a connection task, set connection date to plannedEnd
                    connectionDate = this.cloneJson(plannedEnd)
                }
                
                // Update last task
                // const primaryTask = taskArray.find((t) => t.id == primaryTaskId) //Definition moved to top of function
                const primaryTaskState = taskArray.find((t) => t.id == primaryTaskId)?.state?.value || TaskState.OPEN //If primary task is not found, set state to OPEN
                try {
                    await this.dataUpdateProjectTask(
                        primaryTaskId, //Task ID
                        primaryTaskState, //Task State
                        assignee, //Remote assignee
                        connectionDate, //Connection date
                        null, //onHoldReason
                        null, //onHoldSubReason
                        plannedStart, //plannedStart
                        plannedEnd, //plannedEnd
                    )
                } catch (error) {
                    // console.error(error)

                    try {
                        await this.dataUpdateProjectTask(
                            primaryTaskId, //Task ID
                            primaryTaskState, //Task State
                            assignee, //Remote assignee
                            connectionDate, //Connection date
                            null, //onHoldReason
                            null, //onHoldSubReason
                            plannedStart, //plannedStart
                            plannedEnd, //plannedEnd
                        )
                    } catch (error) {
                        EventBus.$emit('function-activity', {functionName: 'DataAPI_dataUpdateBooking', isActive: false})
                        throw new Error(`While saving the booking, encountered the following error when updating the primary task:\n${error.message}`)
                    }

                    // EventBus.$emit('function-activity', {functionName: 'DataAPI_dataUpdateBooking', isActive: false})
                    // throw new Error(`While saving the booking, encountered the following error when updating the primary task:\n${error.message}`)
                }

                try {
                    if (bookTaskId) {
                        let username = this.$parent.$parent.user.displayName
                        // console.log(username)
                        
                        // Update BOOKKUNDE task
                        await this.dataUpdateProjectTask(
                            bookTaskId, //Task ID
                            TaskState.CLOSED_COMPLETE, //Task state
                            username, //Rmote assignee
                            connectionDate, //connection date
                            null, //on hold reason
                            null // onHoldSubReason
                        )
                    }
                } catch (error) {
                    EventBus.$emit('function-activity', {functionName: 'DataAPI_dataUpdateBooking', isActive: false})
                    throw new Error(`While saving the booking, encountered the following error when updating the booking task:\n${error.message}`)
                }

                if (data.sendToEFB){
                    try {
                        // Post note to data provider
                        if (primaryTaskId){
                            await this.dataPostNote(primaryTaskId, null, data.externalUpdateNote, 'Ekstern', null, false)
                        }
                    } catch (error) {
                        EventBus.$emit('function-activity', {functionName: 'DataAPI_dataUpdateBooking', isActive: false})
                        let errorMessage = `Could not post note to PilotBI with error:\n${error.message}`
                        console.error(errorMessage)
                    }
                }
            }
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataUpdateBooking', isActive: false})
        },

        async dataGetAppointmentsOnInst(instNumber) {
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetAppointmentsOnInst', isActive: true})
            let appointments = await FirebaseInstance.getAppointmentsOnInst(instNumber)
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetAppointmentsOnInst', isActive: false})
            return appointments
        },

        async dataDeleteConnectionDate(taskId){
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataDeleteConnectionDate', isActive: true})
            if (!taskId || typeof taskId != typeof 'string') {
                console.error(`Could not delete connectionDate with falsy taskId: ${taskId}`)
                EventBus.$emit('function-activity', {functionName: 'DataAPI_dataDeleteConnectionDate', isActive: false})
                return null
            }

            if (this.useApiV3) {
                try {
                    let response = await APIInstance.deleteConnectionDateV3(taskId).then(() => {
                        console.log(`Removed connectionDate from task ${taskId}`)
                    })
                    EventBus.$emit('function-activity', {functionName: 'DataAPI_dataDeleteConnectionDate', isActive: false});
                    return response;
                } catch (error) {
                    console.error(`Error deleting connectionDate from task ${taskId}`, error)
                    EventBus.$emit('function-activity', {functionName: 'DataAPI_dataDeleteConnectionDate', isActive: false});
                    return null;
                }
            } else {
                try {
                    let response = await APIInstance.deleteConnectionDate(taskId).then(() => {
                        console.log(`Removed connectionDate from task ${taskId}`)
                    })
                    EventBus.$emit('function-activity', {functionName: 'DataAPI_dataDeleteConnectionDate', isActive: false});
                    return response;
                } catch (error) {
                    console.error(`Error deleting connectionDate from task ${taskId}`, error)
                    EventBus.$emit('function-activity', {functionName: 'DataAPI_dataDeleteConnectionDate', isActive: false});
                    return null;
                }
            }
        },
        
        async dataDeleteAppointment(appointment, bookTask, cpeTask, user, reason, setOnHold, onHoldReason, onHoldSubReason) {
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataDeleteAppointment', isActive: true})

            let errorMessages = []

            // let lastTask = this.findLastTask(appointment.ProjectTasks, false)

            // Prepare note text.
            let noteText = `${user.Name} har slettet aftale med begrundelse: ${reason}.\n`
            noteText += `Aftale indhold: \nTidspunkt ${appointment.TimeWindowString}.\nAftale bekræftet: ${appointment.Confirmed ? 'Ja' : 'Nej'}`
            noteText = noteText.replaceAll('\n', '\\n').replaceAll('"', "\\\"")

            if ((appointment.Confirmed || setOnHold) && cpeTask.Code != TaskCode.PATCH){ // PATCH will be handled internally
                if (!bookTask || !cpeTask) {
                    EventBus.$emit('function-activity', {functionName: 'DataAPI_dataDeleteAppointment', isActive: false})
                    throw new Error('Either BOOKKUNDE or CPE task was not found when deleting appointment')
                }
    
                // Update assignee to empty string on BOOK task
                const assignee = ''

                //TODO: Consider replacing below code with loop, looping through tasks in appointment
                try {
                    //Update BOOK task connectionDate, assignee and on_hold state
                    await this.dataUpdateProjectTask(
                        bookTask.Id,
                        setOnHold ? TaskState.ON_HOLD : TaskState.OPEN,
                        assignee,
                        null, //Connection date is deleted in seperate method
                        setOnHold ? onHoldReason : null,
                        setOnHold ? onHoldSubReason : null
                    )
                } catch (error) {
                    let errorMessage = `Could not update booking project task (${bookTask.Code}) with error:\n${error.message}`
                    console.error(errorMessage)
                    errorMessages.push(errorMessage)
                }

                try {
                    // Update CPE task assignee and pending state
                    await this.dataUpdateProjectTask(
                        cpeTask.Id,
                        TaskState.PENDING,
                        assignee,
                        null,
                        null,
                        null
                    )
                } catch (error) {
                    let errorMessage = `Could not update primary project task (${cpeTask.Code}) with error:\n${error.message}`
                    console.error(errorMessage)
                    errorMessages.push(errorMessage)
                }

                try {
                    // Post note to data provider
                    await this.dataPostNote(cpeTask.Id, null, noteText, 'Ekstern', null, false)
                } catch (error) {
                    let errorMessage = `Could not post note to PilotBI with error:\n${error.message}`
                    console.error(errorMessage)
                    errorMessages.push(errorMessage)
                }
            } else {
                if (reason?.length) {
                    try {
                        let noteTask = bookTask || cpeTask || appointment.ProjectTasks[0];
                        await this.dataPostNote(noteTask.id, appointment.InstallationLabel, noteText, 'Intern')
                    } catch (error) {
                        let errorMessage = `Could not post note to Firebase with error:\n${error.message}`
                        console.error(errorMessage)
                        errorMessages.push(errorMessage)
                    }
                }
            }

            try {
                // Remove appointment in firebase.
                await FirebaseInstance.removeAppointment(appointment.id)
            } catch (error) {
                let errorMessage = `Failed canceling appointment with error:\n${error.message}`
                console.error(errorMessage)
                errorMessages.push(errorMessage)
            }

            let joinedErrorMessage = `The following errors occured while canceling appointment:\n\n${errorMessages.join(`\n\n`)}`
            if (errorMessages.length) {
                swal('Fejl i aflysning af aftale', joinedErrorMessage, 'error')
                EventBus.$emit('function-activity', {functionName: 'DataAPI_dataDeleteAppointment', isActive: false})
                throw new Error(joinedErrorMessage)
            }
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataDeleteAppointment', isActive: false})
        },

        // TODO: Check if needs to be updated to V3
        async dataDeleteTtAppointment(appointment, taskarray, user, reason) {
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataDeleteTtAppointment', isActive: true})

            // Prepare note text.
            let noteText = `${user.Name} har slettet aftale med begrundelse: ${reason}.\n`
            noteText += `Aftale indhold: \nTidspunkt ${appointment.TimeWindowString}.\nAftale bekræftet: ${appointment.Confirmed ? 'Ja' : 'Nej'}`
            let noteTextExternal = noteText.replaceAll('\n', '\\n').replaceAll('"', "\\\"")

            let taskIds = []
            for (let i in this.appointment.ProjectTasks){
                taskIds.push(this.appointment.ProjectTasks[i].Id)
            }
            for (let i in taskarray) {
                let task = taskarray[i]
                if (taskIds.includes(task.id) && !TicketState.closedStatesArray.includes(task.state.value)){
                    //Update assignee to empty string
                    const assignee = ''
                    await this.dataUpdateTroubleTicket(
                        task.id,
                        null,
                        assignee,
                        null
                    )

                    // Post note to data provider
                    await this.dataPostNote(task.id, null, noteTextExternal, 'Ekstern', null, true);
                } 
                else if (taskIds.includes(task.id)){
                    await this.dataPostNote(task, noteText, this.$route.params.projectIdentifier, 'Intern')
                }  
            }            

            // Remove appointment in firebase.
            await FirebaseInstance.removeAppointment(appointment.id)
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataDeleteTtAppointment', isActive: false})
        },

        async dataGetAppointment(projectId, projectTask, appointmentType, onlyActive = true) {
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetAppointment', isActive: true})
            let appointment = await FirebaseInstance.getAppointment(projectId, projectTask, appointmentType, onlyActive)
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetAppointment', isActive: false})
            return appointment
        },

        async dataGetAppointmentById(projectId, appointmentId) {
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetAppointmentById', isActive: true})
            let appointment = await FirebaseInstance.getAppointmentById(projectId, appointmentId)
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetAppointmentById', isActive: false})
            return appointment
        },

        async dataUpdateProjectTask(projectTaskId, state, assignee, connectionDate, onHoldReason, onHoldSubReason, plannedStart, plannedEnd, signalStrengthData, signalStrengthCaTV, speedTestFiberbox, dataportCheckPerformed){
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataUpdateProjectTask', isActive: true})
            if (this.useApiV3) {
                try {
                    let updateObj = {
                        state: state,
                        assignee: assignee,
                        connectionDate:connectionDate,
                        onHoldReason: onHoldReason,
                        onHoldSubReason: onHoldSubReason,
                        plannedStart: plannedStart,
                        plannedEnd: plannedEnd,

                        signalStrengthData: signalStrengthData,
                        signalStrengthCaTV: signalStrengthCaTV,
                        speedTestFiberbox: speedTestFiberbox,
                        dataportCheckPerformed: dataportCheckPerformed,
                    }

                    let response = await APIInstance.updateProjectTaskV3(projectTaskId, updateObj)
                    EventBus.$emit('function-activity', {functionName: 'DataAPI_dataUpdateProjectTask', isActive: false})
                    return response
                } catch (error) {
                    console.error('Error updating project task:', error)
                    EventBus.$emit('function-activity', {functionName: 'DataAPI_dataUpdateProjectTask', isActive: false})
                    throw error
                }
                    
            } else {
                try {
                    let response = await APIInstance.updateProjectTask(projectTaskId, state, assignee, connectionDate, onHoldReason, onHoldSubReason, plannedStart, plannedEnd, signalStrengthData, signalStrengthCaTV, speedTestFiberbox, dataportCheckPerformed)
                    EventBus.$emit('function-activity', {functionName: 'DataAPI_dataUpdateProjectTask', isActive: false})
                    return response;
                } catch (error) {
                    console.error('Error updating project task:', error)
                    EventBus.$emit('function-activity', {functionName: 'DataAPI_dataUpdateProjectTask', isActive: false})
                    throw error;
                }
            }
        },

        async dataUpdateTroubleTicket(troubleTicketId, state, assignee, onHoldReason, plannedStart, plannedEnd){ 
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataUpdateTroubleTicket', isActive: true})
            if (this.useApiV3) {
                try {
                    let updateObj = {
                        state: state,
                        assignee: assignee,
                        onHoldReason: onHoldReason,
                        plannedStart: plannedStart,
                        plannedEnd: plannedEnd,
                    }

                    let response = await APIInstance.updateTroubleTicketV3(troubleTicketId, updateObj)
                    EventBus.$emit('function-activity', {functionName: 'DataAPI_dataUpdateTroubleTicket', isActive: false})
                    return response
                } catch (error) {
                    console.error('Error updating trouble ticket:', error)
                    EventBus.$emit('function-activity', {functionName: 'DataAPI_dataUpdateTroubleTicket', isActive: false})
                    throw error
                }
            } else {
                try {
                    let response = await APIInstance.updateTroubleTicket(troubleTicketId, state, assignee, onHoldReason)
                    EventBus.$emit('function-activity', {functionName: 'DataAPI_dataUpdateTroubleTicket', isActive: false})
                    return response
                } catch (error) {
                    console.error('Error updating trouble ticket:', error)
                    EventBus.$emit('function-activity', {functionName: 'DataAPI_dataUpdateTroubleTicket', isActive: false})
                    throw error
                }
            }
        },

        async externalGetGeoCoordinatesFromAddress(address) {
            EventBus.$emit('function-activity', {functionName: 'DataAPI_externalGetGeoCoordinatesFromAddress', isActive: true})
            let coords = {}
            try {
                coords = await DawaAPIInstance.getGeoCoordinatesFromAddress(address)
            }
            catch {
                console.log(`Unable to find address %c${this.formatAddress(address, false)}%c in DAWA, trying on Google`, 'font-weight: bold; color: cyan;', '')
                try {
                    coords = await GoogleAPIInstance.getGeoCoordinatesFromAddress(address)
                }
                catch (error) {
                    console.error(`Neither DAWA or Google could find address: ${this.formatAddress(address, false)}`)
                    throw new Error(`Failed getting coordinates for address "${address}" with error: ${error.message}`)
                }
            }
            EventBus.$emit('function-activity', {functionName: 'DataAPI_externalGetGeoCoordinatesFromAddress', isActive: false})
            return coords
        },

        async dataSaveCoordinates(results, projectId, projectType) {
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataSaveCoordinates', isActive: true})
            let response
            try {
                response = await FirebaseInstance.saveCoordinates(results, projectId, projectType)
            } catch (error) {
                EventBus.$emit('function-activity', {functionName: 'DataAPI_dataSaveCoordinates', isActive: false})
                throw new Error(error)
            }
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataSaveCoordinates', isActive: false})
            return response
        },

        async dataGetCoordinatesFromFirebase(label, projectId) {
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetCoordinatesFromFirebase', isActive: true})
            let response = await FirebaseInstance.getCoordinates(label, projectId)
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetCoordinatesFromFirebase', isActive: false})
            return response
        },

        async dataAddEmail(emailObj){
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataAddEmail', isActive: true})
            let response = await FirebaseInstance.addEmail(emailObj)
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataAddEmail', isActive: false})
            return response
        },

        async dataAddSMS(smsObj) {
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataAddSMS', isActive: true})
            let response = await FirebaseInstance.addSMS(smsObj)
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataAddSMS', isActive: false})
            return response
        },

        async dataGetAllProjectCoordinates(projectId) {
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetAllProjectCoordinates', isActive: true})
            let response = await FirebaseInstance.getAllProjectCoordinates(projectId)
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetAllProjectCoordinates', isActive: false})
            return response
        },

        async dataCreateNewHub(hubObj) {
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataCreateNewHub', isActive: false})
            let response = await FirebaseInstance.createNewHub(hubObj)
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataCreateNewHub', isActive: false})
            return response
        },

        async dataGetContactsFromCompany(companyName) {
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetContactsFromCompany', isActive: true})
            let response = await FirebaseInstance.getContactsFromCompany(companyName)
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetContactsFromCompany', isActive: false})
            return response
        },

        async dataGetHubs(id) {
            if (!id) return []
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetHubs', isActive: true})
            let response
            try {
                response = await APIInstance.getTechnicalFromId('HUB', id)
            } catch (error) {
                console.error('Error getting hub from API:', error)
                EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetHubs', isActive: false})
            }

            if (!response.data) {
                EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetHubs', isActive: false})
                return []
            }

            let requestedHubId = id.replaceAll(' ', '')
            if (id.indexOf('-') != -1){
                requestedHubId = requestedHubId.substr(0, requestedHubId.indexOf('-'))
            }

            //This filter removes erroneous responses from PilotBI, so when they fix the bug, it shouldn't be required
            response.data = response.data.filter((hub) => {
                let retrievedHubId = hub.identification.replaceAll(' ', '').substr(0, hub.identification.replaceAll(' ', '').indexOf('-'))
                // console.log(`${retrievedHubId} == ${requestedHubId} (${retrievedHubId == requestedHubId})`)
                return retrievedHubId == requestedHubId
            })
            
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetHubs', isActive: false})
            return response.data
        },

        async dataGetUubs(id) {
            if (!id) {
                console.error('Could not get uubs without ID')
                return []
            }
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetUubs', isActive: true})
            id = id.replace(/^0+/, '') //Remove leading 0s
            let response = await APIInstance.getTechnicalFromId('UUB', id)
            // console.log(`Got uubs with data: ${JSON.stringify(response.data)}`)
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetUubs', isActive: false})
            return response.data
        },

        async dataGetReport(idSource, planType, formatType) {
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetReport', isActive: true})
            let response = await APIInstance.getReportFromIdSource(idSource, planType, formatType)
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetReport', isActive: false})
            return response
        },

        async dataAddOrUpdateWorkDay(workDayObj, projectId) {
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataAddOrUpdateWorkDay', isActive: true})
            let response = await FirebaseInstance.addOrUpdateWorkDay(workDayObj, projectId)
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataAddOrUpdateWorkDay', isActive: false})
            return response
        },

        async dataAddOrUpdateUnitWork(unitWorkDocument, unitWorkId){
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataAddOrUpdateUnitWork', isActive: true})
            if (!unitWorkDocument.Amount && !unitWorkId){
                console.error('Did not save, because amount was 0')
                EventBus.$emit('function-activity', {functionName: 'DataAPI_dataAddOrUpdateUnitWork', isActive: false})
                return unitWorkDocument
            }
            let response = await FirebaseInstance.addOrUpdateUnitWork(unitWorkDocument,unitWorkId)
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataAddOrUpdateUnitWork', isActive: false})
            return response
        },

        async dataGetUnitWorkForInst(instLabel){
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetUnitWorkForInst', isActive: true})
            let response = await FirebaseInstance.getUnitWorkForInst(instLabel)
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetUnitWorkForInst', isActive: false})
            return response
        },

        async dataDeleteUnitWork(unitWork){
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataDeleteUnitWork', isActive: true})
            let response
            if (Array.isArray(unitWork)){
                let jobs = []
                for (let i in unitWork){
                    jobs.push(FirebaseInstance.deleteUnitWork(unitWork[i]))
                }
                response = Promise.all(jobs)
            } else {
                response = await FirebaseInstance.deleteUnitWork(unitWork)
            }
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataDeleteUnitWork', isActive: false})
            return response
        },

        async dataGetSubtasksForInst(instLabel){
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetSubtasksForInst', isActive: true})
            let response = await FirebaseInstance.getSubtasksForInst(instLabel)
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetSubtasksForInst', isActive: false})
            return response
        },

        async dataGenericUpdateDocument(collection, docID, doc){
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGenericUpdateDocument', isActive: true})
            let response = await FirebaseInstance.genericUpdateDocument(collection, docID, doc)
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGenericUpdateDocument', isActive: false})
            return response
        },

        async dataGetNexelData(instLabel){
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetNexelData', isActive: true})
            let response
            try {
                response = await APIInstance.getNexelData(instLabel)
            } catch (error) {
                EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetNexelData', isActive: false})
                throw error
            }
            if (!response.headers?.date){
                response.headers.date = this.formatMachineTimestamp(new Date)
            }
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetNexelData', isActive: false})
            return {
                headers: response.headers,
                ...response.data
            }
        },

        async dataGetNexelStatus(instLabel){
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetNexelStatus', isActive: true})
            let response
            try {
                response = await APIInstance.getNexelStatus(instLabel)
            } catch (error) {
                EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetNexelStatus', isActive: false})
                throw error
            }
            if (!response.headers?.date){
                response.headers.date = this.formatMachineTimestamp(new Date)
            }
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetNexelStatus', isActive: false})
            return { 
                headers: response.headers,
                ...response.data
            }
        },

        async dataGetZipCodes(){
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetZipCodes', isActive: true})
            let response = await DawaAPIInstance.listZipCodes()
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataGetZipCodes', isActive: false})
            return response
        },

        async dataSaveUnitWorkOutputSettings(presetId, settingObj) {
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataSaveUnitWorkOutputSettings', isActive: true})
            let response = await FirebaseInstance.saveUnitWorkOutputSettings(presetId, settingObj)
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataSaveUnitWorkOutputSettings', isActive: false})
            return response
        },

        async dataRevokeUserRefreshToken(FirebaseUID) {
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataRevokeUserRefreshToken', isActive: true})
            let response = await FirebaseInstance.revokeRefreshToken(FirebaseUID)
            EventBus.$emit('function-activity', {functionName: 'DataAPI_dataRevokeUserRefreshToken', isActive: false})
            return response
        }
    }
}
