<template>
    <sui-modal
        id="stateChangeModal"
        :open="isOpen" 
        v-on:clickAwayModal="clickAway"
        v-if="task && task != {}"
    >
        <sui-modal-header>
            Ændre status for {{ task.number}} - {{ task.shortDescription }}
        </sui-modal-header>
        <sui-modal-content scrolling style="max-height: 65vh">
            <sui-form @submit.prevent="changeState" :error="!installationLabel">
                <sui-message error>
                    <sui-message-header>Intet Installationsnummer</sui-message-header>
                    <p>Der er sket en fejl, hvor installationsnummeret til denne opgave ikke kunne findes.<br>Du kan derfor ikke tilføje noter til denne opgave.</p>
                </sui-message>
                <div class="row">
                    <div class="col-sm-5">
                        <sui-segment vertical>
                            <sui-form-field required title="Vælg en af EnergiFyns pre-definerede status'er (påkrævet)">
                                <label>Ny Status</label>
                                <sui-dropdown
                                    placeholder="Status"
                                    selection
                                    v-model="selectedStatus"
                                    :options="statusOptions"/>
                            </sui-form-field>
                        </sui-segment>
                        <sui-segment vertical v-if="selectedStatus && !(!taskIsTroubleTicket && selectedStatus != TaskState.ON_HOLD) && !(taskIsTroubleTicket && selectedStatus != TicketState.ON_HOLD)">
                            <sui-form-field 
                                :disabled="!selectedStatus || (!taskIsTroubleTicket && selectedStatus != TaskState.ON_HOLD) || (taskIsTroubleTicket && selectedStatus != TicketState.ON_HOLD)"
                                :required="!(!selectedStatus || (!taskIsTroubleTicket && selectedStatus != TaskState.ON_HOLD) || (taskIsTroubleTicket && selectedStatus != TicketState.ON_HOLD))"
                                title="Vælg en af EnergiFyns pre-definerede årsager (påkrævet)"
                            >
                                <label>On Hold Årsag</label>
                                <sui-dropdown
                                    placeholder="On Hold Reason"
                                    selection
                                    v-model="selectedOnHoldReason"
                                    :options="onHoldReasonOptions"/>
                                <small v-if="selectedOnHoldReason && taskIsTroubleTicket"><em>{{onHoldReasonOptions.find(o => o.value == selectedOnHoldReason).title}}</em></small>
                            </sui-form-field>
                            <sui-form-field 
                                v-if="!taskIsTroubleTicket"
                                :disabled="!selectedStatus || (!taskIsTroubleTicket && selectedStatus != TaskState.ON_HOLD) || (taskIsTroubleTicket && selectedStatus != TicketState.ON_HOLD)"
                                :required="!(!selectedStatus || (!taskIsTroubleTicket && selectedStatus != TaskState.ON_HOLD) || (taskIsTroubleTicket && selectedStatus != TicketState.ON_HOLD))"
                                title="Vælg en af EnergiFyns pre-definerede årsager (påkrævet)"
                            >
                                <label>On Hold Underkategori</label>
                                <sui-dropdown
                                    placeholder="On Hold Subreason"
                                    selection
                                    v-model="selectedOnHoldSubreason"
                                    :options="onHoldSubreasonOptions"/>
                                <small v-if="selectedOnHoldSubreason"><em>{{onHoldSubreasonOptions.find(o => o.value == selectedOnHoldSubreason).title}}</em></small>
                            </sui-form-field>
                        </sui-segment>
                        <sui-segment vertical v-if="selectedStatus && !taskIsTroubleTicket && task.code == TaskCode.CPE && (selectedStatus == TaskState.CLOSED_COMPLETE || selectedStatus == TaskState.CLOSED_INCOMPLETE)">
                            <sui-form-fields>
                                <sui-form-field 
                                    width="eight" 
                                    :required="selectedStatus && !taskIsTroubleTicket && task.code == TaskCode.CPE && (selectedStatus == TaskState.CLOSED_COMPLETE)"
                                    title="Udfyld signalstyrkemåling på datafiber i dBm (påkrævet)"
                                >
                                    <label>Signalstyrke Data</label>
                                    <sui-input v-model="signalStrengthData"/>
                                </sui-form-field>
                                <sui-form-field 
                                    width="eight" 
                                    :required="selectedStatus && !taskIsTroubleTicket && task.code == TaskCode.CPE && (selectedStatus == TaskState.CLOSED_COMPLETE)"
                                    :title="`Udfyld signalstyrkemåling på TV-fiber i dBm (påkrævet)\n(udfyldes med '-' hvis der ikke er signal på TV-fiber)`"
                                >
                                    <label>Signalstyrke TV</label>
                                    <sui-input v-model="signalStrengthCaTV"/>
                                </sui-form-field>
                            </sui-form-fields>
                            <sui-form-field 
                                :required="selectedStatus && !taskIsTroubleTicket && task.code == TaskCode.CPE && (selectedStatus == TaskState.CLOSED_COMPLETE)"
                                title="Kryds af at du har tjekket på fqpn at porten er korrekt (påkrævet)"
                            >
                                <p>
                                    <sui-checkbox style="padding-top: 3px; margin-right: 5px;" v-model="dataportCheckPerformed"/>
                                    <span class="fake-label" @click="dataportCheckPerformed = !dataportCheckPerformed">Port tjekket<strong style="color:#db2828;"> *</strong><span v-if="technicalData"> ({{technicalData.asrName}} - {{technicalData.asrPort}})</span></span>
                                </p>
                            </sui-form-field>
                            <sui-form-field title="Resultat af evt udført hastighedstest noteres her">
                                <label>Hastighedstest</label>
                                <sui-input v-model="speedTestFiberbox"/>
                            </sui-form-field>
                        </sui-segment>
                        <sui-segment vertical v-if="selectedStatus && !taskIsTroubleTicket && task.code == TaskCode.FIBERKONSU && (selectedStatus == TaskState.CLOSED_COMPLETE || selectedStatus == TaskState.CLOSED_INCOMPLETE)">
                            <sui-button @click.prevent="uploadFile">Upload Fil</sui-button>
                        </sui-segment>
                        <sui-segment vertical>
                            <sui-form-field :title="`Eventuel note i forbindelse med status-ændringen,\npåføres opgaven som enhver anden note`"> 
                                <label>Note</label>
                                <textarea rows="3" v-model="stateReasonNote" :disabled="!installationLabel"></textarea>
                            </sui-form-field>
                        </sui-segment>
                    </div>
                    <div class="col-sm-7">
                        <sui-segment vertical>
                            <div class="row" v-if="selectedStatus && !taskIsTroubleTicket && task.code == TaskCode.CPE && (selectedStatus == TaskState.CLOSED_COMPLETE || selectedStatus == TaskState.CLOSED_INCOMPLETE)">
                                <div class="col-sm-5 scrollable-y" style="max-height: 30vh;">
                                    <sui-table striped>
                                        <nexel-status 
                                            :instLabel="installationLabel"
                                            :includeReloadBtn="true"
                                        />
                                    </sui-table>
                                </div>
                                <div class="col-sm-7 scrollable-y" style="max-height: 30vh;">
                                    <sui-table striped>
                                        <nexel-data 
                                            :instLabel="installationLabel"
                                            :includeReloadBtn="true"
                                        />
                                    </sui-table>
                                </div>
                            </div>
                            <div v-if="selectedStatus && !taskIsTroubleTicket && task.code == TaskCode.FIBERKONSU && (selectedStatus == TaskState.CLOSED_COMPLETE || selectedStatus == TaskState.CLOSED_INCOMPLETE)">
                                <sui-table striped>
                                    <sui-table-header>
                                        <sui-table-row>
                                            <sui-table-header-cell colspan="2">
                                                Filer på denne opgave
                                                <i v-if="loadingFiles" style="float: right;" class="fa-solid fa-spinner-third fa-spin"/>
                                            </sui-table-header-cell>
                                        </sui-table-row>
                                    </sui-table-header>
                                    <sui-table-body>
                                        <template v-for="(file) in task.attachments">
                                            <sui-table-row :key="file.id" @click="openAttachment(task, file)" class="hover-pointer">
                                                <sui-table-cell colspan="2">
                                                    <p>
                                                        <strong>{{ file.name }}</strong> - 
                                                        <span>{{ file.type }}</span>
                                                    </p>
                                                    <p>{{ formatBytes(file.sizeBytes) }}</p>
                                                </sui-table-cell>
                                            </sui-table-row>
                                        </template>
                                        <sui-table-row key="noFilesMessage" v-if="!task.attachments || !task.attachments.length">
                                            <sui-table-cell colspan="2">
                                                <p><em>Der er ikke fundet nogen filer på denne opgave</em></p>
                                            </sui-table-cell>
                                        </sui-table-row>
                                    </sui-table-body>
                                </sui-table>
                            </div>


                            <!-- <sui-form-fields grouped v-if="selectedStatus && !taskIsTroubleTicket && task.code == TaskCode.CPE && (selectedStatus == TaskState.CLOSED_COMPLETE || selectedStatus == TaskState.CLOSED_INCOMPLETE)">
                                <sui-form-field required>
                                    <label for="autoAddUnitWork">Er kundeinstallationen lavet på akkord eller timeløn?</label>
                                </sui-form-field>
                                <sui-form-field inline>
                                    <sui-checkbox radio v-model="autoAddUnitWork" label="" value="true"/><label>Akkord <small>(Der skrives automatisk akkord-arbejde for installation af CPE)</small></label><br>
                                </sui-form-field>
                                <sui-form-field inline>
                                    <sui-checkbox radio v-model="autoAddUnitWork" label="" value="false"/><label>Timeløn <small>(Der skrives ikke automatisk noget til fakturering)</small></label>
                                </sui-form-field>
                            </sui-form-fields>
                            <sui-form-field required v-if="selectedStatus && taskIsTroubleTicket && [TicketState.CLOSED, TicketState.RESOLVED].includes(selectedStatus)">
                                <label>Hvordan skal opgaven faktureres?</label>
                                <sui-dropdown
                                    placeholder="Faktureres som..."
                                    selection
                                    v-model="troubleTicketUnitWorkUnit"
                                    :options="troubleTicketUnitWorkOptions"
                                />
                            </sui-form-field> -->

                            <sui-form-field v-if="showInvoiceDropdown" required>
                                <label>Hvordan skal opgaven faktureres?</label>
                                <sui-dropdown
                                    placeholder="Faktureres som..."
                                    selection
                                    v-model="selectedUnitWorkUnit"
                                    :options="project.TaskCompletionUnitworkUnitOptions[task.code].options"
                                />
                            </sui-form-field>

                            <sui-form-field 
                                :title="`Medarbejdere der skal noteres med på enheds-arbejdet`" 
                                v-if="
                                    selectedStatus && 
                                    ((!taskIsTroubleTicket && task.code == TaskCode.CPE && [TaskState.CLOSED_COMPLETE, TaskState.CLOSED_INCOMPLETE].includes(selectedStatus)) || 
                                    (taskIsTroubleTicket && [TicketState.CLOSED, TicketState.RESOLVED].includes(selectedStatus)))
                                "
                            >
                                <label>Medarbejdere</label>
                                <sui-dropdown
                                    placeholder="Medarbejder(e)"
                                    selection 
                                    multiple
                                    v-model="selectedWorkers"
                                    :options="workerOptions"/>
                            </sui-form-field>
                            <sui-form-field>
                                <p :title="`Åbner enheds-arbejde formular når du gemmer denne status-ændring`">
                                    <sui-checkbox v-model="addExtraUnitWork" style="padding-top: 3px; margin-right: 5px;" />
                                    <span class="fake-label" @click="addExtraUnitWork = !addExtraUnitWork">Tilføj ekstra-arbejde</span>
                                    <br><span class="explanation" @click="addExtraUnitWork = !addExtraUnitWork">Herunder alt arbejde der ligger ud over en almindelig installation af CPE.</span>
                                </p>
                            </sui-form-field>
                            <sui-form-field v-if="selectedStateIsSpecial">
                                <label>Send status-ændring til <small><em>(Lad stå tom hvis der ikke skal sendes mail)</em></small></label>
                                <sui-dropdown
                                    placeholder="Kontaktperson(er)"
                                    selection
                                    multiple
                                    :options="contacts"
                                    v-model="selectedContacts"
                                />
                            </sui-form-field>
                        </sui-segment>
                        <sui-message error :visible="!this.taskIsTroubleTicket && this.task.code == TaskCode.FIBERKONSU && this.selectedStatus == TaskState.CLOSED_COMPLETE && !this.taskHasPdf && !getProjectSetting(project,'allowFinishInspectionWithoutPDF')">
                            <p>Husk at uploade etableringsaftalen inden du lukker opgaven</p>
                        </sui-message>
                    </div>
                </div>
            </sui-form>
            <sui-dimmer :active="stateFormLoading">
                <multi-step-loader
                    class="content"
                    :steps="loadingSteps"
                />
            </sui-dimmer>
        </sui-modal-content>
        <sui-modal-actions>
            <sui-button
                negative
                @click="clickAway"
            >
                Luk
            </sui-button>
            <sui-button
                positive
                type="submit"
                @click="changeState"
                :disabled="saveButtonDisabled"
            >
                Gem
            </sui-button>
        </sui-modal-actions>
    </sui-modal>
</template>
<script>
import swal from 'sweetalert'
import { Mixin } from '@/lib/Mixins/mixin'
import { DateMixin } from '../../lib/Mixins/dateMixin'
import { unitWorkMixin } from '@/lib/unitWorkMixin'
import { DataAPI } from '@/lib/DataAPI'
import EventBus from '@/EventBus'
import TaskState from '@/lib/Enums/TaskState'
import TicketState from '@/lib/Enums/TicketState'
import OnHoldReason from '@/lib/Enums/OnHoldReason'
import EmailTemplate from '@/lib/Enums/EmailTemplate'
import { analytics } from '@/firebase'
import NexelStatus from '@/components/Project/NexelStatus'
import NexelData from '@/components/Project/NexelData'
import TaskCode from '@/lib/Enums/TaskCode'
import MultiStepLoader from '../Global/MultiStepLoader.vue'
import UserRoles from '../../lib/Enums/UserRoles'
import { ProjectSettingsMixin } from '@/modules/ProjectSettings/projectSettingsMixin.js'

export default {
    mixins: [Mixin, DateMixin, unitWorkMixin, DataAPI, ProjectSettingsMixin],

    enums: {
        TaskState,
        TicketState,
        TaskCode,
        UserRoles
    },

    components: {
        NexelStatus,
        NexelData,
        MultiStepLoader,
    },

    data() {
        return {
            project: null,

            selectedContacts: [],

            stateFormLoading: false,
            selectedStatus: null,
            selectedOnHoldReason: null,
            selectedOnHoldSubreason: null,
            onHoldReasonOptions: OnHoldReason.DROPDOWN_OPTIONS,
            onHoldSubreasonOptions: [],

            signalStrengthData: "",
            signalStrengthCaTV: "",
            speedTestFiberbox: "",
            dataportCheckPerformed: false,

            stateReasonNote: "",

            autoAddUnitWork: null,
            selectedWorkers: [],
            addExtraUnitWork: false,

            troubleTicketUnitWorkOptions: [
                {text: 'Netselskab', value: 'F.TRT.EFB'},
                {text: 'Indholdsudbyder', value: 'F.TRT.SP'},
                {text: 'AX-nummer', value: 'F.TRT.AX'},
            ],
            troubleTicketUnitWorkUnit: null,
            selectedUnitWorkUnit: null,

            loadingSteps: [],
            loadingFiles: false,
        }
    },

    props: {
        task: Object,
        isOpen: Boolean,
        projectProp: Object,
        serviceOrder: Object,
        technicalData: Object,
        installationLabel: String,
        availableWorkers: Array,
        relevantAppointments: Array,
    },

    computed: {
        statusOptions() {
            // console.log('re-computing property "statusOptions"')
            if (!this.task) {
                return []
            }
            if (this.taskIsTroubleTicket) {
                return TicketState.DROPDOWN_OPTIONS
            } else {
                let filterState = true
                if (this.firebaseUser && this.firebaseUser.Roles && this.firebaseUser.Roles.includes(UserRoles.EFB_BYPASS_BUSINESS_RULES)){
                    filterState = false
                }

                if (filterState){
                    let options = this.cloneJson(TaskState.DROPDOWN_OPTIONS)
                    options = options.filter((opt) => {
                        return opt.value != TaskState.CLOSED_SKIPPED && opt.value != TaskState.CLOSED_INCOMPLETE
                    })
                    return options
                }
                return TaskState.DROPDOWN_OPTIONS
            }
        },

        showInvoiceDropdown() {
            // Don't show invoice dropdown if missing data
            if (!this.isOpen || !this.project || !this.project.TaskCompletionUnitworkUnitOptions || !this.task) {
                return false
            }

            if (!Object.keys(this.project.TaskCompletionUnitworkUnitOptions).includes(this.task.code)) {
                return false
            }

            // Don't show invoice dropdown if there is only one option
            if (this.project.TaskCompletionUnitworkUnitOptions[this.task.code].options.length <= 1) {
                return false
            }

            // TICKET
            if (this.selectedStatus && 
                this.taskIsTroubleTicket && 
                [TicketState.CLOSED, TicketState.RESOLVED].includes(this.selectedStatus)) {
                    return true }

            // CPE
            if (this.selectedStatus && 
                !this.taskIsTroubleTicket && 
                this.task.code == TaskCode.CPE && 
                (this.selectedStatus == TaskState.CLOSED_COMPLETE || 
                this.selectedStatus == TaskState.CLOSED_INCOMPLETE)) {
                    return true }
            
            // FIBERKONSU
            if (this.selectedStatus &&
                !this.taskIsTroubleTicket &&
                this.task.code == TaskCode.FIBERKONSU &&
                (this.selectedStatus == TaskState.CLOSED_COMPLETE || 
                this.selectedStatus == TaskState.CLOSED_INCOMPLETE)) {
                    return true
            }
            
            return false
        },

        saveButtonDisabled() {
            if (!this.selectedStatus) return true
            if (this.taskIsTroubleTicket && 
                (this.selectedStatus == TicketState.CLOSED || this.selectedStatus == TicketState.RESOLVED) &&
                !this.selectedUnitWorkUnit

            ) {
                return true
            }
            if (!this.taskIsTroubleTicket && 
                this.task.code == TaskCode.CPE && 
                this.selectedStatus == TaskState.CLOSED_COMPLETE &&
                (!this.signalStrengthData || 
                !this.signalStrengthCaTV || 
                !this.dataportCheckPerformed || 
                !this.selectedUnitWorkUnit
                // !(this.autoAddUnitWork == 'false' || this.autoAddUnitWork == 'true')
            )){
                return true
            }
            if (!this.taskIsTroubleTicket && 
                this.task.code == TaskCode.FIBERKONSU && 
                this.selectedStatus == TaskState.CLOSED_COMPLETE &&
                !this.getProjectSetting(this.project,"allowFinishInspectionWithoutPDF") &&
                (!this.selectedUnitWorkUnit ||
                !this.taskHasPdf
            )) {
                return true 
            }
            if (this.selectedStateIsOnHold && !this.taskIsTroubleTicket && (!this.selectedOnHoldReason || !this.selectedOnHoldSubreason)) return true
            if (this.selectedStateIsOnHold && !this.selectedOnHoldReason) return true
            return false
        },

        firebaseUser() {
            return this.$parent.firebaseUser
        },

        workerOptions() {
            let options = this.availableWorkers || []
            if (options.findIndex(w => w.value == this.firebaseUser.email) == -1){
                options.push({text: this.firebaseUser.Name, value: this.firebaseUser.Email}) //Add self to list of workers
            }
            return options
        },

        contacts() {
            let projectContacts = this.project ? this.project.Contacts : []
            let contactOptions = []
            for (let contact of projectContacts) {
                if (contact.Name && contact.Email) {
                    contactOptions.push({
                        text: contact.Name,
                        value: contact.Email
                    })
                }
            }
            return contactOptions
        },

        openTasksOnServiceOrder() {
            if (!this.serviceOrder || !this.serviceOrder.project || !this.serviceOrder.project.tasks || !this.serviceOrder.project.tasks.length) return []
            let openTasks = []
            for (let task of this.serviceOrder.project.tasks) {
                if (!TaskCode.AllArray.includes(task.code)) continue; //Skip irrelevant tasks based on code
                if (task.state && 
                    task.state.value && //Task has state
                    !TaskState.closedStatesArray.includes(task.state.value) && //Task state is not a closed state
                    task.id != this.task.id //Task is not the currently open task
                ) {
                    openTasks.push(task)
                }
            }
            return openTasks.sort((a,b) => a.number > b.number ? 1 : -1)
        },

        selectedStateIsSpecial() {
            return (
                    ( //ProjectTask special states:
                        !this.taskIsTroubleTicket && (
                            // this.selectedStatus == TaskState.ON_HOLD ||
                            this.selectedStatus == TaskState.CLOSED_SKIPPED ||
                            this.selectedStatus == TaskState.CLOSED_INCOMPLETE
                        )
                    ) ||
                    ( //TroubleTicket special states:
                        this.taskIsTroubleTicket && (
                            this.selectedStatus == TicketState.ON_HOLD ||
                            this.selectedStatus == TicketState.CANCELED ||
                            this.selectedStatus == TicketState.RESOLVED
                        )
                    )
                )
        },

        selectedStateIsOnHold() {
            return (
                (!this.taskIsTroubleTicket && this.selectedStatus == TaskState.ON_HOLD) ||
                (this.taskIsTroubleTicket && this.selectedStatus == TicketState.ON_HOLD)
            )
        },

        loadingStepsContainsError() {
            for (let step of this.loadingSteps) {
                if (step.state == 'error') return true
            }
            return false
        },

        taskIsTroubleTicket() {
            if (this.task?.code == TaskCode.TICKET) return true
            return false


            // if (!this.task || !this.task.number || typeof this.task.number != 'string') {
            //     // console.error(`Could not determine if task is a troubleTicket:`, JSON.stringify(this.task, null, 2)) //Log error
            //     return false //Fail safe
            // }
            // return this.task.number.substring(0,3) == 'TRT'
        },

        configurationItemName() {
            return this.taskIsTroubleTicket ? 'installation' : 'configurationItem'
        },

        taskHasPdf() {
            if (!this.task || !this.task.attachments || !this.task.attachments.length) {
                return false
            }
            for (let attachment of this.task.attachments) {
                if (attachment.type == 'application/pdf') return true
            }
            return false
        },
    },

    methods: {

        prePopulateForm() {
            // console.log('Preparing stateChange form')
            this.signalStrengthData = this.technicalData?.signalStrengthData
            this.signalStrengthCaTV = this.technicalData?.signalStrengthCaTV
            this.dataportCheckPerformed = this.stringToBool(this.technicalData?.portCheckPerformed)
            this.speedTestFiberbox = this.technicalData?.speedTestFiberbox
            this.selectedWorkers = (this.firebaseUser && this.firebaseUser.Email) ? [this.firebaseUser.Email] : []
            this.troubleTicketUnitWorkUnit = null
            if (this.task && this.task.referenceId && (
                    this.task.referenceId?.includes('AX') || //Reference ID includes the substring 'AX'
                    this.task.description?.match(/(AX\d{4,10})/gi) || //Description includes a substring starting with AX followed by 4-10 nummerical digits
                    JSON.stringify(this.task.notes)?.match(/(AX\d{4,10})/gi) //Notes include a substring starting with AX followed by 4-10 nummerical digits
                )){
                this.troubleTicketUnitWorkUnit = 'F.TRT.AX' //Pre-fill unitWorkUnit as having an AX-numer reference
            }
            this.selectedContacts = []

            if (!Object.keys(this.project.TaskCompletionUnitworkUnitOptions).includes(this.task.code)) {
                this.selectedUnitWorkUnit = null
            } else if (this.isOpen && this.project && this.task && this.project.TaskCompletionUnitworkUnitOptions[this.task.code].options.length == 1) {
                this.selectedUnitWorkUnit = this.project.TaskCompletionUnitworkUnitOptions[this.task.code].options[0].value
            }
        },

        changeLoadingStep(code, state){
            let stepIndex = this.loadingSteps.findIndex(step => step.code == code)
            if (stepIndex == -1) {
                console.error(`Could not find step with code ${code} in this.loadingSteps`)
                return
            }
            let stepObj = this.loadingSteps[stepIndex]
            stepObj.state = state
            this.$set(this.loadingSteps, stepIndex, stepObj)
            return stepObj
        },

        async changeState() {
            EventBus.$emit('function-activity', {functionName: 'StateChangeModal_changeState', isActive: true})

            this.stateFormLoading = true

            this.loadingSteps = [
                {
                    code: 'VALIDATE',
                    title: 'Check at data er gyldigt',
                    state: 'in progress',
                },
                {
                    code: 'CHECK_APPOINTMENT',
                    title: 'Kontrollér aftale (ved lukning)',
                    state: 'pending',
                },
                {
                    code: 'CANCEL_APPOINTMENT',
                    title: 'Aflys evt aftale (kun on-hold)',
                    state: 'pending',
                },
                {
                    code: 'CHECK_SERVICE_ORDER_TASKS',
                    title: 'Check opgaver i serviceOrder',
                    state: 'pending',
                },
                {
                    code: 'UPDATE_SERVICE_ORDER_TASKS',
                    title: 'Opdatér evt serviceOrder opgaver',
                    state: 'pending',
                },
                {
                    code: 'ADD_NOTE',
                    title: 'Tilføj evt note på opgave',
                    state: 'pending',
                },
                {
                    code: 'UPDATE_MAIN_TASK',
                    title: 'Opdater denne opgave',
                    state: 'pending',
                },
                {
                    code: 'ADD_UNIT_WORK',
                    title: 'Tilføj evt enhedsarbejde',
                    state: 'pending',
                },
                {
                    code: 'SAVE_INTERNAL_NOTE',
                    title: 'Gem statusændring i interne noter',
                    state: 'pending'
                },
                {
                    code: 'SEND_NOTIFICATION',
                    title: 'Send evt email',
                    state: 'pending',
                },
            ]

            //Gather relevant data
            let task = this.task
            if (!task) {
                swal('Invalid data', 'Task er ugyldig.', 'error')
                console.log(this.task)
                EventBus.$emit('function-activity', {functionName: 'StateChangeModal_changeState', isActive: false})
                return
            }

            let projectTaskId = task.id
            if (task.ProjectTaskId) {
                projectTaskId = task.ProjectTaskId
            }
            let state = this.selectedStatus
            let assignee = task.assignee
            let connectionDate = task.connectionDate
            let onHoldReason = task.onHoldReason || null
    
            if ( this.selectedStateIsOnHold) { //If new status is ON_HOLD
                if (!this.selectedOnHoldReason) { // Check that onHoldReson is selected
                    swal('Ingen årsag valgt', 'Ved status `on Hold` skal der vælges en årsag', 'warning')
                    this.stateFormLoading = false //End loading animation
                    EventBus.$emit('function-activity', {functionName: 'StateChangeModal_changeState', isActive: false})
                    return
                }
                if (!this.selectedOnHoldSubreason && !this.taskIsTroubleTicket) { // Check that onHoldSubreson is selected
                    swal('Ingen årsag valgt', 'Ved status `on Hold` skal der vælges en årsag', 'warning')
                    this.stateFormLoading = false //End loading animation
                    EventBus.$emit('function-activity', {functionName: 'StateChangeModal_changeState', isActive: false})
                    return
                }
                if (this.relevantAppointments && this.relevantAppointments.length) { //Check for active appointments
                    let plural = this.relevantAppointments.length > 1
                    let relevantAppointmentIds = this.relevantAppointments.reduce((prev, cur) => [...prev, cur.id], [])
                    console.log(this.relevantAppointments, relevantAppointmentIds)
                    let closeAppointments = await swal({
                        title: `Aktiv${plural ? 'e' : ''} aftale${plural ? 'r' : ''} på opgave`,
                        text: `Der er fundet følgende aftale${plural ? 'r' : ''} på denne opgave:\n${relevantAppointmentIds.join(", ")}\n\nVil du aflyse aftale${plural ? 'rne' : 'n'}?`,
                        dangerMode: true,
                        buttons: {
                            cancel: 'Luk ikke',
                            confirm: `Luk aftale${plural ? 'r' : ''}`
                        }
                    })
                    if (closeAppointments) {
                        this.changeLoadingStep('CANCEL_APPOINTMENT', 'in progress')

                        //TODO: Ensure there is no connectionDate on task when cancelling appointments
                        // await this.dataDeleteConnectionDate(projectTaskId) //Serverside logic changes state of all serviceOrder tasks :-(
                        for (let appointment of this.relevantAppointments) {
                            this.dataCancelAppointment(appointment.id).then(() => {
                                this.dataDeleteConnectionDate(projectTaskId).then(() => {
                                    this.changeLoadingStep('CANCEL_APPOINTMENT', 'success')
                                }).catch((reason) => {
                                    console.error(reason)
                                    this.changeLoadingStep('CANCEL_APPOINTMENT', 'error')
                                })
                            }).catch((reason) => {
                                console.error(reason)
                                this.changeLoadingStep('CANCEL_APPOINTMENT', 'error')
                            })
                        }
                    }
                }
            }

            // Check a note is written for special states
            if ( this.selectedStateIsSpecial && !(this.stateReasonNote && this.stateReasonNote.length > 1) ){
                let shouldProceed = await swal({
                    //'Advarsel', 'Ved status `on Hold` skal der vælges en årsag og en note.', 'warning'
                    icon: 'warning',
                    dangerMode: true,
                    title: 'Mangler note',
                    text: `Ved status '${this.taskIsTroubleTicket ? TicketState.getValueFromCode(this.selectedStatus) : TaskState.getValueFromCode(this.selectedStatus)}' skal der tilføjes en note, der forklarer årsagen til at denne status er valgt.`,
                    buttons: {
                        cancel: {
                            text: 'Gå tilbage',
                            value: false,
                            visible: true,
                            closeModal: true,
                        },
                        confirm: {
                            text: 'Gem uden note',
                            value: true,
                            visible: false, //Change to true if user should be able to bypass this warning
                            closeModal: true,
                        },
                    }
                })
                if (!shouldProceed) {
                    this.changeLoadingStep('VALIDATE', 'error')
                    this.stateFormLoading = false //End loading animation
                    return
                }
            }

            this.changeLoadingStep('VALIDATE', 'success')
            this.changeLoadingStep('CHECK_SERVICE_ORDER_TASKS', 'in progress')

            if (
                this.taskIsTroubleTicket &&
                [TicketState.RESOLVED, TicketState.CLOSED].includes(state)
            ){
                this.changeLoadingStep('CHECK_APPOINTMENT', 'in progress')
                if (!this.relevantAppointments?.length){ //Check that the task is associated with one or more active appointment(s)
                    swal('Ingen aftale', 'Der er ingen aftale om installation hos denne kunde, lav en aftale før du afslutter opgaven (undlad at sende beskeder til kunden hvis du allerede er færdig)', 'error')
                    console.log('No appointment for this task')
                    this.changeLoadingStep('CHECK_APPOINTMENT', 'error')
                    this.stateFormLoading = false //End loading animation
                    EventBus.$emit('function-activity', {functionName: 'StateChangeModal_changeState', isActive: false})
                    return
                } else {
                    this.changeLoadingStep('CHECK_APPOINTMENT', 'success')
                }
            }

            if (
                !this.taskIsTroubleTicket && //Task is not a toubleTicket (so it must be a projectTask)
                state == TaskState.CLOSED_COMPLETE && //Task is being set to closed complete
                this.task.code == TaskCode.CPE && //Task code is CPE (Final task of normal serviceOrder)
                this.openTasksOnServiceOrder.length //There are open tasks on the serviceOrder
            ) {
                this.changeLoadingStep('CHECK_APPOINTMENT', 'in progress')
                if (!this.relevantAppointments?.length){ //Check that the task is associated with one or more active appointment(s)
                    swal('Ingen aftale', 'Der er ingen aftale om installation hos denne kunde, lav en aftale før du afslutter opgaven (undlad at sende beskeder til kunden hvis du allerede er færdig)', 'error')
                    console.log('No appointment for this task')
                    this.changeLoadingStep('CHECK_APPOINTMENT', 'error')
                    this.stateFormLoading = false //End loading animation
                    EventBus.$emit('function-activity', {functionName: 'StateChangeModal_changeState', isActive: false})
                    return
                } else {
                    this.changeLoadingStep('CHECK_APPOINTMENT', 'success')
                }

                // console.log(this.openTasksOnServiceOrder)
                let openTaskList = this.openTasksOnServiceOrder.reduce((str, task, i) => { //Reduce list of open tasks to user-friendly list string
                    let string = str
                    if (i != 0) string += '\n'
                    string += `${task.number}: ${task.shortDescription}`
                    return string
                }, '')

                //Text description for sweetAlert popup:
                let closeRelatedTasksText = `Der blev fundet følgende opgaver på serviceOrderen der ikke er lukket:\n\n${openTaskList}\n\nVil du også sætte disse opgaver til 'Closed Complete'?`
                //Await user response to sweetAlert popup:
                let shouldCloseRelatedTasks = await swal({
                    icon: 'warning',
                    title: 'Luk alle opgaver?',
                    text: closeRelatedTasksText,
                    buttons: {
                        cancel: { //Option for not changing anything in any database
                            text: 'Annulér',
                            value: 'CANCEL',
                            visible: true,
                            className: '', //Is grey by default, can be changed by adding a class here and some css
                            closeModal: true,
                        },
                        dontCloseAll: { //Option for only changing the selected task
                            text: 'Afslut kun denne',
                            value: false,
                            visible: true,
                            className: 'redBg', //Is blue by default, can be changed by adding a class here and some css
                            closeModal: true,
                        },
                        closeAll: { //Option for closing all (relevant) open task on the serviceOrder
                            text: 'Afslut alle',
                            value: true,
                            visible: true,
                            className: 'greenBg', //Is blue by default, can be changed by adding a class here and some css
                            closeModal: true,
                        },
                    },
                })
                this.changeLoadingStep('CHECK_SERVICE_ORDER_TASKS', 'success')

                // console.log(shouldCloseRelatedTasks)
                if (shouldCloseRelatedTasks == 'CANCEL'){
                    this.stateFormLoading = false //Stop loading animation
                    EventBus.$emit('function-activity', {functionName: 'StateChangeModal_changeState', isActive: false})
                    return null //Beak out of function
                }
                if (shouldCloseRelatedTasks) { //true for closing all, false for closing only selected
                    let updatePromises = []
                    for (let task of this.openTasksOnServiceOrder) { //loop through list of open tasks
                        updatePromises.push(this.dataUpdateProjectTask(task.id, TaskState.CLOSED_COMPLETE, "", null, null, null, null, null, null, null, null))

                        //Add note to each task
                        this.dataPostNote(task.id, this.installationLabel, `Ændret status på opgave ${task.number} fra '${TaskState.getValueFromCode(this.task.state.value)}' til '${TaskState.getValueFromCode(state)}' i forbindelse med afslutning af opgave ${this.task.number}`, "Intern", this.$route.params.projectIdentifier)
                    }
                    this.changeLoadingStep('UPDATE_SERVICE_ORDER_TASKS', 'in progress')

                    try {
                        await Promise.all(updatePromises)
                        this.changeLoadingStep('UPDATE_SERVICE_ORDER_TASKS', 'success')
                    } catch (error) {
                        this.changeLoadingStep('UPDATE_SERVICE_ORDER_TASKS', 'error')
                        console.error(error)
                    }
                    //TODO: Consider if the following projectTask update (selected/CPE task) can be included in this call, for efficiency
                }
            } else if (
                !this.taskIsTroubleTicket && //Task is not a toubleTicket (so it must be a projectTask)
                [...TaskState.openStatesArray, TaskState.ON_HOLD].includes(state) && //Task is being set to an open state or on hold
                TaskCode.BookRoleArray.includes(this.task.code) //Task code is a kind of book task (First task of normal serviceOrder)
            ) {
                this.changeLoadingStep('UPDATE_SERVICE_ORDER_TASKS', 'in progress')
                //Set primary task to PENDING
                if (!this.serviceOrder.project?.tasks?.length){
                    this.changeLoadingStep('UPDATE_SERVICE_ORDER_TASKS', 'error')
                } else {
                    let updatePromises = []
                    for (let serviceOrderTask of this.serviceOrder.project.tasks) {
                        if (TaskCode.PrimaryRoleArray.includes(serviceOrderTask.code) && serviceOrderTask.number>this.task.number) {
                            updatePromises.push(this.dataUpdateProjectTask(serviceOrderTask.id, TaskState.PENDING, "", null, null, null, null, null, null, null, null))
                        }
                    }
                    try {
                        await Promise.all(updatePromises)
                        this.changeLoadingStep('UPDATE_SERVICE_ORDER_TASKS', 'success')
                    } catch (error) {
                        this.changeLoadingStep('UPDATE_SERVICE_ORDER_TASKS', 'error')
                        console.error(error)
                    }
                }
            }

            if (this.stateReasonNote && this.stateReasonNote.length > 1) { //Has note
                try {
                    this.changeLoadingStep('ADD_NOTE', 'in progress')

                    await this.dataPostNote(projectTaskId, this.installationLabel, this.stateReasonNote, "Ekstern", this.$route.params.projectIdentifier, this.taskIsTroubleTicket)//Append note to task
                    
                    this.changeLoadingStep('ADD_NOTE', 'success')
                }
                catch(error) {
                    swal('Kunne ikke gemme note.', error.message, 'error')
                    console.error(error)
                    this.changeLoadingStep('ADD_NOTE', 'error')
                }
            }
            
            if ((!this.taskIsTroubleTicket && this.selectedStatus == TaskState.ON_HOLD) ||(this.taskIsTroubleTicket && this.selectedStatus == TicketState.ON_HOLD) ) { //On hold
                onHoldReason = this.selectedOnHoldReason
            }

            //Update task with API
            this.changeLoadingStep('UPDATE_MAIN_TASK', 'in progress')
            if (!this.taskIsTroubleTicket) {
                if (state == TaskState.CLOSED_COMPLETE && this.task.code == TaskCode.CPE) { //CPE task being closed
                    let today = new Date()
                    let changeConnectionDate = false
                    if (!this.task.connectionDate || this.task.connectionDate.substring(0,10) != today.toISOString().substring(0,10)) { //connectionDate is not today
                        console.log('connectionDate is not today', `${this.task.connectionDate ? this.task.connectionDate.substring(0,10) : String(this.task.connectionDate)} != ${today.toISOString().substring(0,10)}`)
                        let changeConnectionDateDescription = `Det ser ud til at du prøver at afslutte en sag der ikke har connectionDate i dag (connectionDate: ${this.task.connectionDate ? this.task.connectionDate.substring(0,10) : ''}).\nVil du ændre connectionDate til dags dato?`
                        changeConnectionDate = await swal({
                            icon: 'warning',
                            title: 'ConnectionDate er ikke i dag',
                            text: changeConnectionDateDescription,
                            buttons: {
                                dontChangeCd: { //Option for not changing the task before closing it
                                    text: 'Gem med nuværende connectionDate',
                                    value: false,
                                    visible: true,
                                    className: '', //Is blue by default, can be changed by adding a class here and some css
                                    closeModal: true,
                                },
                                doChangeCd: { //Option for setting connectionDate to today
                                    text: 'Sæt connectionDate til dags dato',
                                    value: true,
                                    visible: true,
                                    className: '', //Is blue by default, can be changed by adding a class here and some css
                                    closeModal: true,
                                },
                            }
                        })
                    }
                    if (!connectionDate || changeConnectionDate) { //without a connectionDate, or user has chosen to change connectionDate
                        connectionDate = today.toISOString() //Set connectionDate to today
                    }
                }
                //(projectTaskId, state, assignee, connectionDate, onHoldReason, plannedStart, plannedEnd, signalStrengthData, signalStrengthCaTV, speedTestFiberbox, dataportCheckPerformed)
                try {
                    await this.dataUpdateProjectTask(projectTaskId, state, assignee, connectionDate, onHoldReason, this.selectedOnHoldSubreason, this.task.plannedStart, this.task.plannedEnd, this.signalStrengthData, this.signalStrengthCaTV, this.speedTestFiberbox, this.dataportCheckPerformed) //For project tasks
                    this.changeLoadingStep('UPDATE_MAIN_TASK', 'success')
                } catch (error) {
                    console.error(error)
                    this.changeLoadingStep('UPDATE_MAIN_TASK', 'error')
                }
            } else {
                try {
                    await this.dataUpdateTroubleTicket(projectTaskId, state, assignee, onHoldReason) //For trouble tickets
                    this.changeLoadingStep('UPDATE_MAIN_TASK', 'success')
                } catch (error) {
                    console.error(error)
                    this.changeLoadingStep('UPDATE_MAIN_TASK', 'error')
                }
            }

            if (
                Object.keys(this.project.TaskCompletionUnitworkUnitOptions).includes(this.task.code) && 
                this.project.TaskCompletionUnitworkUnitOptions[this.task.code].appliesToState.includes(state)
                // (!this.taskIsTroubleTicket && state == TaskState.CLOSED_COMPLETE && this.task.code == TaskCode.CPE) ||
                // (!this.taskIsTroubleTicket && state == TaskState.CLOSED_COMPLETE && this.task.code == TaskCode.UDVID) ||
                // (!this.taskIsTroubleTicket && state == TaskState.PATCH && this.task.code == TaskCode.PATCH) ||
                // (this.taskIsTroubleTicket && [TicketState.RESOLVED, TicketState.CLOSED].includes(state)) ||
                // (!this.taskIsTroubleTicket && state == TaskState.CLOSED_COMPLETE && this.task.code == TaskCode.FIBERKONSU)
            ) {
                this.changeLoadingStep('ADD_UNIT_WORK', 'in progress')

                let PayedBy = null
                let ProjectInstallationType = null
                let unitId = this.selectedUnitWorkUnit
                if (this.taskIsTroubleTicket) {
                    PayedBy = unitId == 'F.TRT.SP' ? this.task.serviceProvider?.id || 'EFB' : 'EFB'
                    ProjectInstallationType = 'TroubleTickets'
                } else {
                    PayedBy = 'EFB'
                    ProjectInstallationType = this.task.project?.type?.value
                }
                let user = this.$parent.$parent.$parent.user
                
                try {
                    if (!unitId) throw new Error('No Unit ID selected for unitWork')


                    if (unitId != 'F.NULL'){
                        await this.dataAddOrUpdateUnitWork({
                            Unit: {
                                Id: this.unitWorkCompositeUnits.find((u) => u.id == unitId).id,
                                ...this.unitWorkCompositeUnits.find((u) => u.id == unitId)
                            },
                            Amount: 1,
                            EquivalentHours: this.calculateEquivalentHours(unitId, 1),
                            Price: this.calculatePrice(unitId, 1),
                            AreaCode: this.task[this.configurationItemName]?.area?.sonWinProjectId,
                            ProjectInstallationType,
                            PayedBy,
                            ConfigurationItem: {
                                Label: this.getConfigurationLabel(this.task) || null,
                                Area: this.task[this.configurationItemName]?.area || null,
                                Cabinet: this.task[this.configurationItemName]?.cabinet || null,
                                TechnicalData: this.task[this.configurationItemName]?.technicalData || null,
                                Type: this.task[this.configurationItemName]?.type || null,
                                Value: this.task[this.configurationItemName]?.value || null,
                                Address: this.formatAddress(this.task[this.configurationItemName]?.address, false) || null,
                            },
                            Workers: this.selectedWorkers || null,
                            TimeStamp: new Date(),
                            CreatedBy: {
                                Name: user?.displayName || null,
                                Email: user?.email || null,
                                Initials: user?.email?.substr(0, user?.email?.indexOf('@')).toUpperCase() || null
                            },
                            AutoGenerated: true,
                            Date: this.formatMachineDate(new Date(), '-'),
                            Billed: {
                                Bool: false,
                                Updated: new Date()
                            },
                            LinkedProjects: [this.$route.params.projectIdentifier],
                            FromDocumentation: {
                                asrName: this.task[this.configurationItemName]?.technicalData?.asrName || null,
                                asrPort: this.task[this.configurationItemName]?.technicalData?.asrPort || null,
                                caTVPort: this.task[this.configurationItemName]?.technicalData?.caTVPort || null,
                                taskId: this.task.id,
                                taskNum: this.task.number,
                            },
                            Description: `${this.task.number}: ${this.task.shortDescription}`,
                        })
                    }
                    this.changeLoadingStep('ADD_UNIT_WORK', 'success')
                } catch (error) {
                    console.error(error)
                    this.changeLoadingStep('ADD_UNIT_WORK', 'error')
                }
            }

            this.changeLoadingStep('SAVE_INTERNAL_NOTE', 'in progress')
            let stateChangedFromString = this.task.number.substring(0,3) == 'TRT' ? TicketState.getValueFromCode(this.task.state.value) : TaskState.getValueFromCode(this.task.state.value)
            let stateChangedToString = this.task.number.substring(0,3) == 'TRT' ? TicketState.getValueFromCode(state) : TaskState.getValueFromCode(state)
            this.dataPostNote(projectTaskId, this.installationLabel, `Ændret status på opgave ${this.task.number} fra '${stateChangedFromString}' til '${stateChangedToString}'`, "Intern", this.$route.params.projectIdentifier).then(() => {
                this.changeLoadingStep('SAVE_INTERNAL_NOTE', 'sucess')
            }).catch((error) => {
                this.changeLoadingStep('SAVE_INTERNAL_NOTE', 'error')
                console.error('error saving internal note: ',error)
            })

            analytics.logEvent('task_state_changed', {taskId: projectTaskId, state})

            try {
                this.changeLoadingStep('SEND_NOTIFICATION', 'in progress')
                await this.sendNotification(task) //Conditionally send email to selected contacts & send to logged in user.
                this.changeLoadingStep('SEND_NOTIFICATION', 'success')
            } catch (error) {
                console.error(error)
                this.changeLoadingStep('SEND_NOTIFICATION', 'error')
            }

            if (this.addExtraUnitWork) {
                // console.log(`Opening unitWork form with pre-filled description: ${this.stateReasonNote}`)
                EventBus.$emit('add-unit-work', this.stateReasonNote)
            }

            //Clear form data
            this.selectedStatus = null
            this.stateReasonNote = null

            // Emit event that updates the timeline task and the active installation data.
            EventBus.$emit('timeline-update-task')
            EventBus.$emit('state-changed',(this.projectTaskId))

            if (this.loadingStepsContainsError) {
                swal('Gemt med fejl', 'Der er sket en fejl i forsøget på at gemme dine ændringer', 'warning')
            } else {
                swal('Gemt', 'Dine ændringer er gemt, hvis du har markeret "send mail til kontaktpersoner" vil de valgte straks modtage en mail.', 'success')
                this.stateFormLoading = false
                EventBus.$emit('function-activity', {functionName: 'StateChangeModal_changeState', isActive: false})
                this.clickAway()
            }
            
        },

        async sendNotification(task) {
            if (!this.selectedContacts || !this.selectedContacts.length) return null //If user has not selected any contacts, don't send any emails
            EventBus.$emit('function-activity', {functionName: 'StateChangeModal_sendNotification', isActive: true})

            const emailObj = {
                to: this.selectedContacts,
                cc: this.firebaseUser.Email,
                replyTo: this.firebaseUser.Email,
                template: {
                    name: EmailTemplate.TASK_STATE_CHANGED,
                    data: {
                        newstate: this.readFromDropdown(this.selectedStatus,this.statusOptions),
                        taskNum: task.number,
                        instLabel: this.taskIsTroubleTicket ? task.installation.label : task[this.configurationItemName].label,
                        username: this.firebaseUser.Name,
                        note: this.stateReasonNote || 'Ingen note',
                    }
                }
            }

            let response = await this.dataAddEmail(emailObj)
            EventBus.$emit('function-activity', {functionName: 'StateChangeModal_sendNotification', isActive: false})
            return response
        },

        openAttachment(task, attachment){
            EventBus.$emit('attachmentShouldOpen', {task, attachment})
        },

        uploadFile(){
            EventBus.$emit('uploadFileModalShouldOpen')
        },

        clickAway() {
            EventBus.$emit('stateChange-modal-closing')
        },
    },

    beforeMount() {
        this.project = {...this.projectProp}

        EventBus.$on('file-viewer-modal-close', () => {
            this.openFileModalOpen = false
        })

        EventBus.$on('function-activity', (data) => {
            if (data.functionName == 'DataAPI_dataPostAttachment' && data.isActive == false) {
                EventBus.$emit('fileUploadedWhileStateChangeModalOpen')
            }
            else if (data.functionName == 'Booking_setActiveInstallationData') {
                // console.log('setActiveInstallationData event detected')
                this.loadingFiles = data.isActive
            }
        })
    },

    watch: {
        projectProp: {
            immediate: true,
            handler(){
                // console.log(`Updating project from projectProp in StateChangeModal`, this.projectProp)
                this.project = this.projectProp
            }
        },
        isOpen: {
            immediate: false,
            handler(){
                this.prePopulateForm()
            }
        },
        technicalData: {
            immediate: false,
            handler(){
                this.prePopulateForm()
            }
        },
        selectedOnHoldReason: {
            immediate: false,
            handler(){
                this.selectedOnHoldSubreason = null;
                switch(this.selectedOnHoldReason){
                    case OnHoldReason.CALLER: this.onHoldSubreasonOptions = OnHoldReason.CALLER_DROPDOWN_OPTIONS 
                    break;
                    case OnHoldReason.PROBLEM: this.onHoldSubreasonOptions = OnHoldReason.PROBLEM_DROPDOWN_OPTIONS
                    break;
                    case OnHoldReason.VENDOR: this.onHoldSubreasonOptions = OnHoldReason.VENDOR_DROPDOWN_OPTIONS
                    break;
                    case OnHoldReason.CHANGE: this.onHoldSubreasonOptions = OnHoldReason.CHANGE_DROPDOWN_OPTIONS
                    break;
                    case OnHoldReason.REQUEST: this.onHoldSubreasonOptions = OnHoldReason.REQUEST_DROPDOWN_OPTIONS
                    break;
                }
            }
        }
    }
}
</script>
<style scoped>
.fake-label {
    cursor: pointer;
}

.explanation {
    font-style: italic;
    font-size: small;
}
.scrollable-y {
    overflow-x: hidden;
    overflow-y: auto;
}
</style>
<style>
.redBg {
    background-color: #db2828 !important;
}
.greenBg {
    background-color: #21ba45 !important;
}
</style>
s