<template>
    <div>
        <sui-modal
            size="small" 
            id="editCachedSettingsModal"
            :open="isOpen" 
            v-on:clickAwayModal="clickAway"
        >
            <sui-modal-header v-if="createNewPreset">
                Opret Nyt Preset
            </sui-modal-header>
            <sui-modal-header v-else-if="editPreset">
                Redigér Preset
            </sui-modal-header>
            <sui-modal-header v-else-if="page == 'booking dashboard'">
                Indstillinger Booking Dashboard
            </sui-modal-header>
            <sui-modal-header v-else-if="page == 'cabinet list'">
                Indstillinger Skabs-oversigt
            </sui-modal-header>
            <sui-modal-header v-else-if="page == 'project list'">
                Indstillinger Projekter
            </sui-modal-header>
            <sui-modal-header v-else-if="page == 'sygdom'">
                Indstillinger sygdomsstatistik
            </sui-modal-header>
            
            <sui-modal-content scrolling>
                <sui-form :loading="formLoading">
                    <sui-form-field v-if="createNewPreset || editPreset">
                        <label>Preset Navn</label>
                        <input type="text" required placeholder="Optimeret til..." id="bookingDate" v-model="newPresetName"/>
                    </sui-form-field>
                    <div class="accordion-spacing" v-for="(group, groupIndex) in settings.settingGroups" :key="groupIndex">

                        <!-- Collapsable group -->
                        <sui-accordion is="sui-menu" vertical exclusive styled fluid v-if="group.accordion && shouldShowGroup(group)">
                            <sui-accordion-title is="sui-menu-header">
                                <sui-icon name="dropdown" />
                                    {{ group.headerText }}
                                </sui-accordion-title>
                            <sui-accordion-content>
                                <div v-if="!createNewPreset && !editPreset">
                                    <div class="button-spacing" v-for="action in group.actions" :key="action.text">
                                        <sui-button
                                            @click.prevent="executeFunction(action.click)"
                                        >
                                            {{ action.text }}
                                        </sui-button>
                                    </div>
                                </div>
                                <div v-for="(key) in group.settings" :key="key">
                                    <!-- Space -->
                                    <sui-form v-if="key.includes('space')">
                                        <label></label>
                                    </sui-form>

                                    <sui-form v-else-if="key.includes('showSettingInModal')">
                                    </sui-form>

                                    <!-- Checkbox -->
                                    <div class="checkbox-spacing" v-else-if="settings.allSettings[key].type == 'checkbox' && shouldShowSetting(key)">
                                        <sui-form-field v-if="!settings.allSettings[key].action" :disabled="disable(settings.allSettings[key], groupIndex)">
                                            <sui-checkbox :label="settings.allSettings[key].text" 
                                                v-model="params[key].value">
                                            </sui-checkbox>
                                        </sui-form-field>
                                        <sui-form-field v-if="settings.allSettings[key].action" :disabled="disable(settings.allSettings[key], groupIndex)">
                                            <sui-checkbox :label="settings.allSettings[key].text" 
                                                v-model="params[key].value"
                                                @click="executeFunction(settings.allSettings[key].action.click,settings.allSettings[key].action.arg)" 
                                                >
                                            </sui-checkbox>
                                        </sui-form-field>
                                    </div>

                                    <!-- Radio Checkbox -->
                                    <sui-form v-else-if="settings.allSettings[key].type == 'radio' && shouldShowSetting(key)">
                                        <label v-if="settings.allSettings[key].title" :for="settings.allSettings[key].name">{{ settings.allSettings[key].title }}</label>
                                        <div class="checkbox-spacing" v-for="option in settings.allSettings[key].options" :key="option.text">
                                            <sui-form-field v-if="option.action">
                                                <sui-checkbox radio 
                                                    :name="settings.allSettings[key].name" 
                                                    :label="option.text" 
                                                    :value="option.value" 
                                                    v-model="params[key].value"
                                                    @click="executeFunction(option.action.click,option.action.arg)" 
                                                    />
                                            </sui-form-field>
                                            <sui-form-field v-if="!option.action">
                                                <sui-checkbox radio 
                                                    :name="settings.allSettings[key].name" 
                                                    :label="option.text" 
                                                    :value="option.value" 
                                                    v-model="params[key].value"
                                                    />
                                            </sui-form-field>
                                        </div>
                                        <div v-if="!settings.allSettings[key] || !settings.allSettings[key].options || !settings.allSettings[key].options.length">
                                            {{ "No options have been provided for this setting" }}
                                        </div>
                                    </sui-form>

                                    <!-- dropdown single -->
                                    <div class="form-field-spacing" v-else-if="settings.allSettings[key].type == 'dropdownSingle' && shouldShowSetting(key)"> 
                                        <sui-form-field>
                                            <label> {{ settings.allSettings[key].title }} </label>
                                            <sui-dropdown
                                                selection
                                                search
                                                :options="settings.allSettings[key].options"
                                                :placeholder="settings.allSettings[key].placeholder"
                                                v-model="params[key].value"
                                            ></sui-dropdown>
                                        </sui-form-field>
                                    </div>

                                    <!-- dropdown multiple -->
                                    <div class="form-field-spacing" v-else-if="settings.allSettings[key].type == 'dropdownMultiple' && shouldShowSetting(key)"> 
                                        <sui-form-field :class="classes(settings.allSettings[key].required && 'required')">
                                            <label> {{ settings.allSettings[key].title }} </label>
                                            <sui-dropdown
                                                multiple
                                                selection
                                                search
                                                :options="settings.allSettings[key].options"
                                                :placeholder="settings.allSettings[key].placeholder"
                                                v-model="params[key].value"
                                                @input="$forceUpdate()"
                                            ></sui-dropdown>
                                        </sui-form-field>
                                    </div>

                                    <!-- Dragable -->
                                    <draggable 
                                        v-else-if="settings.allSettings[key].type == 'draggable'  && shouldShowSetting(key)"
                                        class="list-group"
                                        tag="ul"
                                        v-model="params[key].value" 
                                        v-bind="dragOptions" 
                                        @start="drag=true" 
                                        @end="drag=false"
                                    >
                                        <transition-group>
                                            <li
                                                class="list-group-item"
                                                v-for="element in params[key].value"
                                                :key="element.value"
                                            >
                                                <i class="fa fa-align-justify handle"></i>
                                                <span class="text">{{  element.text }}</span>
                                            </li>
                                        </transition-group> 
                                    </draggable>
                                </div>
                            </sui-accordion-content>
                        </sui-accordion>

                        <!-- Non collapsable group -->
                        <div v-if="!group.accordion && shouldShowGroup(group)"> 
                            <div v-if="!createNewPreset && !editPreset">
                                <div class="button-spacing" v-for="action in group.actions" :key="action.text">
                                    <sui-button
                                        @click.prevent="executeFunction(action.click)"
                                    >
                                        {{ action.text }}
                                    </sui-button>
                                </div> 
                            </div>   
                            <div v-for="(key) in group.settings" :key="key">
                                <!-- Space -->
                                <sui-form v-if="key.includes('space')">
                                    <label></label>
                                </sui-form>

                                <sui-form v-else-if="key.includes('showSettingInModal')">
                                </sui-form>

                                <!-- Checkbox -->
                                <div class="checkbox-spacing" v-else-if="settings.allSettings[key].type == 'checkbox' && shouldShowSetting(key)">
                                    <sui-form-field v-if="!settings.allSettings[key].action" :disabled="disable(settings.allSettings[key], groupIndex)">
                                        <sui-checkbox :label="settings.allSettings[key].text" 
                                            v-model="params[key].value">
                                        </sui-checkbox>
                                    </sui-form-field>
                                    <sui-form-field v-if="settings.allSettings[key].action" :disabled="disable(settings.allSettings[key], groupIndex)">
                                        <sui-checkbox :label="settings.allSettings[key].text" 
                                            v-model="params[key].value"
                                            @click="executeFunction(settings.allSettings[key].action.click,settings.allSettings[key].action.arg)" 
                                            >
                                        </sui-checkbox>
                                    </sui-form-field>
                                </div>

                                <!-- Radio Checkbox -->
                                <sui-form v-else-if="settings.allSettings[key].type == 'radio' && shouldShowSetting(key)">
                                    <label v-if="settings.allSettings[key].title" :for="settings.allSettings[key].name">{{ settings.allSettings[key].title }}</label>
                                    <div class="checkbox-spacing" v-for="option in settings.allSettings[key].options" :key="option.text">
                                        <sui-form-field v-if="option.action">
                                            <sui-checkbox radio 
                                                :name="settings.allSettings[key].name" 
                                                :label="option.text" 
                                                :value="option.value" 
                                                v-model="params[key].value"
                                                @click="executeFunction(option.action.click,option.action.arg)" 
                                            />
                                        </sui-form-field>
                                        <sui-form-field v-if="!option.action">
                                            <sui-checkbox radio 
                                                :name="settings.allSettings[key].name" 
                                                :label="option.text" 
                                                :value="option.value" 
                                                v-model="params[key].value"
                                            />
                                        </sui-form-field>
                                        </div>
                                        <div v-if="!settings.allSettings[key] || !settings.allSettings[key].options || !settings.allSettings[key].options.length">
                                            {{ "No options have been provided for this setting" }}
                                        </div>
                                </sui-form>

                                <!-- dropdown single -->
                                <div class="form-field-spacing" v-else-if="settings.allSettings[key].type == 'dropdownSingle' && shouldShowSetting(key)"> 
                                    <sui-form-field>
                                        <label> {{ settings.allSettings[key].title }} </label>
                                        <sui-dropdown
                                            selection
                                            search
                                            :options="settings.allSettings[key].options"
                                            :placeholder="settings.allSettings[key].placeholder"
                                            v-model="params[key].value"
                                        ></sui-dropdown>
                                    </sui-form-field>
                                </div>

                                <!-- dropdown multiple -->
                                <div class="form-field-spacing" v-else-if="settings.allSettings[key].type == 'dropdownMultiple' && shouldShowSetting(key)"> 
                                    <sui-form-field :class="classes(settings.allSettings[key].required && 'required')">
                                        <label> {{ settings.allSettings[key].title }} </label>
                                        <sui-dropdown
                                            multiple
                                            selection
                                            search
                                            :options="settings.allSettings[key].options"
                                            :placeholder="settings.allSettings[key].placeholder"
                                            v-model="params[key].value"
                                            @input="$forceUpdate()"
                                        ></sui-dropdown>
                                    </sui-form-field>
                                </div>

                                <!-- Dragable -->
                                <draggable 
                                    v-else-if="settings.allSettings[key].type == 'draggable'  && shouldShowSetting(key)"
                                    class="list-group"
                                    tag="ul"
                                    v-model="params[key].value" 
                                    v-bind="dragOptions" 
                                    @start="drag=true" 
                                    @end="drag=false"
                                >
                                    <transition-group>
                                        <li
                                            class="list-group-item"
                                            v-for="element in params[key].value"
                                            :key="element.value"
                                        >
                                            <i class="fa fa-align-justify handle"></i>
                                            <span class="text">{{  element.text }}</span>
                                        </li>
                                    </transition-group> 
                                </draggable>
                            </div>
                        </div>
                    </div>
                </sui-form>
            </sui-modal-content>
            <sui-modal-actions>
                <sui-button @click="clickAway()">Annullér</sui-button>
                <sui-button v-if="editPreset" negative @click="deletePreset()">Slet Preset</sui-button>
                <sui-button v-if="createNewPreset || editPreset" :disabled="newPresetName == ''" positive @click="savePreset()">Gem Preset</sui-button>
                <sui-button v-else positive @click="saveChanges(true)">Gem</sui-button>
            </sui-modal-actions>
        </sui-modal>

        <sui-modal
            size="small" 
            id="choosePresetSettingsModal"
            :open="openChoosePresetModal" 
            v-on:clickAwayModal="openChoosePresetModal = false"
        >
            <sui-modal-header>

                <div v-if="!editPreset">
                    Vælg Preset
                </div>
                <div v-else>
                    Redigér Preset
                </div>
                
                <sui-button
                    v-if="hasPermission(UserRoles.SUPER_ADMIN)" 
                    floated="right"
                    size="mini"
                    title="Opret nyt preset"
                    style="padding: 9.87755px; margin-top: -28px;"
                    @click="createSettingsPreset()"
                ><i class="fa-solid fa-plus"></i></sui-button>

                <sui-button 
                    v-if="hasPermission(UserRoles.SUPER_ADMIN) && presets.length"
                    floated="right"
                    size="mini"
                    title="Redigér"
                    style="padding: 9.87755px; margin-top: -28px;"
                    @click="editPreset = !editPreset"
                ><i class="fa-solid fa-pen-to-square"></i></sui-button>
            </sui-modal-header>
            <sui-modal-content scrolling>
                <sui-form-field v-if="!presets.length">
                    Der blev ikke fundet nogle presets!
                </sui-form-field>
                <sui-form-field v-else>
                    <div class="button-spacing" v-for="preset in presets" :key="preset.id">
                        <sui-button
                            v-if="!editPreset"
                            @click.prevent="choosePreset(preset)"
                        >
                            {{ preset.presetName }}
                        </sui-button>
                        <sui-button
                            v-if="editPreset"
                            color="blue"
                            @click.prevent="choosePreset(preset)"
                        >
                            {{ preset.presetName }}
                        </sui-button>
                    </div>
                </sui-form-field>
            </sui-modal-content>
            <sui-modal-actions>
                <sui-button @click="clickAway(false)">Annullér</sui-button>
            </sui-modal-actions>
        </sui-modal>
    </div>
</template>
<script>
import EventBus from '../../EventBus'
import { db } from '../../firebase'
import { Mixin } from '../../lib/Mixins/mixin'
import swal from 'sweetalert'
import draggable from "vuedraggable"
import UserRoles from '../../lib/Enums/UserRoles'

export default {

    mixins: [EventBus, Mixin, db],

    components: {
        draggable,
    },

    enums: {
        UserRoles
    },

    props: {
        isOpen: Boolean,
        project: Object,
        settings: {
            type: Object,
            required: true
        },
        hasProjectTasks: Boolean,
        hasTroubleTickets: Boolean,
        page: String,
        allowShowOverride: {
            type: Boolean,
            default: true
        },
    },

    data() {
        return {
            formLoading: false,
            params: [],
            showAllSettings: false,
            createNewPreset: false,
            editPreset: false,
            openChoosePresetModal: false,
            newPresetName: '',
            presets: null,
            activePreset: null,
        }
    },

    computed: {
        user() {
            return this.$root.$children[0].user
        },

        firebaseUser() {
            return this.$root.$children[0].firebaseUser
        },

        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            }
        },

        currentMedia(){
            return this.$root.$children[0].currentMedia
        },
    },

    methods: {
        /**
         * Method to close modal if clicked outside the modal
         * 
         * Emits an event to close modal
         * @see Booking.vue - For event handler
         */
        clickAway(deep = true){
            this.showAllSettings = false
            this.createNewPreset = false
            this.editPreset = false
            this.openChoosePresetModal = false
            this.activePreset = null
            if (deep){
                EventBus.$emit('edit-settings-modal-closing')
            }
        },

        hasPermission(permission){
            if (JSON.stringify(this.firebaseUser) === '{}') return false
            if (this.firebaseUser.Roles.includes(permission)) return true
            return false
        },

        disable(setting){
            if (!setting?.disabledBy){
                return false
            } else if (setting.disabledMode){
                return this.params[setting.disabledBy].value
            } else {
                return !this.params[setting.disabledBy].value
            }
        },

        shouldShowSetting(key){
            let setting = this.settings.allSettings[key]
            let returnVal = false

            if (key.includes('showSettingInModal')) return this.allowShowOverride
            if (key.includes('space')) return false
            if (this.showAllSettings && setting.appliesTo.includes('booking dashboard')) return true
            
            if (this.hasProjectTasks && this.hasTroubleTickets && setting.appliesTo.includes('only combined') && setting.appliesTo.includes(this.page)){
                returnVal = true
            } else if (this.hasProjectTasks && setting.appliesTo.includes('PT') && setting.appliesTo.includes(this.page)){
                returnVal = true
            } else if (this.hasTroubleTickets && setting.appliesTo.includes('TT') && setting.appliesTo.includes(this.page)){
                returnVal = true
            }
            else if (!setting.appliesTo.includes('PT') && !setting.appliesTo.includes('TT') && !setting.appliesTo.includes('only combined') && setting.appliesTo.includes(this.page)){
                returnVal = true
            }
            return returnVal
        },

        shouldShowGroup(group){
            let returnVal = false
            for (let key of group.settings){
                returnVal = this.shouldShowSetting(key)
                if (returnVal) break
            }
            return returnVal
        },

        /**
         * Method to prepopulate the modal
         * 
         * Makes a clone of the settings array
         */
        prePopulateForm(){
            this.params = this.cloneJson(this.settings.allSettings)

            // Get presets from firestore
            this.$bind('presets', db.collection('SettingsPresets'))
        },

        /**
         * Method for resetting all settings
         * 
         * Opens a SWAL to accept or decline resetting 
         * If the user accepts the settings will be reset and saved
         * If close = true - Event with updated keys are send and the modal closes
         * @param {boolean} close - Control closing of the modal
         */
        async resetToStandard(close = false) {
            let shouldReset = await swal({
                title: 'Ønsker du at gendanne standard indstillinger?',
                icon: 'warning',
                buttons: ['Nej','Ja'],
            })

            if (shouldReset) {  
                let hasBeenUpdated = this.settings.resetToStandard()
                this.settings.saveAll()
                
                if (close) {
                    EventBus.$emit('edit-settings-updated',hasBeenUpdated)
                    this.clickAway() //Close form 
                } 
                else this.prePopulateForm()
            }        
        },

        /**
         * Method for changing project preferences
         * 
         * Implements the functionality to keep or overwrite existing settings
         * when changing between general or projectSpecific
         * @param {string} value - Updated value of setting
         */
        async onChangeSettingsPreferences(value) {
            this.settings.set("projectPreferences", value)
            if (this.createNewPreset) return

            let shouldKeep = await swal({
                title: 'Du er ved at skifte indstillinger?',
                text: 'Ønsker du at',
                icon: 'warning',
                buttons: ['Overskive med nuværende','Beholde gemte'],
            })

            if (shouldKeep){
                this.settings.set("projectPreferences",this.params.projectPreferences.value)
                this.settings.save("projectPreferences")
                this.prePopulateForm()
            } 
        },

        /**
         * Method fro saving the settings
         * 
         * Saves all settings and emits an event with all updated settings
         * @param {boolean} close - Control whether or not the modal shoud close when saving
         * @return {boolean}
         */
        saveChanges(close = true){
            let hasBeenUpdated = []
            for (let key in this.params){
                if (JSON.stringify(this.settings.get(key)) != JSON.stringify(this.params[key].value)) hasBeenUpdated.push(key)
                this.settings.set(key,this.params[key].value)
            }

            let success = this.settings.saveAll()
            if (!success){
                swal('Fejl ved forsøg på at gemme projektspecifikke indstillinger', 
                'Kunne ikke gemme specifikke indstillinger grundet manglende projekt ID','error')
                this.clickAway()
            }

            if (close && success){
                EventBus.$emit('edit-settings-updated',hasBeenUpdated)
                this.clickAway() //Close form
            }
            return success
        },

        async mapUnbookedSettings(){
            console.log("Hello World")
            if (this.params.showUnbookedCard.value === false){
                let enableUnbooked = await swal({
                    title: 'Vil du slå vis ikke bookede til?',
                    text: 'Vis ikke bookede er ikke slået til. Dette er nødvendigt for at kunne vise ikke bookede kunder på kortet',
                    icon: 'info',
                    buttons: ['Nej','Ja'],
                })

                if (enableUnbooked){
                    this.params.showUnbookedCard.value = true
                } else {
                    this.params.mapShowunbookedApptTypeTickets.value = false
                    this.params.mapShowUnbookedApptTypeInspection.value = false
                    this.params.mapShowUnbookedApptTypeInstallation.value = false
                    this.params.mapShowUnbookedApptTypeTechnician.value = false
                    this.params.mapShowUnbookedApptTypePatch.value = false
                }
            }
        },

        changeSettingsPreset(){
            this.openChoosePresetModal = true
        },

        createSettingsPreset(){
            this.activePreset = null
            this.newPresetName = ''
            this.openChoosePresetModal = false
            this.showAllSettings = true
            this.createNewPreset = true
            this.editPreset = false
            this.prePopulateForm()
        },

        async savePreset(){
            console.log("Saving preset")

            let user = {
                displayName: this.user.displayName,
                email: this.user.email,
            }

            if (this.activePreset == null){
                let newDoc = {
                    presetName: this.newPresetName,
                    createdAt: new Date(),
                    createdBy: user,
                    updated: new Date(),
                    updatedBy: user,
                    settings: this.params
                }
    
                console.log("Saving new settings preset to Firestore", newDoc)
                let docRef = db.collection('SettingsPresets').doc() // Create document and get auto generated ID
                await docRef.set(newDoc) // Save document to firestore
            } else {
                let updateDoc = {
                    presetName: this.newPresetName,
                    updated: new Date(),
                    updatedBy: user,
                    settings: this.params
                }

                console.log("Updating setting preset", updateDoc)
                await db.collection('SettingsPresets').doc(this.activePreset.id).update(updateDoc)
            }

            this.clickAway()
        },

        async deletePreset(){
            console.log("Delete Preset")

            let deletePreset = await swal({
                title: 'Vil du slette dette preset?',
                icon: 'warning',
                buttons: ['Nej','Ja'],
            })

            if (deletePreset){
                await db.collection('SettingsPresets').doc(this.activePreset.id).delete()
            }
            this.clickAway(false)

        },

        async choosePreset(preset){
            this.activePreset = preset

            if (this.editPreset){
                console.log("Open edit setting preset modal")

                this.newPresetName = this.activePreset.presetName
                this.params = this.activePreset.settings
                this.showAllSettings = true
                this.openChoosePresetModal = false
            } else {
                let usePreset = await swal({
                    title: 'Vil du overskrive indstillerne?',
                    icon: 'info',
                    buttons: ['Nej','Ja'],
                })
                
                if (usePreset){
                    console.log("Override settings with selected preset:", this.activePreset.presetName)
                    this.params = this.activePreset.settings
                    this.saveChanges(true)
                } else {
                    this.activePreset = null
                    this.newPresetName = ''
                    this.openChoosePresetModal = false
                    this.showAllSettings = false
                    this.createNewPreset = false
                }
            }
        },
    },

    beforeMount() {
        this.prePopulateForm()     
    },

    watch: {
        project: {
            handler(){
                this.settings.setActiveProject(this.project)
                this.prePopulateForm()
            }
        },

        isOpen: {
            handler(open){
                if (open) {
                    this.prePopulateForm()
                }
            }
        }
    }
}
</script>
<style scoped>
    .checkbox-spacing {
        margin-top: 3px;
        margin-bottom: 3px;
    }
    .accordion-spacing {
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .button-spacing {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .text {
        margin: 20px;
    }
</style>