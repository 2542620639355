<template>
    <sui-modal
        v-if="illnessPeriod"
        small
        :open="isOpen"
        v-on:clickAwayModal="clickAway()"
    >
        <sui-modal-header>
            {{ fitterName }}: {{ illnessPeriod.firstIllnessDate }} - {{ illnessPeriod.lastIllnessDate }}
        </sui-modal-header>
        <sui-modal-content scrolling>
            <sui-table striped>
                <sui-table-header>
                    <sui-table-row>
                        <sui-table-header-cell><i class="fas fa-check" title="Registreringen er markeret som 'håndteret'" style="color: green;"></i></sui-table-header-cell>
                        <sui-table-header-cell>Dato</sui-table-header-cell>
                        <sui-table-header-cell>Timer</sui-table-header-cell>
                        <sui-table-header-cell>Løndel</sui-table-header-cell>
                        <sui-table-header-cell>Sag</sui-table-header-cell>
                        <sui-table-header-cell>Note</sui-table-header-cell>
                        <sui-table-header-cell>Godkender</sui-table-header-cell>
                    </sui-table-row>
                </sui-table-header>
                <sui-table-body>
                    <sui-table-row positive key="firstWellAfter" v-if="illnessPeriod.supersededByDate" >
                        <sui-table-cell></sui-table-cell>
                        <sui-table-cell>{{ toUserFriendlyDate(illnessPeriod.supersededByDate) }}</sui-table-cell>
                        <sui-table-cell></sui-table-cell>
                        <sui-table-cell colspan="4">Første 'raske' timeregistrering efter sygdomsperioden</sui-table-cell>
                    </sui-table-row>
                    <sui-table-row v-for="illnessEntry in illnessPeriod.illnesses" :key="illnessEntry.ID">
                        <sui-table-cell><i v-if="illnessEntry.illnessHandled" class="fas fa-check" title="Registreringen er markeret som 'håndteret'" style="color: green;"></i></sui-table-cell>
                        <sui-table-cell>{{ toUserFriendlyDate(illnessEntry.Date) }}</sui-table-cell>
                        <sui-table-cell>{{ illnessEntry.Hours }}</sui-table-cell>
                        <sui-table-cell>{{ illnessEntry.CategoryName }}</sui-table-cell>
                        <sui-table-cell>{{ illnessEntry.ProjectName }}</sui-table-cell>
                        <sui-table-cell>{{ illnessEntry.Note }}</sui-table-cell>
                        <sui-table-cell>{{ illnessEntry.ApprovedBy }}</sui-table-cell>
                    </sui-table-row>
                    <sui-table-row positive key="lastWellBefore" v-if="illnessPeriod.precededByDate" >
                        <sui-table-cell></sui-table-cell>
                        <sui-table-cell>{{ toUserFriendlyDate(illnessPeriod.precededByDate) }}</sui-table-cell>
                        <sui-table-cell></sui-table-cell>
                        <sui-table-cell colspan="4">Sidste 'raske' timeregistrering inden sygdomsperioden</sui-table-cell>
                    </sui-table-row>
                </sui-table-body>
                <sui-table-footer>
                    <sui-table-row>
                        <sui-table-header-cell></sui-table-header-cell>
                        <sui-table-header-cell></sui-table-header-cell>
                        <sui-table-header-cell><strong>{{ illnessPeriod.totalIllnessHours }}</strong></sui-table-header-cell>
                        <sui-table-header-cell colspan="4"></sui-table-header-cell>
                    </sui-table-row>
                </sui-table-footer>
            </sui-table>
            <sui-segment v-if="handledPeriod" color="green">
                <p>Perioden er markeret som 'håndteret' af {{ handledPeriod.handledBy.displayName }} {{ toUserFriendlyTimestamp(handledPeriod.handledAt,true,true,false) }}</p>
                <p v-if="handledPeriod.note"><strong>Note: </strong>{{ handledPeriod.note }}</p>
            </sui-segment>
        </sui-modal-content>
        <sui-modal-actions>
            <sui-button @click="clickAway()">Luk</sui-button>
            <sui-button v-if="userHasHourCheckRole && fitterIsNotSelf" positive @click="handlePeriod()">Markér som 'håndteret'</sui-button>
        </sui-modal-actions>
    </sui-modal>
</template>
<script>
import { DateMixin } from '../../lib/Mixins/dateMixin';
import swal from 'sweetalert';
import { db } from '@/firebase';

export default {
    name: 'illnessPeriodModal',
    mixins: [DateMixin],
    props: {
        isOpen: {
            type: Boolean,
            required: true
        },
        illnessPeriod: {
            type: Object,
            required: true
        },
        fitterName: {
            type: String,
            required: true
        },
        fitterInitials: {
            type: String,
            required: true
        },
        handledFitterIllness: {
            type: Array,
            required: false
        },
    },
    computed: {
        user() {
            return this.$root.$children[0].user
        },
        fitterIsNotSelf() {
            if (!this.fitterInitials || !this.user?.email) return false;
            return this.fitterInitials?.toLowerCase() != this.user.email.split('@')[0]?.toLowerCase()
        },
        handledPeriod() {
            if (!this.handledFitterIllness) {
                return null;
            }
            //Find the handled period that matches the illness period based on matching most illness IDs - if multiple handled periods match, return the one with the most matching illness IDs, but show a warning in the console, as periods should not overlap
            let thisHandledPeriod = null;
            let mostIDMatches = 0;
            for (let handledPeriod of this.handledFitterIllness) {
                let idMatches = handledPeriod.illnessIds.reduce((acc, curr) => {
                    if (this.illnessPeriod.illnesses.find(illness => illness.ID == curr)) {
                        acc++;
                    }
                    return acc;
                }, 0);
                if (idMatches > mostIDMatches) {
                    if (mostIDMatches > 0) {
                        console.warn(`Multiple handled periods match the same illness period - this should not happen`);
                    }
                    thisHandledPeriod = handledPeriod;
                    mostIDMatches = idMatches;
                }
            }
            console.log(thisHandledPeriod)
            return thisHandledPeriod;
        },
        firebaseUser() {
            return this.$root.$children[0].firebaseUser
        },
        userHasHourCheckRole() {
            return this.firebaseUser?.Roles?.includes('hourCheck')
        },
    },
    methods: {
        clickAway() {
            this.$emit('close');
        },
        async handlePeriod() {
            let noteBody = this.handledPeriod?.note ? this.handledPeriod?.note : '';
            let swalResponse = await swal({
                content: {
                    text: "Evt note for håndtering af sygeperioden",
                    element: "input",
                    attributes: {
                        placeholder: "Valgfri note der skrives på perioden",
                        type: "textArea",
                        value: noteBody,
                    },
                },
                buttons: {
                    cancel: {
                        text: 'Annulér',
                        value: 'CANCEL',
                        visible: true,
                    },
                    confirm: {
                        text: 'Gem',
                        visible: true,
                    },
                },
            })
            console.log(swalResponse);
            if (swalResponse == 'CANCEL') {
                return;
            }

            let firstIllnessDate = this.illnessPeriod.firstIllnessDate;
            if (this.handledPeriod && this.handledPeriod.firstIllnessDate < this.illnessPeriod.firstIllnessDate) {
                firstIllnessDate = this.handledPeriod.firstIllnessDate;
            }

            let lastIllnessDate = this.illnessPeriod.lastIllnessDate;
            if (this.handledPeriod && this.handledPeriod.lastIllnessDate > this.illnessPeriod.lastIllnessDate) {
                lastIllnessDate = this.handledPeriod.lastIllnessDate;
            }

            let illnessIds = this.illnessPeriod.illnesses.reduce((acc, curr) => {
                acc.push(curr.ID);
                return acc;
            }, []);
            if (this.handledPeriod) {
                for (let hpIllnessId of this.handledPeriod.illnessIds) {
                    if (!illnessIds.includes(hpIllnessId)) {
                        illnessIds.push(hpIllnessId);
                    }
                }
            }

            let updateDoc = {
                fitterInitials: this.fitterInitials,
                handledAt: new Date().toISOString(),
                handledBy: {
                    email: this.user.email,
                    displayName: this.user.displayName,
                },
                note: swalResponse,
                firstIllnessDate,
                lastIllnessDate,
                illnessIds,
            }

            // console.log(updateDoc);

            if (this.handledPeriod) {
                await db.collection('HandledIllnessPeriods').doc(this.handledPeriod.id).update(updateDoc);
            } else {
                await db.collection('HandledIllnessPeriods').add(updateDoc);
            }
            this.$emit('deepclose');
        }
    }
}
</script>